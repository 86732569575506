import { SetStateAction, useCallback, useEffect, useState } from 'react';

import DateRangePickerInput from 'components/common/DateRangePickerInput';
import { useSearchParams } from 'react-router';
import { useKeyPress } from 'react-use';

const ControlledDateRangePickerInput = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPressed] = useKeyPress('Backspace');

  const handleInputFromValue = useCallback((day: SetStateAction<string>) => {
    setFromDate(day);
  }, []);

  const handleInputToValue = useCallback((day: SetStateAction<string>) => {
    setToDate(day.toString().replace('-', '').trim());
  }, []);

  const handleSelect = useCallback(() => {
    if (fromDate.length && !toDate.length) {
      searchParams.set('startDate', fromDate);
      searchParams.set('endDate', fromDate);
    } else {
      searchParams.set('startDate', fromDate);
      searchParams.set('endDate', toDate);
    }
    searchParams.set('pageNo', '0');
    setSearchParams(searchParams);
  }, [fromDate, searchParams, setSearchParams, toDate]);

  useEffect(() => {
    if (isPressed) {
      searchParams.delete('endDate');
      searchParams.delete('startDate');
    }
    setSearchParams(searchParams);
  }, [isPressed, searchParams, setSearchParams]);

  useEffect(() => {
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    if (!searchParams.has('endDate') && !searchParams.has('startDate')) {
      setFromDate('');
      setToDate('');
    }

    if (startDate && startDate === endDate) {
      setFromDate(startDate);
    }

    if (endDate && startDate && startDate !== endDate) {
      setFromDate(startDate + ' - ');
      setToDate(endDate);
    }
  }, [searchParams]);

  return (
    <DateRangePickerInput
      placeholder="Date range"
      fromValue={fromDate}
      setFromValue={handleInputFromValue}
      toValue={toDate}
      setToValue={handleInputToValue}
      lastAllowedDate={new Date()}
      onSelect={handleSelect}
    />
  );
};

export default ControlledDateRangePickerInput;
