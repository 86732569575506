import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { handleReset } from 'utils/filters/filters';

import { defaultValues } from './patientMessageTemplatesFilterForm.settings';
import { PatientMessageTemplatesFilterFormTypes } from './PatientMessageTemplatesFilterForm.types';

const PatientMessageTemplatesFilterForm = () => {
  const { control, watch, handleSubmit, reset } = useForm<PatientMessageTemplatesFilterFormTypes>({
    reValidateMode: 'onChange'
  });
  const [formData, setFormData] = useTableFiltersData(
    'patientMessageTemplates',
    defaultValues,
    reset
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onSubmit = (data: PatientMessageTemplatesFilterFormTypes) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...data,
      searchKey: data.searchKey && encodeURIComponent(data.searchKey),
      message: encodeURIComponent(data.message),
      shortCode: encodeURIComponent(data.shortCode),
      uniqueId: encodeURIComponent(data.uniqueId),
      status: data?.status?.value
    };

    const query = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => {
        return !!value;
      })
    );

    if (isEqual(query, parsedUrl)) {
      dispatch(closeModal());
    } else {
      navigate({ search: queryString.stringify(query) });
      dispatch(closeModal());
    }
  };

  watch();

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-[312px] flex-col gap-4 2xl:w-[410px]"
      >
        <InputField
          name="message"
          label="Message"
          type="text"
          placeholder="Enter message"
          control={control}
          value={formData?.message}
        />
        <InputField
          name="shortCode"
          label="Shortcode"
          type="text"
          placeholder="Enter shortcode"
          control={control}
          value={formData?.shortCode}
        />
        <InputField
          name="uniqueId"
          label="Unique ID"
          type="text"
          placeholder="Enter unique ID"
          control={control}
          value={formData?.uniqueId}
        />
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="status"
          defaultValue={formData?.status}
        />
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default PatientMessageTemplatesFilterForm;
