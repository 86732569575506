import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import { FormDataFrontDeskProps } from 'components/modals/AddFrontDeskTicket/addFrontDeskTicket.types';
import {
  CareChannelListProps,
  ChannelProps as ContextChannelProps
} from 'contexts/MessagesContext/messagesContext.types';
import { Status } from 'enums/messages';
import type { RootState } from 'store';

import { ChannelProps, FrontDeskChannelProps } from './channel.types';
import { apiSlice } from '../api/apiSlice';

const channelsAdapter = createEntityAdapter<CareChannelListProps, EntityId>({
  selectId: (channel) => channel.channelId
});

const channelsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createChannel: build.mutation<ChannelProps, { patientId: string; channelTitle: string }>({
      query: ({ patientId, channelTitle }) => ({
        url: '/channels',
        method: 'POST',
        body: { patientId, channelTitle }
      }),
      transformResponse: (response: { data: ChannelProps }) => response.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: patientDetail } = await queryFulfilled;
          const patientId = args.patientId;

          const patientDetailTransformed = {
            patientId,
            channelId: patientDetail._id,
            channelTitle: patientDetail.channelTitle,
            channelDescription: patientDetail.channelDescription,
            unreadMessageCount: 0
          };

          dispatch(
            channelsApiSlice.util.upsertQueryData('getPatientChannels', { patientId }, [
              patientDetailTransformed
            ])
          );
        } catch (error) {
          console.error(error);
        }
      }
    }),
    createFrontDeskChannel: build.mutation({
      query: (body) => ({
        url: '/front-desk-channels',
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: FormDataFrontDeskProps }) => response.data,
      invalidatesTags: [{ type: 'FrontDeskChannel', id: 'LIST' }]
    }),
    getPatientChannels: build.query<CareChannelListProps[], { patientId: string }>({
      query: ({ patientId }) => `/channels/${patientId}/patient-channels`,
      transformResponse: (response: { data: ContextChannelProps[] }) =>
        response.data.map((item) => ({
          patientId: item.patientId,
          channelId: item.channelId,
          channelTitle: item.channelTitle,
          channelDescription: item.channelDescription,
          unreadMessageCount: item.unreadMessageCount,
          latestMessage: item.latestMessage,
          latestMessageDate: item.latestMessageDate
        }))
    }),
    getFrontDeskChannels: build.query<CareChannelListProps[], { patientId: string }>({
      query: ({ patientId }) => ({
        url: `/channels/${patientId}/frontdesk-channels`,
        params: { fetchFirstMessage: false }
      }),
      transformResponse: (response: {
        data: FrontDeskChannelProps[];
        info: { totalCount: number };
      }) =>
        response.data.map((item) => ({
          latestMessageDate: item.latestMessageDate,
          channelDescription: item.frontDeskRequestType,
          channelId: item.channelId,
          channelTitle: item.frontDeskRequestType,
          latestMessage: item.latestMessage,
          patientId: item.patientDetail.id,
          _id: item.channelId,
          unreadMessageCount: item.unreadMessageCount
        })),
      providesTags: (result) =>
        result
          ? [
              { type: 'FrontDeskChannel', id: 'LIST' },
              ...result.map(({ channelId }) => ({
                type: 'FrontDeskChannel' as const,
                id: channelId
              }))
            ]
          : ['FrontDeskChannel']
    })
  })
});

const initialState: {
  data: EntityState<CareChannelListProps, EntityId>;
  currentChannel: CareChannelListProps | null;
  loadingChannelsStatus: Status;
} = {
  data: channelsAdapter.getInitialState(),
  currentChannel: null,
  loadingChannelsStatus: Status.Idle
};

let lastRequestId: string;

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    setChannel: (state, action: PayloadAction<CareChannelListProps | null>) => {
      state.currentChannel = action.payload;
    },
    updateChannel: (
      state,
      action: PayloadAction<{
        channelId: string;
        latestMessage?: string;
        latestMessageDate: string;
        unreadMessageCount: number;
      }>
    ) => {
      const selected = channelsAdapter
        .getSelectors()
        .selectById(state.data, action.payload.channelId);

      if (selected) {
        const update = { ...selected, ...action.payload };

        channelsAdapter.upsertOne(state.data, update);
      }
    },
    resetChannels: (state) => {
      if (state.loadingChannelsStatus === Status.Pending) {
        return { ...initialState, loadingChannelsStatus: Status.Pending };
      } else {
        return initialState;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      channelsApiSlice.endpoints.getPatientChannels.matchFulfilled,
      (state, { payload, meta }) => {
        if (lastRequestId === meta.requestId) {
          channelsAdapter.upsertMany(state.data, payload);
          state.loadingChannelsStatus = Status.Fulfilled;
        }
      }
    );
    builder.addMatcher(
      channelsApiSlice.endpoints.getPatientChannels.matchPending,
      (state, action) => {
        lastRequestId = action.meta.requestId;
        state.loadingChannelsStatus = Status.Pending;
      }
    );
    builder.addMatcher(channelsApiSlice.endpoints.getPatientChannels.matchRejected, (state) => {
      state.loadingChannelsStatus = Status.Rejected;
    });
    builder.addMatcher(
      channelsApiSlice.endpoints.getFrontDeskChannels.matchFulfilled,
      (state, { payload }) => {
        channelsAdapter.upsertMany(state.data, payload);
        state.loadingChannelsStatus = Status.Fulfilled;
      }
    );
    builder.addMatcher(channelsApiSlice.endpoints.getFrontDeskChannels.matchPending, (state) => {
      state.loadingChannelsStatus = Status.Pending;
    });
    builder.addMatcher(channelsApiSlice.endpoints.getFrontDeskChannels.matchRejected, (state) => {
      state.loadingChannelsStatus = Status.Rejected;
    });
  }
});

export const { updateChannel, resetChannels, setChannel } = channelsSlice.actions;

export const channelsSelectors = channelsAdapter.getSelectors<RootState>(
  (state) => state.channels.data
);

export const selectChannels = (state: RootState) => state.channels;

export const {
  useLazyGetPatientChannelsQuery,
  useCreateChannelMutation,
  useCreateFrontDeskChannelMutation,
  useLazyGetFrontDeskChannelsQuery
} = channelsApiSlice;

export default channelsSlice.reducer;
