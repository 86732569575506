import { AppointmentTypesFiltersFormProps } from './appointmentTypesFiltersForm.types';

export const defaultValues: AppointmentTypesFiltersFormProps = {
  displayName: '',
  searchKey: '',
  length: { value: '', label: '' },
  uniqueId: '',
  category: { value: '', label: '' },
  status: { value: '', label: '' }
};
