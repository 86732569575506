import { useEffect, useState } from 'react';

import { notifyError } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { FormProvider, useForm } from 'react-hook-form';
import { changeModalSize, closeModal } from 'store/modal/modalSlice';
import {
  useLinkInvoiceToOrderMutation,
  useReleaseOrderFromHoldAltMutation
} from 'store/prescriptions/prescriptionsSlice';

import { renderContent } from './releaseOrderFromHold.settings';
import { NOT_LISTED_VALUE } from './releaseOrderFromHold.types';
import {
  ReleaseFromOnHoldSteps,
  ReleaseOrderFromHoldFormData,
  ReleaseOrderFromHoldProps
} from './releaseOrderFromHold.types';

const ReleaseOrderFromHold: React.FC<ReleaseOrderFromHoldProps> = ({ order }) => {
  const dispatch = useAppDispatch();
  const [updateError, setUpdateError] = useState('');
  const [linkInvoice, { isLoading: isLinkInvoiceLoading }] = useLinkInvoiceToOrderMutation();
  const [releaseOrderFromHold, { isLoading: isReleaseOrderFromHoldLoading }] =
    useReleaseOrderFromHoldAltMutation();
  const methods = useForm<ReleaseOrderFromHoldFormData>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      listedInvoice: '',
      manualEntryInvoice: '',
      reasons: undefined
    }
  });
  const { handleSubmit } = methods;
  const [step, setStep] = useState<ReleaseFromOnHoldSteps>();

  const handleReleaseFromHold = async (data: ReleaseOrderFromHoldFormData) => {
    dispatch(
      changeModalSize({
        size: 'sm'
      })
    );
    setStep(ReleaseFromOnHoldSteps.Loading);

    if (!data.listedInvoice) return;
    if (!order.orderId) {
      notifyError(`Prescription does not have an order id`);
      dispatch(closeModal());
      return;
    }
    if (!data.reasons) {
      notifyError(`Reason for payment missing is required`);
      dispatch(closeModal());
      return;
    }
    const selectedInvoice =
      data.listedInvoice !== NOT_LISTED_VALUE
        ? data.listedInvoice
        : (data.manualEntryInvoice as string);

    const formattedReasons = data.reasons.map((item) => item.value);

    try {
      await linkInvoice({
        orderId: order.orderId,
        invoiceId: selectedInvoice,
        reasons: formattedReasons
      })
        .unwrap()
        .catch((error) => {
          setUpdateError(
            error?.data?.message ||
              error?.message ||
              error?.errorMessage ||
              error?.data?.errorMessage
          );
          setStep(ReleaseFromOnHoldSteps.UpdateFailed);
          throw new Error(`Couldn't link invoice to order`);
        });

      await releaseOrderFromHold({ orderId: order.orderId })
        .unwrap()
        .then(() => {
          setStep(ReleaseFromOnHoldSteps.SuccessPaymentAndReleasedHold);
        })
        .catch((error) => {
          setUpdateError(
            error?.data?.message ||
              error?.message ||
              error?.errorMessage ||
              error?.data?.errorMessage
          );
          setStep(ReleaseFromOnHoldSteps.SuccessPaymentAndFailedReleaseHold);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const notPaid = order.paymentStatus !== 'paid';

    if (notPaid) {
      setStep(ReleaseFromOnHoldSteps.InformationMissing);
    } else {
      setStep(ReleaseFromOnHoldSteps.Loading);

      if (!order.orderId) {
        notifyError(`Prescription does not have an order id`);
        dispatch(closeModal());
        return;
      }

      releaseOrderFromHold({ orderId: order.orderId })
        .unwrap()
        .then(() => {
          setStep(ReleaseFromOnHoldSteps.SuccessPaymentAndReleasedHold);
        })
        .catch((error) => {
          setUpdateError(error.data?.message || error.message);
          setStep(ReleaseFromOnHoldSteps.SuccessPaymentAndFailedReleaseHold);
        });
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form className="p-6" onSubmit={handleSubmit(handleReleaseFromHold)}>
        <h2 className="mb-6 text-xl font-bold text-gray-700">Release from on hold</h2>
        {renderContent(
          step,
          setStep,
          isLinkInvoiceLoading,
          isReleaseOrderFromHoldLoading,
          updateError
        )}
      </form>
    </FormProvider>
  );
};

export default ReleaseOrderFromHold;
