import React from 'react';

import { Common } from '@thecvlb/design-system';
import AddDocumentTags from 'components/modals/AddDocumentTags';
import DeleteDocument from 'components/modals/DeleteDocument';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';

import { DocumentProps } from './document.types';
import DocumentInfo from './DocumentInfo';
import MoreOptions from './MoreOptions';
import ViewDetailsDocument from '../../../modals/ViewDetailsDocument';

const Document: React.FC<DocumentProps> = ({
  handleClick,
  handleDownload,
  patientFullName,
  patientId,
  dataTestId,
  document,
  isTable
}) => {
  const dispatch = useDispatch();
  const { fileName, createdAt, uploadedBy, isUploadedByCurrentUser, tags, _id } = document;

  const handleClickDelete = () =>
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: <DeleteDocument title={fileName} documentId={_id} patientId={patientId} />
      })
    );

  const handleAddTags = () => {
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: (
          <AddDocumentTags document={document} patientId={patientId} shouldUpdateTask={isTable} />
        )
      })
    );
  };

  const handleViewDetails = () => {
    dispatch(
      openModal({
        size: 'lg',
        modalContent: <ViewDetailsDocument document={document} />
      })
    );
  };

  const moreOptions = (
    <MoreOptions
      handleDelete={handleClickDelete}
      handleDownload={handleDownload}
      handleAddTags={handleAddTags}
      handleViewDetails={handleViewDetails}
      shouldShowDeleteItem={uploadedBy.trim() !== patientFullName.trim()}
      shouldEditTags={!!(tags && tags.length > 0)}
    />
  );

  if (isTable) {
    return (
      <tr className="text-base font-semibold text-gray-700">
        <td className="pr-2 pt-2">
          <div className="flex gap-1.5">
            <span className="shrink-0">
              <Common.Icon name="articles" />
            </span>
            <span className="max-w-52 truncate text-base ">{fileName}</span>
          </div>
        </td>
        <td className="max-w-36 pr-2 pt-2">{uploadedBy}</td>
        <td className="truncate pr-2 pt-2">
          {createdAt && dayjs(createdAt).format(DateFormat.MMM_D_YYYY)}
        </td>
        <td className="flex flex-col flex-wrap gap-1 pt-2">
          {tags?.map((tag) => (
            <div
              key={tag._id}
              className="w-fit whitespace-nowrap rounded bg-gray-800/5 px-1 py-0.5 text-xs font-semibold text-gray-600"
            >
              {tag.name}
            </div>
          ))}
        </td>
        <td className="pt-2">{moreOptions}</td>
      </tr>
    );
  }

  return (
    <div className="flex items-center" data-testid={dataTestId}>
      <DocumentInfo
        title={fileName}
        date={createdAt}
        uploadedByName={uploadedBy}
        byCurrentUser={isUploadedByCurrentUser}
        handleClick={handleClick}
        tags={tags}
      />
      {moreOptions}
    </div>
  );
};

export default Document;
