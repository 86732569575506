import { Common } from '@thecvlb/design-system/lib/src';
import { useAppSelector } from 'hooks/redux';
import { useSearchParams } from 'react-router';
import { selectTasks } from 'store/tasks/tasksSlice';

const EscalatedTasksFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { tasksSummary } = useAppSelector(selectTasks);
  const count = tasksSummary.escalationsCount;
  const counterLabel = count && count > 99 ? '99+' : count;
  const defaultSelected = searchParams.get('escalated') ? ['Escalations'] : ['All'];

  const handleSelect = (filter: Common.DataItemProps) => {
    if (filter.label === 'Escalations') {
      searchParams.set('escalated', 'true');
      setSearchParams(searchParams);
    }
    if (filter.label === 'All') {
      searchParams.delete('escalated');
      setSearchParams(searchParams);
    }
  };

  return count ? (
    <div>
      <Common.Tabs
        type="line"
        data={[
          { label: 'All' },
          { label: 'Escalations', count: counterLabel, badge: true, isUrgent: true }
        ]}
        onChange={handleSelect}
        defaultSelected={defaultSelected}
      ></Common.Tabs>
    </div>
  ) : null;
};

export default EscalatedTasksFilter;
