import { Common } from '@thecvlb/design-system/lib/src';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';

import { CancelAppointmentsProps } from './cancelAppointments.types';

const CancelAppointments: React.FC<CancelAppointmentsProps> = ({
  patientName,
  upcomingAppointments
}) => {
  const { handleOpenTask } = useOpenTask();

  return (
    <div className="flex flex-col gap-4">
      <p className="mb-2 text-base font-medium text-gray-700">
        The following appointments must first be cancelled. {patientName} will not be charged for
        these.
      </p>
      {upcomingAppointments?.map((appointment) => (
        <div className="rounded-2xl shadow" key={appointment?._id}>
          <Common.Appointment
            onClick={() =>
              handleOpenTask({
                taskId: appointment.taskId,
                assignToId: appointment.doctorId,
                assignToName: appointment.staffName,
                shouldClosePrevModal: true
              })
            }
            title={appointment?.appointmentType?.displayName}
            {...(appointment.appointmentMethod === 'message'
              ? { type: 'message' }
              : appointment?.appointmentTime?.startTime && {
                  startTime: appointment?.appointmentTime?.startTime || ''
                })}
          />
        </div>
      ))}
    </div>
  );
};

export default CancelAppointments;
