import { createEntityAdapter, createSelector, createSlice, EntityId } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Status } from 'enums/messages';
import groupBy from 'lodash/groupBy';
import type { RootState } from 'store';

import { formatSMSs } from './sms.settigns';
import { SMSBodyProps, SMSProps } from './sms.types';
import { apiSlice } from '../api/apiSlice';

const smsAdapter = createEntityAdapter<SMSProps, EntityId>({
  selectId: (sms) => sms._id,
  sortComparer: (a, b) => a.createdAt?.localeCompare(b.createdAt)
});

const smsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      getSMS: build.query<
        { smsArray: SMSProps[]; totalCount: number },
        { patientId: string; limit: number; pageNo: number }
      >({
        query: ({ patientId, limit, pageNo }) => ({
          url: `/sms/${patientId}/history`,
          params: {
            limit,
            pageNo
          }
        }),
        transformResponse: (response: { data: SMSProps[]; info: { totalCount: number } }) => ({
          smsArray: response.data,
          totalCount: response.info.totalCount
        })
      }),
      sendSMS: build.mutation<SMSProps, { patientId: string; body: SMSBodyProps }>({
        query: ({ patientId, body }) => ({
          url: `/sms/${patientId}/send`,
          method: 'POST',
          body: body
        }),
        transformResponse: (response: { data: SMSProps }) => response.data
      })
    };
  }
});

const initialState = { SMSs: smsAdapter.getInitialState(), totalCount: 0, status: Status.Idle };

let lastRequestId: string;

const smsSlice = createSlice({
  name: 'sms',
  initialState,
  reducers: {
    resetSMSs: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(smsApiSlice.endpoints.getSMS.matchPending, (state, action) => {
      lastRequestId = action.meta.requestId;
      state.status = Status.Pending;
    });
    builder.addMatcher(smsApiSlice.endpoints.getSMS.matchFulfilled, (state, { payload, meta }) => {
      if (lastRequestId === meta.requestId) {
        smsAdapter.upsertMany(state.SMSs, payload.smsArray);
        state.totalCount = payload.totalCount;
        state.status = Status.Fulfilled;
      } else {
        state = initialState;
      }
    });
    builder.addMatcher(smsApiSlice.endpoints.getSMS.matchRejected, (state) => {
      state.status = Status.Rejected;
    });
    builder.addMatcher(smsApiSlice.endpoints.sendSMS.matchFulfilled, (state, { payload }) => {
      const newMessage = {
        _id: payload._id,
        message: payload.message,
        status: payload.status,
        createdAt: payload.createdAt,
        senderDetails: payload.receiverDetails
      };
      smsAdapter.upsertOne(state.SMSs, newMessage as SMSProps);
      state.status = Status.Fulfilled;
    });
    builder.addMatcher(smsApiSlice.endpoints.sendSMS.matchRejected, (state) => {
      state.status = Status.Rejected;
    });
  }
});

export const smsSelectors = smsAdapter.getSelectors<RootState>((state) => state.sms.SMSs);

export const selectSMS = (state: RootState) => state.sms;

export const selectFormattedSMSs = createSelector(smsSelectors.selectAll, (SMSs) => {
  const formattedSMSs = formatSMSs(SMSs);
  return groupBy(formattedSMSs, (sms) => dayjs(sms.date).format(DateFormat.MM_DD_YYYY));
});

export const selectLatestSMS = createSelector(smsSelectors.selectAll, (SMSs) => {
  return SMSs[SMSs.length - 1];
});

export const { resetSMSs } = smsSlice.actions;
export const { useLazyGetSMSQuery, useSendSMSMutation } = smsApiSlice;

export default smsSlice.reducer;
