import { Setting } from 'models/settings.types';

import { apiSlice } from '../api/apiSlice';

const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getGlobalSettings: build.query<Setting[], void>({
      query: () => '/global-settings',
      providesTags: ['Settings'],
      keepUnusedDataFor: 300000 // 5 minutes
    }),
    updateGlobalSetting: build.mutation({
      query: ({ id, body }) => ({
        url: `/global-settings/${id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['Settings']
    })
  })
});

export const { useGetGlobalSettingsQuery, useUpdateGlobalSettingMutation } = settingsApiSlice;
