import { SEX_AT_BIRTH } from 'constants/user';

import { PatientLabels } from 'enums/orderLabels';
import { Address, PatientProps } from 'store/orders/orders.types';
import { addDashesToPhoneNumber } from 'utils/helpers';

import { OrderFormStateProps, PatientInfoItem } from './patientInfo.types';

const getAddress = (address: Address) => {
  const results = [];
  if (address.line1) {
    results.push(address.line1);
  }
  if (address.line2) {
    results.push(address.line2);
  }
  if (address.city) {
    results.push(address.city);
  }
  if (address.state) {
    results.push(address.state);
  }
  if (address.postal) {
    results.push(address.postal);
  }
  return results.join(', ');
};

export const getItems = (patient: PatientProps): PatientInfoItem[] => {
  return [
    { label: PatientLabels.FirstName, value: patient.firstName },
    { label: PatientLabels.LastName, value: patient.lastName },
    { label: PatientLabels.Email, value: patient.email },
    { label: PatientLabels.Address, value: getAddress(patient.address) },
    {
      label: PatientLabels.SexAtBirth,
      value: SEX_AT_BIRTH.find((item) => item.value === patient.sexAtBirth)?.label || '-'
    },
    ...(patient.phone.internationalFormat
      ? [
          {
            label: PatientLabels.Phone,
            isUnderline: true,
            value: addDashesToPhoneNumber(patient.phone?.internationalFormat)
          }
        ]
      : []),
    { label: PatientLabels.Allergies, value: patient.allergies },
    {
      label: PatientLabels.Medications,
      value: Object.values(patient.medications?.map((medication) => medication.name) || {}).join(
        ', '
      )
    },
    { label: PatientLabels.Conditions, value: patient.conditions }
  ];
};

export const getSubmitOrderData = (formData: OrderFormStateProps) => {
  return {
    patient: {
      address: {
        line1: formData.line1,
        city: formData.city,
        state: formData.state,
        postal: formData.postal
      }
    }
  };
};

export const states = [
  {
    label: 'Alabama',
    value: 'Alabama'
  },
  {
    label: 'Alaska',
    value: 'Alaska'
  },
  {
    label: 'American Samoa',
    value: 'American Samoa'
  },
  {
    label: 'Arizona',
    value: 'Arizona'
  },
  {
    label: 'Arkansas',
    value: 'Arkansas'
  },
  {
    label: 'California',
    value: 'California'
  },
  {
    label: 'Colorado',
    value: 'Colorado'
  },
  {
    label: 'Connecticut',
    value: 'Connecticut'
  },
  {
    label: 'Delaware',
    value: 'Delaware'
  },
  {
    label: 'District of Columbia',
    value: 'District of Columbia'
  },
  {
    label: 'Florida',
    value: 'Florida'
  },
  {
    label: 'Georgia',
    value: 'Georgia'
  },
  {
    label: 'Guam',
    value: 'Guam'
  },
  {
    label: 'Hawaii',
    value: 'Hawaii'
  },
  {
    label: 'Idaho',
    value: 'Idaho'
  },
  {
    label: 'Illinois',
    value: 'Illinois'
  },
  {
    label: 'Indiana',
    value: 'Indiana'
  },
  {
    label: 'Iowa',
    value: 'Iowa'
  },
  {
    label: 'Kansas',
    value: 'Kansas'
  },
  {
    label: 'Kentucky',
    value: 'Kentucky'
  },
  {
    label: 'Louisiana',
    value: 'Louisiana'
  },
  {
    label: 'Maine',
    value: 'Maine'
  },
  {
    label: 'Maryland',
    value: 'Maryland'
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts'
  },
  {
    label: 'Michigan',
    value: 'Michigan'
  },
  {
    label: 'Minnesota',
    value: 'Minnesota'
  },
  {
    label: 'Mississippi',
    value: 'Mississippi'
  },
  {
    label: 'Missouri',
    value: 'Missouri'
  },
  {
    label: 'Montana',
    value: 'Montana'
  },
  {
    label: 'Nebraska',
    value: 'Nebraska'
  },
  {
    label: 'Nevada',
    value: 'Nevada'
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire'
  },
  {
    label: 'New Jersey',
    value: 'New Jersey'
  },
  {
    label: 'New Mexico',
    value: 'New Mexico'
  },
  {
    label: 'New York',
    value: 'New York'
  },
  {
    label: 'North Carolina',
    value: 'North Carolina'
  },
  {
    label: 'North Dakota',
    value: 'North Dakota'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands'
  },
  {
    label: 'Ohio',
    value: 'Ohio'
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma'
  },
  {
    label: 'Oregon',
    value: 'Oregon'
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania'
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico'
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island'
  },
  {
    label: 'South Carolina',
    value: 'South Carolina'
  },
  {
    label: 'South Dakota',
    value: 'South Dakota'
  },
  {
    label: 'Tennessee',
    value: 'Tennessee'
  },
  {
    label: 'Texas',
    value: 'Texas'
  },
  {
    label: 'U.S. Virgin Islands',
    value: 'U.S. Virgin Islands'
  },
  {
    label: 'Utah',
    value: 'Utah'
  },
  {
    label: 'Vermont',
    value: 'Vermont'
  },
  {
    label: 'Virginia',
    value: 'Virginia'
  },
  {
    label: 'Washington',
    value: 'Washington'
  },
  {
    label: 'West Virginia',
    value: 'West Virginia'
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin'
  },
  {
    label: 'Wyoming',
    value: 'Wyoming'
  }
];
