import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledCombobox from 'components/forms/controlled/ControlledCombobox';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import { useAppDispatch } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { Option as SelectOption } from 'models/form.types';
import { Controller, useForm } from 'react-hook-form';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { useCreateTaskStaffNoteMutation } from 'store/staffNotes/staffNotesSlice';
import { AvailableStaffResponse } from 'store/tasks/task.types';
import { useAssignTaskToTeamMutation, useEscalateTaskMutation } from 'store/tasks/tasksSlice';
import { useLazyGetTeamsQuery } from 'store/teams/teamsSlice';

import type {
  EscalateTaskFormState,
  EscalateTaskProps,
  StaffMemberOption
} from './escalateTask.types';
import { useGetAvailableStaff } from '../AssignToUser/assignToUser.settings';
import TaskUpdated from '../CreateTaskForm/TaskUpdated';

const EscalateTask = ({
  taskId,
  isTeams,
  isAssign,
  taskCategory,
  patientId
}: EscalateTaskProps) => {
  const dispatch = useAppDispatch();
  const isAssignToTeam = isTeams && isAssign;

  const [query, setQuery] = useState('');
  const { searchData, isLoading, isSuccess } = useGetAvailableStaff(query, taskId);
  const [sendTaskStaffNote] = useCreateTaskStaffNoteMutation();

  const { handleSubmit, control, setValue, clearErrors, watch, formState } =
    useForm<EscalateTaskFormState>({
      mode: 'onChange',
      reValidateMode: 'onSubmit'
    });

  const [escalateTask, { isLoading: isEscalateLoading }] = useEscalateTaskMutation();
  const [assignTaskToTeam, { isLoading: isLoadingAssignTask }] = useAssignTaskToTeamMutation();
  const [getTeams, { data: teams }] = useLazyGetTeamsQuery();

  const teamsOptions = useMemo(
    () => teams?.teams?.map((team) => ({ label: team.name, value: team._id })),
    [teams]
  );
  const formData = watch();
  const { dirtyFields } = formState;

  const staffErrors = (formData?.staffMember?.value as AvailableStaffResponse)?.warning;
  const shouldConfirm = (formData?.staffMember?.value as AvailableStaffResponse)?.warning
    ?.shouldConfirm;
  const wrongRole =
    (formData?.staffMember?.value as AvailableStaffResponse)?.warning?.canAssignStaff === false;
  const noAvailableStaffMembers =
    isSuccess && !isLoading && !searchData.length ? { warning: 'No staff members found' } : null;

  const actionDisabled =
    isLoadingAssignTask ||
    isEscalateLoading ||
    (shouldConfirm && !formData.isConfirmed) ||
    isEmpty(dirtyFields) ||
    wrongRole;

  useEffect(() => {
    if (isTeams) {
      getTeams({});
    }
  }, [isTeams]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleChangeStaff = (option: Option) => {
    if (!option?.value) return;
    clearErrors('staffMember');
    setValue('staffMember', option as StaffMemberOption);
  };

  const handleSelectTeam = (option: SelectOption) => {
    clearErrors('teams');
    setValue('teams', option);
  };

  const openSuccessModal = () =>
    dispatch(
      openModal({
        modalContent: (
          <div className="p-6">
            <TaskUpdated
              type="assigned"
              assignedTo={formData.staffMember?.label ?? formData.teams?.label}
              patientId={patientId ?? ''}
              taskCategory={taskCategory ?? ''}
              closeModal={() => dispatch(closeModal())}
              heading={isAssignToTeam ? 'Assign to team' : 'Escalate task'}
            />
          </div>
        ),
        size: 'sm',
        hideClose: true
      })
    );

  const handleEscalateTask = (form: EscalateTaskFormState) => {
    if (!isTeams && form.staffMember?.value?._id && form.isConfirmed) {
      clearErrors('staffMember');
    }

    const body = {
      ...(!isTeams && { userId: form.staffMember?.value?._id }),
      ...(!isTeams && { isConfirmed: form.isConfirmed }),
      ...(isTeams && { teamIds: [form.teams.value] }),
      ...(form.note && { note: form.note.trim() })
    };

    const request = isAssignToTeam ? assignTaskToTeam : escalateTask;

    request({ taskId, ...body })
      .unwrap()
      .then((data) => {
        notifySuccess(data.message);
        if (form.note?.trim()) {
          sendTaskStaffNote({
            taskId,
            body: {
              note: `Escalation notes: ${form.note.trim()}`,
              isUrgent: true,
              staffNoteFile: null
            }
          });
        }
        openSuccessModal();
      });
  };

  return (
    <form
      data-testid="escalation_popup"
      onSubmit={handleSubmit(handleEscalateTask)}
      className="flex flex-col gap-4 p-6"
    >
      <h2 className="mb-2 text-xl font-bold text-gray-700">
        {isAssign ? 'Assign to team' : 'Escalate task'}
      </h2>

      {!isTeams && (
        <ControlledCombobox
          size="sm"
          label="Staff member"
          labelDirection="col"
          control={control}
          name="staffMember"
          defaultValue=""
          options={searchData}
          isLoading={isLoading}
          preIcon="search"
          inputValue={query}
          onChange={handleChangeStaff}
          onInputChange={handleInputChange}
          hideComboboxButton
          errors={staffErrors || noAvailableStaffMembers}
          helperText={staffErrors?.message || noAvailableStaffMembers?.warning}
          placeholder="Search staff"
          rules={{
            required: {
              value: true,
              message: 'Please select a staff member'
            }
          }}
        />
      )}

      {isTeams && (
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={teamsOptions}
          placeholder="Select team"
          label="Select team"
          name="teams"
          onChange={handleSelectTeam}
          rules={{
            required: {
              value: true,
              message: 'Please select a team'
            }
          }}
        />
      )}

      {shouldConfirm && (
        <Controller
          control={control}
          name="isConfirmed"
          render={({ field }) => (
            <Common.Checkbox
              dataTestId="confirm_not_urgent_checkbox"
              checked={field.value}
              onChange={field.onChange}
              value={field.value as unknown as string}
              size="sm"
            >
              I confirm that the task is not urgent so it can wait more than 24 hours
            </Common.Checkbox>
          )}
        />
      )}
      {!isAssignToTeam && (
        <ControlledTextArea
          control={control}
          name="note"
          label="Notes (optional):"
          labelDirection="col"
          placeholder="Please describe why you’re escalating this task..."
          rows={3}
        />
      )}

      <div className="mt-2 grid grid-cols-2 gap-x-2">
        <Common.Button
          type="button"
          color="white-alt"
          className="!w-full justify-center"
          onClick={() => dispatch(closeModal())}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button
          className="!w-full justify-center"
          type="submit"
          color="blue"
          size="sm"
          disabled={actionDisabled}
          isLoading={isEscalateLoading}
        >
          Assign
        </Common.Button>
      </div>
    </form>
  );
};

export default EscalateTask;
