import { useCallback, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { getDateValue, useDelayedScroll } from './picker.settings';
import { DataProps, PickerProps } from './picker.types';
import PickerSkeleton from './PickerSkeleton';
import { Provider } from '../dateAndTime.types';

const Picker: React.FC<PickerProps> = ({
  data,
  isLoading,
  isUnlimitedPlan,
  date,
  onConfirm,
  hideDate = false,
  setProvider,
  provider = Provider.primary,
  isReschedule,
  shouldDisabledButton
}) => {
  const { setValue } = useFormContext();
  const [selectedTime, setSelectedTime] = useState<string | Date>('');
  const [, updateState] = useState<{ ['key']?: string }>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const scrollBlock = useRef<HTMLDivElement | null>(null);
  const scrollStopper = useDelayedScroll(forceUpdate, 200);

  const getSlotClassNames = (item: DataProps) =>
    classNames(
      'py-5 md:py-4 w-full !leading-3 border rounded-lg',
      !isLoading ? 'hover:bg-primary-100' : 'opacity-50',
      selectedTime === item.value ? 'bg-primary-100 border-primary-300 font-bold' : 'bg-white'
    );

  const confirmButtonClassName = (value: string | Date) =>
    classNames(
      selectedTime === value ? 'visible w-full ml-2' : 'invisible w-0',
      'py-[18px] md:py-3.5 border transition-all duration-300 text-white rounded-lg font-bold bg-primary border-primary'
    );

  const noSlotsTextClassName = 'mt-4 block text-center italic text-gray';
  const slotsWrapperClassName =
    'flex max-h-[45vh] flex-col gap-y-3 overflow-auto overflow-x-hidden md:h-[280px] md:gap-y-2';
  const wrapperClassName = 'relative mx-auto max-h-full min-h-[10rem] w-full overflow-hidden';
  const dateClassName = 'mb-3 text-center text-mSm text-gray md:text-sm';

  useEffect(() => {
    const item = data.find((slot) => slot.value === selectedTime);
    if (item) {
      !!item?.doctorId && setValue('doctorId', item.doctorId);
      !!item?.displayName && setValue('randomProviderName', item.displayName);
      setValue('endTime', item.valueEnd);
      setValue('startTime', item.value);
    }
  }, [data, setValue, selectedTime]);

  // Resetting the time value when changing the provider to turn off the active button
  useEffect(() => {
    setSelectedTime('');
  }, [provider]);

  return (
    <div className={wrapperClassName}>
      {!hideDate && <p className={dateClassName}>{getDateValue(date)}</p>}
      {isLoading ? (
        <PickerSkeleton />
      ) : (
        <>
          {data.length ? (
            <div className={slotsWrapperClassName} ref={scrollBlock} onScroll={scrollStopper}>
              {data.map((dataItem: DataProps, keyId: number) => (
                <div className="flex justify-center" key={`${keyId}_${dataItem.value}`}>
                  <button
                    data-testid="time_slot"
                    className={getSlotClassNames(dataItem)}
                    disabled={isLoading}
                    type="button"
                    onClick={() =>
                      setSelectedTime(dataItem.value === selectedTime ? '' : dataItem.value)
                    }
                  >
                    {dataItem.label}
                  </button>
                  <button
                    className={confirmButtonClassName(dataItem.value)}
                    type="button"
                    onClick={onConfirm}
                    disabled={shouldDisabledButton}
                  >
                    {shouldDisabledButton ? (
                      <Common.Icon name="loader" className="mx-auto size-5 animate-spin" />
                    ) : isReschedule ? (
                      'Reschedule'
                    ) : isUnlimitedPlan ? (
                      'Confirm'
                    ) : (
                      'Continue'
                    )}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <>
              <span data-testid="no_slots_text" className={noSlotsTextClassName}>
                {provider === Provider.primary
                  ? 'Primary provider is unavailable for the selected day.'
                  : 'There are no available providers for the selected day.'}
              </span>
              {setProvider && provider !== Provider.any && (
                <Common.Button
                  className="mx-auto mt-4"
                  color="blue-alt"
                  onClick={() => setProvider(Provider.any)}
                  type="button"
                >
                  See any available provider
                </Common.Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Picker;
