import { createContext, PropsWithChildren, useContext } from 'react';

import {
  DeleteMessageFromServerParams,
  UpdatedMessageToServerParams
} from 'contexts/MessagesContext/messagesContext.types';
import {
  useDeleteStaffNoteMutation,
  useUpdateStaffNoteMutation
} from 'store/staffNotes/staffNotesSlice';

import { StaffNotesContextProps } from './staffNotesContext.types';

const StaffNotesContext = createContext<StaffNotesContextProps | undefined>(undefined);

const StaffNotesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [updateMessage] = useUpdateStaffNoteMutation();
  const [deleteMessage] = useDeleteStaffNoteMutation();

  /**
   * Sends updated message to the server.
   *
   * @param {Object} params - object with the necessary params.
   * @returns {void}
   */
  const sendUpdatedMessageToServer = (params: UpdatedMessageToServerParams) => {
    updateMessage(params);
  };

  /**
   * Deletes message from the server.
   *
   * @param {Object} params - object with the necessary params.
   * @returns {void}
   */
  const deleteMessageFromServer = (params: DeleteMessageFromServerParams) => {
    deleteMessage(params);
  };

  const value = {
    sendUpdatedMessageToServer,
    deleteMessageFromServer
  };

  return <StaffNotesContext.Provider value={value}>{children}</StaffNotesContext.Provider>;
};

StaffNotesProvider.displayName = 'Staff Notes Provider';

const useStaffNote = () => {
  const context = useContext(StaffNotesContext);
  if (context === undefined) {
    throw new Error(`useStaffNote must be used within a StaffNotesProvider`);
  }
  return context;
};

export { StaffNotesProvider as StaffNotesProvider, useStaffNote };
