import { useEffect, useMemo, useState } from 'react';

import { rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  FilterFn,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import SearchForm from 'components/filters/SearchForm';
import OrderPane from 'components/order/orderPane';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import { columns } from 'components/tables/OrdersTable/columns';
import OrdersTable from 'components/tables/OrdersTable/OrdersTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch } from 'hooks/redux';
import { PatientsQueryParams } from 'models/patients.types';
import queryString from 'query-string';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router';
import { open } from 'store/orderPane/orderPaneSlice';
import { useGetOrdersQuery } from 'store/orders/ordersSlice';

import { OrdersTableTypes } from '../../components/tables/OrdersTable/ordersTable.types';
import { OrderProps } from '../../store/orders/orders.types';

const Orders: React.FC = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const queryParams: PatientsQueryParams = useMemo(() => {
    const parsedQueryParams = queryString.parse(searchParams.toString());
    // remove orderModalOpenID from request payload
    if (parsedQueryParams?.orderModalOpenID) delete parsedQueryParams?.orderModalOpenID;

    return parsedQueryParams;
  }, [searchParams]);

  const { data, isFetching, isUninitialized } = useGetOrdersQuery({ params: queryParams });

  const [orderData, setOrderData] = useState([] as OrderProps[]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [globalFilter, setGlobalFilter] = useState('');

  const fuzzyFilter: FilterFn<OrdersTableTypes> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: orderData
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    state: { globalFilter, sorting },
    initialState: {
      pagination: {
        pageSize: 50
      }
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  const totalCount = data?.totalCount || 0;

  const filterData = [
    { label: 'All', value: 'all' },
    { label: 'Needs insurance', value: 'needs-insurance' }
  ];

  const [filterTab, setFilterTab] = useState(filterData[0].label);

  const handleFilterChange = (val: Common.DataItemProps) => {
    const searchQuery = {
      ...queryString.parse(location.search)
    };

    if (val.value === 'needs-insurance') {
      searchQuery.needsInsurance = 'true';
      setFilterTab(filterData[1].label);
      setSearchParams(searchQuery as URLSearchParamsInit);
    } else if (val.value === 'all') {
      delete searchQuery.needsInsurance;
      setFilterTab(filterData[0].label);
      setSearchParams(searchQuery as URLSearchParamsInit);
    }
  };

  useEffect(() => {
    if (data) {
      setOrderData(data.orders);
    }
  }, [data]);

  const handleOpenOrder = (orderId: string) => {
    const searchQuery = {
      ...queryString.parse(location.search),
      orderModalOpenID: orderId
    };

    setSearchParams(searchQuery as URLSearchParamsInit);
  };

  useEffect(() => {
    const orderId = searchParams.get('orderModalOpenID');

    if (orderId) {
      dispatch(open({ orderId: orderId }));
    }
  }, [queryParams, dispatch, searchParams]);

  useEffect(() => {
    const parsedQueryParams = queryString.parse(searchParams.toString());
    if (!parsedQueryParams.sortField) {
      const searchQuery = {
        ...queryString.parse(location.search),
        sortField: 'metadata.createdAt',
        sortOrder: 'DESC',
        limit: '50'
      };
      setSearchParams(searchQuery as URLSearchParamsInit);
    }
    if (parsedQueryParams.needsInsurance === 'true') {
      setFilterTab(filterData[1].label);
    }
    // NOTE: because we need set search params only on mount phase
  }, []);

  return (
    <>
      <div className="flex items-center justify-between bg-gray-50 py-5">
        <Common.Tabs
          data={filterData}
          onChange={handleFilterChange}
          type="pill"
          defaultSelected={[filterTab]}
        />

        <div className="relative">
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags />
      <div className="-mx-6 h-[calc(100%-72px)] overflow-y-auto px-6">
        <Card className="mb-6">
          <OrdersTable
            table={table}
            totalCount={totalCount}
            isFetching={isFetching}
            onClickRow={(row) => {
              const order = row as OrderProps;
              handleOpenOrder(order.id);
            }}
          />
        </Card>
      </div>
      <OrderPane />
    </>
  );
};

export default Orders;
