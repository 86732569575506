import { ArticleProps, GetArticlesQueryParams } from 'models/articles.types';

import { apiSlice } from '../api/apiSlice';

const articlesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<
      { articles: ArticleProps[]; totalCount: number },
      { params: GetArticlesQueryParams }
    >({
      query: ({ params }) => ({
        url: '/articles',
        params: params
      }),
      transformResponse: (response: { data: ArticleProps[]; info: { totalCount: number } }) => ({
        articles: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'Article',
              { type: 'Article', id: 'LIST' },
              ...result.articles.map(({ _id }) => ({ type: 'Article' as const, id: _id }))
            ]
          : ['Article']
    }),
    getArticle: build.query<ArticleProps, { id: string }>({
      query: ({ id }) => `/articles/${id}`,
      transformResponse: (response: { data: ArticleProps }) => response.data,
      providesTags: (_result, _error, arg) => [{ type: 'Article', id: arg.id }]
    }),
    addArticle: build.mutation({
      query: ({ body }) => ({
        url: `/articles`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Article']
    }),
    addArticleImage: build.mutation({
      query: ({ body }) => {
        const bodyFormData = new FormData();
        for (const key in body) {
          bodyFormData.append(key, body[key]);
        }
        return {
          url: `/articles/images`,
          method: 'POST',
          body: bodyFormData
        };
      }
    }),
    updateArticle: build.mutation({
      query: ({ id, body }) => ({
        url: `/articles/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Article', id: arg.id }]
    }),
    deleteArticle: build.mutation<ArticleProps, { id: string }>({
      query: ({ id }) => ({
        url: `/articles/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Article', id: 'LIST' }]
    })
  })
});

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  useLazyGetArticleQuery,
  useAddArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useAddArticleImageMutation
} = articlesApi;
