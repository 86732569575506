import { apiSlice } from '../api/apiSlice';
import { AppointmentTypeLengths, EditLengthDefaultsBody } from '../appointments/appointments.types';

const appointmentLengthApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAppointmentLengths: build.query<AppointmentTypeLengths[], void>({
      query: () => ({ url: `appointment-lengths`, params: { limit: 50 } }),
      transformResponse: (response: { data: AppointmentTypeLengths[] }) => response.data,
      providesTags: ['AppointmentLengths']
    }),
    editAppointmentLengths: build.mutation<
      { message: string },
      { plans: EditLengthDefaultsBody[] }
    >({
      query: ({ plans }) => ({
        url: `appointment-lengths`,
        method: 'PATCH',
        body: { plans }
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['AppointmentLengths']
    })
  })
});

export const { useGetAppointmentLengthsQuery, useEditAppointmentLengthsMutation } =
  appointmentLengthApiSlice;
