import { Dispatch } from 'react';

import omit from 'lodash/omit';
import queryString from 'query-string';
import { DefaultValues, FieldValues, KeepStateOptions } from 'react-hook-form';
import { NavigateFunction } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';

import { FiltersType } from './filters.types';

export const handleReset = (
  dispatch: Dispatch<unknown>,
  reset: (values?: DefaultValues<FieldValues>, keepStateOptions?: KeepStateOptions) => void,
  defaultValues: FieldValues,
  navigate: NavigateFunction
) => {
  const defaultUrlParams = { ...queryString.parse(location.search), pageNo: '0' };

  reset(defaultValues);

  navigate({
    search: queryString.stringify(omit(defaultUrlParams, Object.keys(defaultValues)))
  });

  dispatch(closeModal());
};

export function getAppliedFilterValues(filters: FiltersType) {
  return Object.fromEntries(Object.entries(filters).filter(([, value]) => !!value));
}
