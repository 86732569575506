import type { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { OrderForFollowUp, RequestReason } from 'store/tasks/task.types';

export interface CreateTaskFormProps {
  patient?: { id: string; fullName: string; state?: string } | null;
}

export interface CreateTaskFormState {
  patient: { id: string; label: string; value: string };
  assignTo: string;
  staff: Option;
  taskDetails: string;
  dueDate: { date: string; time: string };
  isUrgent: boolean;
  taskCategory: { label: string; value: string };
  medication?: { label: string; value: string };
  requestReason?: RequestReason[];
  orderForFollowUp?: OrderForFollowUp;
}

export enum TaskCategory {
  Request = 'Request',
  RequestPA = 'Request PA',
  RequestOrder = 'Request • Order'
}

export interface OrderForFollowUpOption {
  value: string;
  label: string;
}

export interface FormatPrescriptionLabelProps {
  name: string;
  signedAt: string | null;
  isTasked: boolean;
}
