import { BulkEditResponseProps } from 'components/modals/bulkEditModals/UpdateFailed/updateFailed.types';
import {
  FrontDeskRequest,
  FrontDeskRequestType,
  FrontDeskRequestTypesQueryParams
} from 'store/frontDeskRequestTypes/frontDeskRequestTypes.types';

import { apiSlice } from '../api/apiSlice';

const frontDeskRequestTypesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFrontDeskRequestTypes: build.query<
      FrontDeskRequest,
      { params: FrontDeskRequestTypesQueryParams }
    >({
      query: ({ params }) => ({ url: `/front-desk-request-type`, params }),
      transformResponse: (response: {
        data: FrontDeskRequestType[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'FrontDeskRequestType',
              ...result.data.map(({ _id }) => ({ type: 'FrontDeskRequestType' as const, id: _id }))
            ]
          : ['FrontDeskRequestType']
    }),
    getFrontDeskRequestType: build.query<FrontDeskRequestType, { id: string }>({
      query: ({ id }) => `/front-desk-request-type/${id}`,
      transformResponse: (response: { data: FrontDeskRequestType }) => response.data
    }),

    createFrontDeskRequestType: build.mutation({
      query: (body) => ({
        url: `/front-desk-request-type`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: FrontDeskRequestType; message: string }) => response,
      invalidatesTags: ['FrontDeskRequestType']
    }),

    updateFrontDeskRequestTypes: build.mutation({
      query: (body) => ({
        url: `/front-desk-request-types-bulk-update`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: BulkEditResponseProps) => response,
      invalidatesTags: ['FrontDeskRequestType']
    }),

    deleteFrontDeskRequestTypes: build.mutation({
      query: ({ body }) => ({
        url: `/front-desk-request-types-bulk-delete`,
        method: 'DELETE',
        body
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['FrontDeskRequestType']
    })
  })
});

export const {
  useGetFrontDeskRequestTypesQuery,
  useLazyGetFrontDeskRequestTypesQuery,
  useLazyGetFrontDeskRequestTypeQuery,
  useCreateFrontDeskRequestTypeMutation,
  useDeleteFrontDeskRequestTypesMutation,
  useUpdateFrontDeskRequestTypesMutation
} = frontDeskRequestTypesApi;
