import { StaffsFilterData } from 'constants/filters';

import { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import BulkEdit from 'components/filters/BulkEdit';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/StaffsTable/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router';
import { useLazyGetStaffsQuery } from 'store/staffs/staffsSlice';

const Staffs: React.FC = () => {
  const [getStaffs, { data, isFetching, isUninitialized }] = useLazyGetStaffsQuery();
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.staffs
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getStaffs({ params: queryString.parse(decodeURIComponent(location.search)) }, true);
  }, [getStaffs, navigate, location.search]);

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StaffsFilterData} queryParam="userType.name" />
        <div className="flex gap-2">
          <BulkEdit />
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default Staffs;
