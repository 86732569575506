import { memo, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import ManageMessageIcon from 'assets/icons/chat/ManageMessage.svg?react';
import classNames from 'classnames';
import BasicConfirmation from 'components/modals/BasicConfirmation';
import EditForm from 'components/patient/Channel/EditForm/EditForm';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { MessageType } from 'enums/messages';
import { Role } from 'enums/role';
import { useClientMessages } from 'hooks/common/useClientMessages';
import Linkify from 'linkify-react';
import { useToggle } from 'react-use';
import { parseHTMLToText } from 'utils/common/parseHTML';

import {
  DELETE_MESSAGE_CONFIRMATION_SUBHEADER_TEXT,
  DELETED_MESSAGE_TEXT,
  filePreview,
  getProfileImageWrapperClassName,
  getUserRole,
  parseEditedString,
  parseString
} from './message.settings';
import { MessageProps } from './message.types';
import ManageMessageDropdown from '../ManageMessageDropdown';
import ManageMessageTooltip from '../ManageMessageTooltip';

const loadImageGallery = () => import('components/modals/TaskPopover/TaskDetails/ImageGallery');

const Message = ({
  date,
  deletedBy,
  fileName,
  handleImageClick,
  history,
  id,
  isBlurred,
  isGrouped,
  isStaffNote,
  name,
  profileImage,
  seenByPatient,
  self,
  sentViaSms,
  src,
  text,
  type,
  userType,
  ref
}: MessageProps) => {
  const useMessages = useClientMessages({ type });
  const { deleteMessageFromServer } = useMessages();
  const isEdited = history && history.length > 0;
  const isDeleted = !!deletedBy;

  const [isEditedMessagesDisplayed, toggleIsEditedMessageDisplayed] = useToggle(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);
  const [showAvatar, setShowAvatar] = useState(false);

  const messageRef = useRef<HTMLDivElement>(null);
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);

  const deletedMessageText = DELETED_MESSAGE_TEXT.replace('{name}', deletedBy || name || '');

  useEffect(() => {
    if (isEditing) {
      // Set the height of the editorWrapper to match the original message's height
      if (messageRef.current && editorWrapperRef.current) {
        editorWrapperRef.current.style.height = `${messageRef.current.offsetHeight}px`;
      }

      // Start showing the avatar after a delay
      const timer = setTimeout(() => {
        setShowAvatar(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setShowAvatar(false); // Hide avatar when not editing
    }
  }, [isEditing]);

  useEffect(() => {
    // Reset styles when editing mode changes
    if (messageRef.current) messageRef.current.style.cssText = '';
  }, [isEditing]);

  const handleEditClick = () => {
    setIsEditing(true);
    setIsDropdownVisible(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(text);
    setShowAvatar(false);
    if (messageRef.current) messageRef.current.style.cssText = '';
  };

  const handleDeleteClick = () => setModalVisible(true);
  const handleCancel = () => setModalVisible(false);

  const handleDeleteMessage = () => {
    setModalVisible(false);
    deleteMessageFromServer({ messageId: id, type });
    // Hide the edited messages list when the core message is deleted
    if (isEditedMessagesDisplayed) {
      toggleIsEditedMessageDisplayed();
    }
  };

  const handleMouseEnter = () => {
    setIsDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false);
  };

  const shouldShowDropdown = isDropdownVisible && !seenByPatient;
  const shouldShowTooltip = isDropdownVisible && seenByPatient;
  const shouldShowBasicConfirmation = !isDeleted && self;
  const shouldShowEditedMessagesToggle = isEdited && !isDeleted && self && type !== MessageType.SMS;
  const shouldShowEditForm = isEditing && type !== MessageType.SMS;
  const shouldShowManageMessageIcon = !isDeleted && type && type !== MessageType.SMS && self;

  const isViaBraze = sentViaSms;
  const containerClasses = classNames('flex flex-col gap-3 mb-1', {
    'pb-3': !isGrouped
  });

  const messageClasses = classNames('relative w-fit py-3 px-5 rounded-2xl', {
    'border border-solid border-gray-300': !self && isStaffNote && !isDeleted,
    'border border-solid border-secondary-400': self && isStaffNote && !isDeleted,
    'bg-secondary-100': self && !isStaffNote && !isDeleted,
    'bg-gray-100': !self && !isStaffNote && !isDeleted,
    'rounded-br-none': !isGrouped && self,
    'rounded-bl-none': !isGrouped && !self,
    '-mt-2': !!src,
    'bg-white outline-dashed outline-gray-300 text-gray-500': isDeleted
  });

  const messageEditClasses = classNames(
    'relative w-full pl-2 rounded-2xl flex justify-between bg-white border-blue-500 border-2'
  );

  const wrapperMessageBlockClasses = classNames(
    `flex flex-col gap-3 ${isEditing ? 'w-full py-1' : ''}`,
    {
      'items-end': self
    }
  );

  const messageWrapperClasses = getProfileImageWrapperClassName(self, isGrouped);
  const mediaWrapperClasses = classNames('w-fit rounded-2xl py-3 px-5', {
    'ml-auto bg-secondary-100': self,
    'mr-auto bg-gray-100': !self,
    'rounded-br-none': isGrouped && self,
    'rounded-bl-none': isGrouped && !self
  });

  const imageClasses = classNames(
    'h-[120px] max-w-[380px] shadow-sm rounded-xl object-cover',
    !text && !isGrouped && (self ? 'rounded-br-none' : 'rounded-bl-none')
  );

  const nameClasses = classNames('flex text-gray items-center text-xs text-left -mt-2 mb-2 gap-1', {
    'ml-auto flex-row-reverse': self,
    'mr-auto': !self
  });

  const normalProfileImageClasses = classNames('block mb-[26px]', self ? 'ml-3' : 'mr-3');

  const editingProfileImageClasses = classNames(
    'block bottom-8 transition-opacity duration-10 ease-in-out',
    {
      'opacity-0': !showAvatar,
      'opacity-100': showAvatar,
      [self ? 'ml-3' : 'mr-3']: true
    }
  );

  const editedMessagesListClasses = classNames(
    'flex gap-2 w-fit rounded-2xl py-3 px-5 rounded-br-none border mr-12 border-grey-300 ml-auto'
  );

  const seenStatusClasses = classNames('text-mXs text-gray-500', self ? 'text-right' : 'text-left');
  const roleTagClasses = classNames('rounded px-1 text-xs', {
    'text-yellow-600 bg-yellow-100': userType?.name === 'Patient',
    'text-blue-600 bg-blue-100': userType?.name === Role.PH,
    'ml-1': !self,
    'mr-1': self
  });

  const renderEditedMessagesToggle = () => (
    <span
      className="ml-1 flex cursor-pointer px-1 text-mXs font-semibold text-blue-700"
      onClick={() => toggleIsEditedMessageDisplayed()}
    >
      {isEditedMessagesDisplayed ? 'Close' : 'Edited'}
    </span>
  );

  return (
    <div id={id} className={containerClasses} ref={ref}>
      {isEditedMessagesDisplayed &&
        history &&
        history.map((editedMessage) => (
          <div className={editedMessagesListClasses}>
            {parseEditedString(editedMessage.message)}
          </div>
        ))}
      <div data-testid="message" className={messageWrapperClasses}>
        {!isGrouped && (
          <Common.ProfileImage
            src={profileImage}
            className={isEditing ? editingProfileImageClasses : normalProfileImageClasses}
          />
        )}
        <div className={wrapperMessageBlockClasses}>
          {src &&
            filePreview({
              fileName,
              imageClasses,
              mediaWrapperClasses,
              onImageClick: () => handleImageClick?.(src),
              onFocusImg: loadImageGallery,
              onMouseEnterImg: loadImageGallery,
              src,
              isBlurred
            })}
          {text &&
            (shouldShowEditForm ? (
              <div
                className={classNames(messageEditClasses)}
                ref={editorWrapperRef} // EditorWrapper ref for setting height
              >
                <EditForm
                  className="min-h-9 w-full resize-none items-center overflow-hidden rounded-2xl border-none bg-white pr-[140px] focus:ring-transparent"
                  editedText={editedText as string}
                  messageId={id}
                  originalMessageText={text}
                  ref={editorRef}
                  setEditedText={setEditedText}
                  setIsEditing={handleCancelEdit}
                  type={type}
                />
              </div>
            ) : (
              <div data-testid="msg_container" className={messageClasses} ref={messageRef}>
                {shouldShowManageMessageIcon && (
                  <div
                    className="relative"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <ManageMessageIcon
                      data-testid="message-arrow-icon"
                      width={20}
                      height={20}
                      fill="#fff"
                      className="absolute right-0 top-0 -mr-6 -mt-5"
                    />
                    {shouldShowDropdown && (
                      <>
                        <div className="absolute right-0 top-3 -mr-6 -mt-5 h-5 w-40" />
                        <ManageMessageDropdown
                          onDelete={handleDeleteClick}
                          onEdit={handleEditClick}
                        />
                      </>
                    )}
                    {shouldShowTooltip && <ManageMessageTooltip />}
                  </div>
                )}
                <Linkify
                  options={{
                    className: 'text-base underline text-primary break-all',
                    target: '_blank'
                  }}
                >
                  {isDeleted ? (
                    <p className="text-base">{deletedMessageText}</p>
                  ) : (
                    parseString(text)
                  )}
                </Linkify>
              </div>
            ))}
          {!isGrouped && (
            <div data-testid="message_datestamp" className={nameClasses}>
              {shouldShowEditedMessagesToggle && renderEditedMessagesToggle()}
              <span className={seenStatusClasses}>
                {!self
                  ? `${name} • ${dayjs(date).format(DateFormat.h_mm_a)}`
                  : `${seenByPatient ? 'Seen • ' : 'Sent • '} ${dayjs(date).format(DateFormat.h_mm_a)}`}
              </span>
              {getUserRole(userType?.name) && (
                <span className={roleTagClasses}>{getUserRole(userType?.name)}</span>
              )}
              {isViaBraze && (
                <span className="ml-1 flex rounded bg-gray-100 px-1 text-xs text-gray-600">
                  Sent via SMS
                </span>
              )}
            </div>
          )}
          {isGrouped && shouldShowEditedMessagesToggle && (
            <div data-testid="message_datestamp" className={nameClasses}>
              {renderEditedMessagesToggle()}
            </div>
          )}
          {shouldShowBasicConfirmation && (
            <BasicConfirmation
              onClose={handleCancel}
              isOpen={isModalVisible}
              onConfirm={handleDeleteMessage}
              isLoading={false}
              confirmButtonText="Delete"
              headerText="Delete message"
              subHeaderText={
                parseHTMLToText({
                  text: DELETE_MESSAGE_CONFIRMATION_SUBHEADER_TEXT,
                  className: 'text-gray-700'
                }) as string
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

Message.displayName = 'Message';

export default memo(Message);
