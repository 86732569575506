import { Common } from '@thecvlb/design-system';
import { toast } from 'react-toastify';

export const notifyError = (message: string) =>
  toast.error(message, {
    closeOnClick: true,
    className: 'justify-start',
    icon: <Common.Icon name="error" className="size-9 text-red" />,
    toastId: message
  });

export const notifySuccess = (message: string) =>
  toast.success(message, {
    closeOnClick: true,
    className: 'justify-start',
    icon: <Common.Icon name="check" color="text-secondary" />,
    toastId: message
  });
