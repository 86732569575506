import { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { Option } from 'models/form.types';
import { DefaultValues, FieldValues, KeepStateOptions } from 'react-hook-form';

import { useAppliedFilters } from './useAppliedFilters';
import { getAlertUnitValue, mapFilterToFormData } from './useTableFiltersData.setting';
import { DataProps, FilterOptionsProps, FilterProps } from './useTableFiltersData.types';

export function useTableFiltersData(
  tableType: string,
  defaultValues: DefaultValues<FieldValues>,
  reset: (values?: DefaultValues<FieldValues>, keepStateOptions?: KeepStateOptions) => void,
  customOptionsList?: {
    tagOptions?: Option[];
    teamsOptions?: Option[];
    taskTagsOptions?: Option[];
    planOptions?: Option[];
  }
) {
  const { appliedFilters } = useAppliedFilters();
  const { tagOptions, teamsOptions, taskTagsOptions, planOptions } = customOptionsList ?? {};
  const [formData, setFormData] = useState<DefaultValues<FieldValues>>(defaultValues);

  useEffect(() => {
    const data: DataProps = cloneDeep(defaultValues ?? {});
    appliedFilters
      .map((item) =>
        mapFilterToFormData(
          item as FilterProps,
          tableType,
          tagOptions,
          teamsOptions,
          taskTagsOptions,
          planOptions
        )
      )
      .forEach(([key, value]) => {
        (data as DataProps)[key] = value;
      });

    if (data?.alertUnit && data?.occurenceOrder) {
      (data as DataProps).alertUnit = getAlertUnitValue(
        data.alertUnit as FilterProps,
        data.occurenceOrder as FilterProps
      );
    }
    if (data?.triggerUnit && data?.['triggerTime.occurenceOrder']) {
      (data as DataProps).triggerUnit = getAlertUnitValue(
        data.triggerUnit as FilterProps,
        data['triggerTime.occurenceOrder'] as FilterProps
      );
    }

    if (data?.onboardingStatuses) {
      const convertItem = (item: FilterOptionsProps) => ({
        label: `${item.groupLabel}: ${item.label}`,
        value: item.value
      });

      data.onboardingStatuses = Array.isArray(data?.onboardingStatuses)
        ? data.onboardingStatuses.map((item: FilterOptionsProps) => convertItem(item))
        : convertItem(data.onboardingStatuses as FilterOptionsProps);
    }

    const isSelectedPatientState = appliedFilters.findIndex(
      (item) => item.label === 'patientStates'
    );
    if (isSelectedPatientState !== -1) {
      const selectedStates = appliedFilters[isSelectedPatientState].value;
      data.patientStates = Array.isArray(selectedStates)
        ? selectedStates.map((item) => ({ label: item, value: item }))
        : [{ label: selectedStates, value: selectedStates }];
    }

    const isSelectedStates = appliedFilters.findIndex((item) => item.label === 'states');
    if (isSelectedStates !== -1) {
      const selectedStates = appliedFilters[isSelectedStates].value;
      data.states = Array.isArray(selectedStates)
        ? selectedStates.map((item) => ({ label: item, value: item }))
        : [{ label: selectedStates, value: selectedStates }];
    }

    setFormData(data);
  }, [appliedFilters, defaultValues, tableType, tagOptions, teamsOptions, taskTagsOptions]);

  useEffect(() => {
    reset({ ...defaultValues, ...formData });
  }, [formData, reset, defaultValues]);

  return [formData, setFormData] as const;
}
