export enum DateFormat {
  /** Start from hour */
  hh = 'hh', // 01
  hh_mm = 'hh:mm', // 01:30
  HH_mm = 'HH:mm', // 14:30
  HH_mm_ss = 'HH:mm:ss', // 14:30:30

  ha = 'ha', // 1am
  h_a = 'h a', // 1 am
  h_mma = 'h:mma', // 1:00pm
  h_mm_a = 'h:mm a', // 1:00 pm
  hh_mm_a = 'hh:mm a', // 01:00 am
  hh_mm_A = 'hh:mm A', // 01:00 AM
  HH_mm_a = 'HH:mm a', // 13:00 pm
  hh_mma = '(hh:mm a)', // (01:00 am)

  h_mm_a_z = 'h:mm a z', // 1:00 am EST
  h_ma_z = 'h:ma z', // 1:1am EST
  h_mma_z = 'h:mma z', // 1:00am EST
  hh_mma_z = 'hh:mm a z', // 01:00 am EST

  h_mm_a_z__MMM_D_YYYY = 'h:mm a z · MMM D, YYYY', // 1:00 am EST • Jan 1, 2018

  /** Start from day */
  D = 'D', // 1
  DD = 'DD', // 01
  ddd = 'ddd', // Sun
  dddd = 'dddd', // Sunday

  dddd__h_a = 'dddd • h a', // Sunday • 1 am
  dddd_h_mm_a = 'dddd h:mm a', // Sunday 1:00 am

  dddd_MMMM_D = 'dddd, MMMM D', // Sunday, January 1
  dddd_MMM_DD = 'dddd MMM DD', // Sunday Jan 01

  dddd_MMMM_D_h_mm_A = 'dddd, MMMM D · h:mm A', // Sunday, January 1 · 1:00 AM
  dddd_MMMM_D_h_mma_z = 'dddd, MMMM D · h:mm a z', // Sunday, January 1 · 1:00am EST
  dddd_MMMM_DD_YYYY = 'dddd, MMMM DD, YYYY', // Sunday, January 01, 2018

  /** Start from month */
  mm = 'mm', // 59
  MMM = 'MMM', // Jan
  MM_DD = 'MM/DD', // 01/01
  MMM_D = 'MMM D', // Jan 1
  MMM_DD = 'MMM DD', // Jan 01
  MMMM_D = 'MMMM D', // January 1
  MMMM_Do = 'MMMM Do', // July 1st

  MMM_YY = "MMM 'YY", // 1
  MMMM_YYYY = 'MMMM YYYY', // January 2018
  MMMM__YYYY = 'MMMM • yyyy', // January • 2018

  MM_DD_YYYY = 'MM/DD/YYYY', // 01/01/2018
  MM_DD_YY = 'MM/DD/YY', // 01/01/18
  MMM_D_YYYY = 'MMM D, YYYY', // Jan 1, 2018
  MMM_DD_YYYY = 'MMM DD, YYYY', // Jan 01, 2018
  MMM_DYYYY = 'MMM D YYYY', // Jan 1 2018
  MMM_D__YYYY = 'MMM D • YYYY', // Jan 1 • 2018
  MMM_DD__YYYY = 'MMM DD • YYYY', // Jan 01 • 2018

  MMM_D_h_mma = 'MMM D · h:mma', // Jan 1 • 1:00 am
  MMM_D_h_mm_a_z = 'MMM D, h:mm a z', // Jan 1, 1:00 am EST
  MMM_D__h_mma_z = 'MMM D • h:mma z', // Jan 1 • 1:00am EST
  MMM_D__h_mm_a_z = 'MMM D · h:mm a z', // Jan 1 • 1:00 am EST
  MMM_DD__hh_mm_a = 'MMM DD • hh:mm a', // Jan 01 • 01:00 am
  MMMM_D_h_mma_z = 'MMMM D, h:mm a z', // January 1, 1:00 am EST

  MM_DD_YYYY_HH_mm = 'MM/DD/YYYY HH:mm', // 01/01/2018 01:00
  MM_DD_YYYY_HH_mmA = 'MM/DD/YYYY HH:mmA', // 01/01/2018 01:00AM
  MMM_D_YYYY_h_mm_a_z = 'MMM D, YYYY • h:mm a z', // Oct 28, 2023 • 7:40 pm GMT+3

  /** Start from year */
  YYYY = 'YYYY', // 2024
  YYYY_MM_DD = 'YYYY-MM-DD', // 2024-01-01

  YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss', // 2024-01-01 01:00:00
  YYYY_MM_DD__HH_mm_ss = 'YYYY-MM-DD_HH:mm:ss', // 2024-01-01_01:00:00
  YYYY_MMM_DD_hh_mm_A = 'YYYY-MMM-DD-hh:mm A', // 2024-Jan-01-01:00 AM

  YYYY_MM_DDTHH_mm_ss_sss = 'YYYY-MM-DDTHH:mm:ss.sss', // 2024-01-01T01:00:00.000
  YYYY_MM_DDTHH_mm_ss_ssZ = 'YYYY-MM-DDTHH:mm:ss.sssZ', // 2024-01-01T01:00:00.000Z
  YYYY_MM_DDTHH_mm_ss_ss_Z = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', // 2024-01-01T01:00:00.000Z

  /** Custom format */
  z = 'z', // 2018-01-01
  a = 'a', // 2018-01-01

  dddd_for_h_a = 'dddd for h a' // Sunday for 1 am
}
