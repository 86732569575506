import { useMemo } from 'react';

import queryString, { ParsedQuery } from 'query-string';
import { useSearchParams } from 'react-router';

/**
 * @description
 * return parsed url search params
 */
export const useParsedURLSearchParams = () => {
  const [searchParams] = useSearchParams();

  const queryParams: ParsedQuery<string> = useMemo(() => {
    const params = queryString.parse(searchParams.toString());
    if (params.searchKey) {
      params.searchKey = decodeURIComponent(params.searchKey as string);
    }
    return params;
  }, [searchParams]);

  return { queryParams };
};
