import React from 'react';

import DocumentInfo from 'components/patient/Documents/Document/DocumentInfo';
import { DocumentsDataItemProps } from 'components/patient/Documents/documents.types';
import TextareaAutosize from 'react-textarea-autosize';

const ViewDetailsDocument: React.FC<{
  document: DocumentsDataItemProps;
}> = ({ document }) => {
  const { fileName, createdAt, uploadedBy, isUploadedByCurrentUser, tags, description } = document;
  const textareaClassName =
    'block outline-none resize-none border w-full rounded-md border-gray-200 outline-0 shadow-sm pb-3.5 pt-4 px-4 text-gray-700 focus:border-gray-200 disabled:bg-gray-100 disabled:shadow-none sm:text-base sm:pb-2.5 sm:pt-3 sm:px-3';

  return (
    <div className="p-6">
      <h3 className="truncate text-lg font-bold">View details: “{fileName}”</h3>
      <DocumentInfo
        title={fileName}
        date={createdAt}
        uploadedByName={uploadedBy}
        byCurrentUser={isUploadedByCurrentUser}
        tags={tags}
      />
      {description && (
        <TextareaAutosize
          disabled
          className={textareaClassName}
          placeholder="Enter a note about this patient..."
          value={description}
          maxRows={10}
        />
      )}
    </div>
  );
};

export default ViewDetailsDocument;
