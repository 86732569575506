import { ALERT_UNIT_OPTIONS } from 'constants/alerts';
import {
  AUDIENCE_OPTIONS,
  FUTURE_TASKS_FILTERS,
  ONBOARDING_STATUSES_EXTENDED
} from 'constants/taskFilter';
import { CATEGORY_OPTIONS_HISTORY } from 'constants/tasks';

import { PROBLEM_TYPE_FILTER } from 'components/modals/CustomerFeedbackFilters/customerFeedbackFiltersForm.settings';
import { URGENCY_TASK_FILTER } from 'components/modals/NotificationsFiltersForm/NotificationsFiltersForm.settings';
import {
  ISSUE_DESCRIPTION_FILTER,
  ISSUE_TYPE_FILTER,
  STATUS_FILTER
} from 'components/modals/StaffFeedbackFiltersForm/staffFeedbackFiltersForm.settings';
import startCase from 'lodash/startCase';
import { Option } from 'models/form.types';

interface FilterProps {
  label: string;
  value: string | string[] | { label: string; value: string };
}

const getOnboardingStatuses = (name: string) => {
  let option;
  ONBOARDING_STATUSES_EXTENDED.forEach((item) => {
    if (item.value === name) {
      option = item;
    } else if (!!item.options) {
      const groupedOption = item.options.find((groupOption) => groupOption.value === name);
      if (groupedOption) option = groupedOption;
    }
  });
  return option;
};

const getEventKey = (name: string) => {
  return CATEGORY_OPTIONS_HISTORY.find((item) => item.value === name);
};

export const mapFilterToFormData = (
  filter: FilterProps,
  tableType: string,
  options?: Option[],
  teamsOptions?: Option[],
  taskTagsOptions?: Option[],
  planOptions?: Option[]
): [string, unknown] => {
  const findInOptions = (
    label: string,
    value: FilterProps['value'],
    optionsArray?: Option[]
  ): [string, unknown] => {
    const result =
      optionsArray?.filter((item) => {
        if (Array.isArray(value)) {
          return value.includes(item.value);
        } else if (typeof value === 'string') {
          return value === item.value;
        }
      }) || [];
    return [label, result?.map((item: Option) => ({ label: item.label, value: item.value }))];
  };

  const generateSimpleObject = (label: string, value: FilterProps['value']): [string, unknown] => [
    label,
    {
      label: value,
      value
    }
  ];

  const generateSimpleArray = (label: string, value: FilterProps['value']): [string, unknown] => {
    const currentArray = Array.isArray(value)
      ? value.map((name: string) => ({
          value: name,
          label: name
        }))
      : typeof value === 'string'
        ? [
            {
              label: value,
              value: value
            }
          ]
        : [];

    return [label, currentArray];
  };

  switch (filter.label) {
    case 'eventKey':
      if (typeof filter.value === 'string') {
        return [filter.label, { ...getEventKey(filter.value) }];
      } else if (Array.isArray(filter.value)) {
        return [filter.label, filter.value.map((name: string) => getEventKey(name))];
      }
      break;
    case 'onboardingStatuses':
      if (typeof filter.value === 'string') {
        return [filter.label, getOnboardingStatuses(filter.value)];
      } else if (Array.isArray(filter.value)) {
        return [filter.label, filter.value.map((name: string) => getOnboardingStatuses(name))];
      }
      break;
    case 'patientNames':
    case 'assignedToNames':
    case 'activeStates':
    case 'eventType':
      return generateSimpleArray(filter.label, filter.value);
    case 'issueDescriptions':
      const issueDescriptionsOptions = (
        Array.isArray(filter.value) ? filter.value : [filter.value]
      ).map((issueDescription) =>
        ISSUE_DESCRIPTION_FILTER.find((issue) => issue.value === issueDescription)
      );
      return [filter.label, issueDescriptionsOptions];
    case 'userType.name':
      return generateSimpleObject('userType', filter.value);
    case 'doctorName':
    case 'membership':
    case 'taskType':
    case 'criterionField':
    case 'alertUnit':
    case 'occurenceOrder':
    case 'triggerTime.occurenceOrder':
    case 'audience':
    case 'pharmacyType':
    case 'orderStatus':
    case 'triggerUnit':
    case 'length':
    case 'triggerType':
    case 'assignedTo':
    case 'payType':
    case 'staff':
    case 'appointmentType':
    case 'state':
    case 'prospectStatus':
    case 'rating':
    case 'providerName':
      return generateSimpleObject(filter.label, filter.value);
    case 'futureTasksRange':
      return [filter.label, FUTURE_TASKS_FILTERS.find((item) => item.value === filter.value)];
    case 'planId':
      return [filter.label, planOptions?.find((item) => item.value.toString() === filter.value)];
    case 'urgencyTask':
      return [
        filter.label,
        URGENCY_TASK_FILTER.find((item) => item.value.toString() === filter.value)
      ];
    case 'tags':
    case 'patientStates':
    case 'patientTags':
      return findInOptions(filter.label, filter.value, options);
    case 'teams':
      return findInOptions(filter.label, filter.value, teamsOptions);
    case 'taskTags':
      return findInOptions(filter.label, filter.value, taskTagsOptions);
    case 'audiences':
      return findInOptions(filter.label, filter.value, AUDIENCE_OPTIONS);
    case 'author.name':
      return ['author', filter.value];
    case 'audience.shortCode':
      return generateSimpleObject('audience', filter.value);
    case 'status':
      if (['staffFeedback'].includes(tableType)) {
        return [
          filter.label,
          STATUS_FILTER.find((statusFilter) => statusFilter.value === filter.value)
        ];
      }
      if (
        [
          'patients',
          'staffs',
          'roles',
          'aging',
          'appointmentTypes',
          'articles',
          'frontdeskRequestTypes',
          'patientMessageTemplates',
          'internalNoteTemplates',
          'internalAutomations',
          'patientAutomations',
          'billingConfiguration',
          'staffCosts',
          'reviews'
        ].includes(tableType)
      ) {
        return [
          filter.label,
          {
            label: startCase(typeof filter.value === 'string' ? filter.value : ''),
            value: filter.value
          }
        ];
      }
      break;
    case 'problemType':
      if (['customerFeedback'].includes(tableType)) {
        return [
          filter.label,
          PROBLEM_TYPE_FILTER.find((problemType) => problemType.value === filter.value)
        ];
      }
      break;
    case 'issueType':
      if (['staffFeedback'].includes(tableType)) {
        return [
          filter.label,
          ISSUE_TYPE_FILTER.find((issueType) => issueType.value === filter.value)
        ];
      }
      break;
    case 'patientName':
      if (['reviews', 'customerFeedback', 'staffFeedback'].includes(tableType)) {
        return generateSimpleObject(filter.label, filter.value);
      }
      break;
    case 'category':
      if (
        ['appointmentTypes', 'articles', 'billingConfiguration', 'activityFeed'].includes(tableType)
      ) {
        return generateSimpleObject(filter.label, filter.value);
      }
      break;
    case 'triggerTime.timeInHours':
      return ['timeInHours', filter.value];

    default:
      return [filter.label, filter.value];
  }
  return [filter.label, filter.value];
};

export const getAlertUnitValue = (alertUnit: FilterProps, occurenceOrder: FilterProps) => {
  const unit =
    alertUnit.value === 'hrs' || alertUnit.value === 'hours'
      ? 'Hours'
      : alertUnit.value === 'min' || alertUnit.value === 'minutes'
        ? 'Mins'
        : '';
  const label = `${unit} (${occurenceOrder.value})`;
  const value = ALERT_UNIT_OPTIONS.find((item) => item.label === label);
  return value ?? alertUnit;
};
