import React, { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/BillingHistoryTransactions/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import isEqual from 'lodash/isEqual';
import { usePrevious } from 'react-use';
import type { PaymentTransactionType } from 'store/patients/patients.types';
import { useLazyGetPaymentTransactionsQuery } from 'store/patients/patientsSlice';

import useFilteredParams from '../useFilteredParams';

const TransactionsTable: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const [getPaymentTransactions, { data: transactions, isFetching, isUninitialized }] =
    useLazyGetPaymentTransactionsQuery();

  const filteredParams = useFilteredParams();
  const prevQueryParams = usePrevious(filteredParams);

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: !!isFetching || !!isUninitialized,
    columns: columns(import.meta.env.VITE_CHARGIFY_SERVER),
    data: transactions?.data
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<PaymentTransactionType, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  useEffect(() => {
    if (patientId && !isEqual(prevQueryParams, filteredParams)) {
      getPaymentTransactions({ id: patientId, params: filteredParams });
    }
  }, [patientId, getPaymentTransactions, filteredParams, prevQueryParams]);

  return (
    <BasicTable
      table={table}
      totalCount={transactions?.info.totalCount || 0}
      isFetching={isFetching}
    />
  );
};

export default TransactionsTable;
