import { Common } from '@thecvlb/design-system';
import CommonPopover from 'components/common/popovers/CommonPopover';
import ControlledDateRangePickerInput from 'components/forms/controlled/ControlledDateRangePickerInput';
import TableFilters from 'components/modals/TableFilters';
import { PathName } from 'enums/pathName';
import { useHeaderParams } from 'hooks/common/useHeaderParams';
import { useLocation } from 'react-router';

const SearchForm: React.FC<{ showSearchBar?: boolean }> = () => {
  const { onClickNew, buttonTitle } = useHeaderParams();
  const location = useLocation();

  return (
    <div data-testid="search_filter_section" className="flex items-center gap-2">
      {location.pathname === PathName.Prospect && (
        <div data-testid="date_range_picker_field" className="mr-1 h-8 w-60">
          <ControlledDateRangePickerInput />
        </div>
      )}
      <CommonPopover>
        <TableFilters />
      </CommonPopover>
      {onClickNew && (
        <Common.Button
          style="pill"
          preIcon="plus-small"
          color="green-alt"
          size="sm"
          onClick={onClickNew}
        >
          {buttonTitle}
        </Common.Button>
      )}
    </div>
  );
};

export default SearchForm;
