import { ArticlesFilterFormProps } from './articlesFilterForm.types';

export const defaultValues: ArticlesFilterFormProps = {
  title: '',
  category: { value: '', label: '' },
  searchKey: '',
  author: '',
  createdAt: '',
  uniqueId: '',
  'author.name': '',
  'audience.shortCode': '',
  audience: { value: '', label: '' },
  status: { value: '', label: '' }
};
