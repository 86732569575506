import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { apiSlice } from 'store/api/apiSlice';

import { FeedbackListProps, FeedbackQueryParams, initialState } from './customerFeedback.types';

const customerFeedbackApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCustomerFeedbacks: build.query<FeedbackListProps, { params: FeedbackQueryParams }>({
      query: ({ params }) => ({ url: `/admin/feedbacks`, params }),
      transformResponse: (response: FeedbackListProps) => ({
        data: response.data,
        info: response.info
      })
    })
  })
});

export const { useLazyGetCustomerFeedbacksQuery } = customerFeedbackApi;

const customerFeedbackSlice = createSlice({
  name: 'customerFeedback',
  initialState: initialState,
  reducers: {
    setCustomerFeedback: (state, action) => {
      state.data = action.payload.data;
      state.info = action.payload.info;
    },
    resetCustomerFeedback: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      customerFeedbackApi.endpoints.getCustomerFeedbacks.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.info = action.payload.info;
        }
      }
    );
  }
});

export const selectCustomerFeedback = (state: RootState) => state.customerFeedback;

export default customerFeedbackSlice.reducer;

export const { setCustomerFeedback, resetCustomerFeedback } = customerFeedbackSlice.actions;
