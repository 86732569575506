import TemplateList from './TemplateList';
import { TemplatesProps } from './templates.types';

const Templates = ({ onTemplateListClick, ref }: TemplatesProps) => {
  return (
    <div
      className="absolute right-0 top-[-5.5rem] z-10 h-60 w-full -translate-y-2/3 rounded-lg rounded-b-none bg-white py-2 shadow-lg"
      ref={ref}
    >
      <p
        data-testid="templates_block_header"
        className="mx-3 my-2 text-base font-bold text-gray-700"
      >
        Templates
      </p>

      <TemplateList onTemplateClick={onTemplateListClick} />
    </div>
  );
};

export default Templates;
