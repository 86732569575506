import { ReportsFilterData } from 'constants/filters';

import { useEffect, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import FilterButtons from 'components/filters/FilterTabs';
import { DashboardCard } from 'components/reports/DashboardCard/dashboardCard';
import { LeaderboardCard } from 'components/reports/LeaderboardCard';
import ReportTable from 'components/reports/ReportTable/ReportTable';
import Header from 'components/tables/Header';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PathName } from 'enums/pathName';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import fileDownload from 'js-file-download';
import { getDefaultReportsParams } from 'pages/AdminReports/adminReports.settings';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router';
import { TableProps } from 'store/medicalAssistantReports/medicalAssistantReports.types';
import {
  useLazyDownloadMedicalAssistantReportsQuery,
  useLazyGetMyReportsLeaderboardQuery,
  useLazyGetMyReportsTasksQuery,
  useLazyGetTasksReportQuery
} from 'store/medicalAssistantReports/medicalAssistantReportsSlice';

import { DashboardTextProps, LeaderboardTextProps } from './medicalAssistantCards.types';

const MedicalAssistantReports: React.FC = () => {
  const [getMyReportsTasks, { data: myReportsData, isFetching: isFetchingDashboard }] =
    useLazyGetMyReportsTasksQuery();
  const [getLeaderboard, { data: leaderboardData, isFetching: isFetchingLeaderboard }] =
    useLazyGetMyReportsLeaderboardQuery();
  const [getTasksReport, { data: tasksReportData, isFetching: isFetchingTable }] =
    useLazyGetTasksReportQuery();
  const [getDownload, { isFetching: isFetchingDownload }] =
    useLazyDownloadMedicalAssistantReportsQuery();
  const [dashboardCardData, setDashboardCardData] = useState<DashboardTextProps[]>();
  const [leaderboardCardData, setLeaderboardCardData] = useState<LeaderboardTextProps[]>();
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const isLoadingReports = isFetchingDashboard || isFetchingLeaderboard || isFetchingTable;
  const isDisabledDownload = isLoadingReports || isFetchingDownload;

  const dynamicColumns = (rowData?: TableProps[]): ColumnDef<TableProps>[] => {
    return rowData
      ? Object.keys(rowData[0]).map((item) => ({
          header: () => <Header text={item} sortField={item} />,
          accessorKey: item,
          cell: ({ getValue }) => {
            const value = getValue();
            return <span className="font-bold">{value as string}</span>;
          }
        }))
      : [];
  };

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetchingTable,
    data: tasksReportData?.tasksReport || [],
    columns: dynamicColumns(tasksReportData?.tasksReport)
  });

  const requestsToStaffTable = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<TableProps>[],
    getCoreRowModel: getCoreRowModel()
  });

  const onClickDownload = () => {
    getDownload({
      params:
        '?' +
        queryString.stringify({
          startDate: parsed.startDate as string,
          endDate: parsed.endDate as string
        })
    })
      .unwrap()
      .then((res) => {
        fileDownload(
          res,
          `lifemd-care-report-${dayjs().format(DateFormat.YYYY_MMM_DD_hh_mm_A)}.csv`
        );
      });
  };

  const deleteSortParams = (parsedDateData: typeof parsed) => {
    const newParams = { ...parsedDateData };
    delete newParams.sortField;
    delete newParams.sortOrder;
    return newParams;
  };

  useEffect(() => {
    if (myReportsData) {
      const dbCardData = Object.entries(myReportsData.myReportsTasks).map((item) => {
        return {
          key: item[0],
          value: item[1]
        };
      });
      setDashboardCardData(dbCardData);
    }
  }, [myReportsData]);

  useEffect(() => {
    if (leaderboardData) {
      const lCardData = leaderboardData?.myReportsLeaderboard?.map((item) => {
        return {
          rank: item?.Rank,
          name: item?.Staff,
          completedCount: item?.Completed,
          id: item._id || nanoid()
        };
      });
      setLeaderboardCardData(lCardData);
    }
  }, [leaderboardData]);

  useEffect(() => {
    if (location.search) {
      // Get table data with all sort params
      getTasksReport({ params: location.search }, true);

      // Get dashboard and leaderboard data without sort params
      const paramsWithoutSort = deleteSortParams(parsed);
      const queryParams = '?' + queryString.stringify(paramsWithoutSort);
      getMyReportsTasks({ params: queryParams }, true);
      getLeaderboard({ params: queryParams }, true);
    } else if (!location.search) {
      navigate(
        {
          pathname: PathName.MedicalAssistantReports,
          search: queryString.stringify(getDefaultReportsParams())
        },
        { replace: true }
      );
    }
  }, [location.search, navigate, getTasksReport, getMyReportsTasks, getLeaderboard]);

  return (
    <div className="flex-col items-center gap-y-3">
      <div className="mb-5 flex justify-between">
        <div className="flex items-center gap-3">
          <FilterButtons filterData={ReportsFilterData} queryParam="dateType" />
        </div>
        <Common.Button
          size="sm"
          color="green-alt"
          style="pill"
          preIcon="download"
          disabled={isDisabledDownload}
          onClick={onClickDownload}
        >
          <span>Download</span>
        </Common.Button>
      </div>
      <div className="mb-5 flex justify-between gap-5">
        <DashboardCard
          title="My Tasks"
          text={dashboardCardData}
          color="secondary-light"
          isInfoRequired={false}
          isFetching={isFetchingDashboard}
        />
        <LeaderboardCard
          title="Leaderboard"
          text={leaderboardCardData}
          color="secondary-dark"
          isFetching={isFetchingLeaderboard}
        />
      </div>
      <ReportTable
        title="My Tasks"
        table={requestsToStaffTable}
        totalCount={0}
        isFetching={isFetchingTable}
      />
    </div>
  );
};

export default MedicalAssistantReports;
