import { createColumnHelper } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { ActionLogs } from 'store/history/history.types';
import { getTaskEventHistoryColor } from 'utils/tasks';

import EventDetails from './EventDetails';
import Header from '../Header';

const columnHelper = createColumnHelper<ActionLogs>();

export const columns = [
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date" sortField="createdAt" />,
    cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_D_YYYY_h_mm_a_z)}</span>,
    size: 185
  }),
  columnHelper.accessor('eventType', {
    header: () => <Header text="Event" sortField="eventType" />,
    cell: (info) => (
      <Common.ColorTag color={getTaskEventHistoryColor(info.row.original)} text={info.getValue()} />
    ),
    size: 160
  }),
  columnHelper.accessor('eventDetails', {
    header: () => <Header text="Details" sortField="eventDetails" />,
    cell: (info) => <EventDetails eventLog={info.row.original} />
  }),
  columnHelper.accessor('createdBy', {
    header: () => <Header text="Author" sortField="createdBy" />,
    cell: (info) => <span>{info.getValue()}</span>
  })
];
