import { ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';
import { DEFAULT_SEARCH_PARAMS } from 'constants/tables';

import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { PathName } from 'enums/pathName';
import { RoleFormStateProps } from 'pages/ManageRole/manageRole.types';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useGetPermissionsQuery } from 'store/lookup/lookupSlice';
import {
  useAddRoleMutation,
  useDeleteRoleMutation,
  useLazyGetRoleQuery,
  useUpdateRoleMutation
} from 'store/roles/rolesSlice';

import { ManageRoleFormProps } from './manageRoleForm.types';
import { PermissionList } from '../PermissionList/PermissionList';
import { RoleInformation } from '../RoleInformation/RoleInformation';

const ManageRoleForm: React.FC<ManageRoleFormProps> = ({
  tabLinks,
  roleRef,
  pageRef,
  editingRef
}) => {
  const { roleId = '' } = useParams<{ roleId: string }>();
  const navigate = useNavigate();

  const isNew = roleId === 'new';

  const { data: permissions } = useGetPermissionsQuery();
  const [getRole, { data, isLoading: isLoadingRole }] = useLazyGetRoleQuery();
  const [updateRole, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] =
    useUpdateRoleMutation();
  const [addRole, { isLoading: isLoadingAdd, isSuccess: isSuccessAdd }] = useAddRoleMutation();
  const [deleteRole, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] =
    useDeleteRoleMutation();

  const [postPagePermissions, setPostPagePermissions] = useState<string[]>([]);
  const [postEditPermissions, setPostEditPermissions] = useState<string[]>([]);

  const { control, handleSubmit, reset, formState } = useForm<RoleFormStateProps>({
    reValidateMode: 'onChange'
  });

  const handleDeleteRole = () => {
    deleteRole({ id: roleId })
      .unwrap()
      .then((result) => notifySuccess(result.message));
  };

  const handleFormSubmit = (formData: RoleFormStateProps) => {
    const body = {
      name: formData.name,
      userType: formData.role.value || formData.role,
      shortName: formData?.shortName,
      color: formData.color,
      status: formData.status.value || formData.status,
      pagePermissions: postPagePermissions,
      editingPermissions: postEditPermissions
    };
    if (isNew) {
      addRole({ body })
        .unwrap()
        .then((result) => notifySuccess(result.message));
    } else {
      updateRole({ id: roleId, body })
        .unwrap()
        .then((result) => notifySuccess(result.message));
    }
  };

  useEffect(() => {
    if (isNew) {
      return;
    }
    if (roleId) {
      getRole({ id: roleId }, true);
    }
  }, [getRole, roleId, isNew]);

  useEffect(() => {
    if (isSuccessUpdate || isSuccessDelete || isSuccessAdd) {
      navigate(
        { pathname: PathName.ManageRoles, search: DEFAULT_SEARCH_PARAMS },
        { replace: true }
      );
    }
  }, [isSuccessUpdate, isSuccessDelete, isSuccessAdd, navigate]);

  useEffect(() => {
    if (data) {
      setPostPagePermissions(data.pagePermissions);
      setPostEditPermissions(data.editingPermissions);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        color: data.color,
        shortName: data.shortName,
        role: ROLE_OPTIONS.find((item) => item.value === data.userType.name),
        status: STATUS_OPTIONS.find((item) => item.value === data.status)
      });
    }
  }, [data, reset]);

  return (
    <>
      <Loader isVisible={isLoadingUpdate || isLoadingDelete || isLoadingAdd || isLoadingRole} />
      <form onSubmit={handleSubmit(handleFormSubmit)} className="flex-1">
        <div className="flex justify-between">
          <h2 data-testid="heading" className="text-2xl font-bold text-secondary">
            Master admin
          </h2>
          <div className="flex gap-3">
            {!isNew && (
              <Common.Button
                dataTestId="delete_role_btn"
                onClick={handleDeleteRole}
                type="button"
                color="red"
                style="text-only"
                preIcon="close"
                size="sm"
              >
                <span className="text-gray-700 underline">Delete User Role</span>
              </Common.Button>
            )}
            <Common.Button dataTestId="save_btn" color="green" style="pill" size="sm">
              Save
            </Common.Button>
          </div>
        </div>
        <h3 ref={roleRef} className="my-4 font-bold text-primary">
          {tabLinks[0]}
        </h3>
        <RoleInformation control={control} formState={formState} />

        {/* Page permissions */}
        <h3 ref={pageRef} className="my-4 font-bold text-primary">
          {tabLinks[1]}
        </h3>
        <PermissionList
          permissions={permissions?.pagePermissions}
          pagePermissionItemsInCol={permissions?.pagePermissionItemsInCol}
          postPermissions={postPagePermissions}
          setPermissions={setPostPagePermissions}
          dataTestId="page_permissions_section"
        />

        {/* Editing permissions */}
        <h3 ref={editingRef} className="my-4 font-bold text-primary">
          {tabLinks[2]}
        </h3>
        <PermissionList
          permissions={permissions?.editPermissions}
          pagePermissionItemsInCol={permissions?.editPermissionItemsInCol}
          postPermissions={postEditPermissions}
          setPermissions={setPostEditPermissions}
          dataTestId="editing_permissions_section"
        />
      </form>
    </>
  );
};

export default ManageRoleForm;
