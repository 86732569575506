import {
  InternalNote,
  InternalNoteTemplate,
  InternalNoteTemplateBody,
  InternalNoteTemplatesQueryParams
} from 'store/internalNoteTemplates/internalNoteTemplates.types';
import { SPEC_CHARS_TO_ESCAPE_REGEXP } from 'utils/regExp';

import { apiSlice } from '../api/apiSlice';

const internalNoteTemplatesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getInternalNoteTemplates: build.query<
      InternalNote,
      { params: InternalNoteTemplatesQueryParams }
    >({
      query: ({ params }) => ({
        url: `/internal-note-templates`,
        params: {
          ...params,
          searchKey: params?.searchKey?.match(SPEC_CHARS_TO_ESCAPE_REGEXP)
            ? params.searchKey.replace(SPEC_CHARS_TO_ESCAPE_REGEXP, '\\$&')
            : params.searchKey
        }
      }),
      transformResponse: (response: {
        data: InternalNoteTemplate[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'InternalNoteTemplate',
              ...result.data.map(({ _id }) => ({ type: 'InternalNoteTemplate' as const, id: _id }))
            ]
          : ['InternalNoteTemplate'],
      keepUnusedDataFor: 60 * 3600 // 1 hour
    }),

    getInternalNoteTemplateById: build.query<InternalNoteTemplate, { id: string }>({
      query: ({ id }) => `/internal-note-templates/${id}`,
      transformResponse: (response: { data: InternalNoteTemplate }) => response.data
    }),

    createInternalNoteTemplates: build.mutation<
      { data: InternalNoteTemplate; message: string },
      { body: InternalNoteTemplateBody }
    >({
      query: ({ body }) => ({
        url: `/internal-note-templates`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: InternalNoteTemplate; message: string }) => response,
      invalidatesTags: ['InternalNoteTemplate']
    }),

    updateInternalNoteTemplates: build.mutation<
      { data: InternalNoteTemplate; message: string },
      { body: InternalNoteTemplateBody; id: string }
    >({
      query: ({ id, body }) => ({
        url: `/internal-note-templates/${id}`,
        method: 'PUT',
        body: body
      }),
      transformResponse: (response: { data: InternalNoteTemplate; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'InternalNoteTemplate', id: arg.id }]
    }),

    deleteInternalNoteTemplates: build.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({
        url: `/internal-note-templates/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['InternalNoteTemplate']
    })
  })
});

export const {
  useGetInternalNoteTemplatesQuery,
  useLazyGetInternalNoteTemplatesQuery,
  useLazyGetInternalNoteTemplateByIdQuery,
  useCreateInternalNoteTemplatesMutation,
  useUpdateInternalNoteTemplatesMutation,
  useDeleteInternalNoteTemplatesMutation
} = internalNoteTemplatesApi;
