import {
  StaffAnnouncementProps,
  StaffAnnouncementsQueryParams
} from 'store/staffAnnouncements/staffAnnouncements.types';

import { apiSlice } from '../api/apiSlice';

export const staffAnnouncementsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAnnouncements: build.query<
      { staffAnnouncements: StaffAnnouncementProps[]; totalCount: number },
      { params: StaffAnnouncementsQueryParams }
    >({
      query: ({ params }) => ({ url: '/announcement', params: params }),
      transformResponse: (response: {
        data: StaffAnnouncementProps[];
        info: { totalCount: number };
      }) => ({
        staffAnnouncements: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'Announcement',
              ...result.staffAnnouncements.map(({ _id }) => ({
                type: 'Announcement' as const,
                id: _id
              }))
            ]
          : ['Announcement']
    }),

    getStaffViewAnnouncements: build.query<
      { staffAnnouncements: StaffAnnouncementProps[]; totalCount: number },
      { params: StaffAnnouncementsQueryParams }
    >({
      query: ({ params }) => ({ url: '/announcement-staff-view', params: params }),
      transformResponse: (response: {
        data: StaffAnnouncementProps[];
        info: { totalCount: number };
      }) => ({
        staffAnnouncements: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'Announcement',
              ...result.staffAnnouncements.map(({ _id }) => ({
                type: 'Announcement' as const,
                id: _id
              }))
            ]
          : ['Announcement']
    }),

    getAnnouncementById: build.query<StaffAnnouncementProps, { id: string }>({
      query: ({ id }) => ({
        url: `/announcement/${id}`
      }),
      transformResponse: (response: { data: StaffAnnouncementProps }) => response.data,
      providesTags: (result, _error, { id }) => {
        return result ? [{ type: 'Announcement' as const, id }] : ['Announcement'];
      }
    }),

    createAnnouncement: build.mutation({
      query: ({ body }) => ({
        url: `announcement`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: StaffAnnouncementProps; message: string }) => response,
      invalidatesTags: ['Announcement']
    }),

    editAnnouncement: build.mutation({
      query: ({ id, body }) => ({
        url: `announcement/${id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: { data: StaffAnnouncementProps; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Announcement', id: arg.id }]
    }),

    updateInteraction: build.mutation({
      query: ({ id, body }) => ({
        url: `announcement/${id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: { data: StaffAnnouncementProps; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Announcement', id: arg.id }]
    }),

    updateInteractionView: build.mutation({
      query: ({ id, body }) => ({
        url: `announcement/update-interations/view/${id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: { data: StaffAnnouncementProps; message: string }) => response
    }),

    updateInteractionAcknowledge: build.mutation({
      query: ({ id, body }) => ({
        url: `announcement/update-interations/acknowledge/${id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: { data: StaffAnnouncementProps; message: string }) => response
    }),

    deleteAnnouncement: build.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({ url: `/announcement/${id}`, method: 'DELETE' }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['Announcement']
    })
  })
});

export const {
  useLazyGetStaffViewAnnouncementsQuery,
  useLazyGetAnnouncementsQuery,
  useGetAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useGetAnnouncementByIdQuery,
  useEditAnnouncementMutation,
  useUpdateInteractionMutation,
  useUpdateInteractionViewMutation,
  useUpdateInteractionAcknowledgeMutation,
  useDeleteAnnouncementMutation
} = staffAnnouncementsApi;
