import { ORDER_STATUS_OPTIONS, PHARMACY_TYPE_OPTIONS } from 'constants/order';

import { useState } from 'react';

import classNames from 'classnames';
import DatePickerInput from 'components/common/DatePickerInput';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import dayjs from 'dayjs';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { OrdersFilterFormProps } from './ordersFilterForm.types';
import { defaultValues } from './ordersFiltersForm.settings';

const OrdersFiltersForm = () => {
  const { control, handleSubmit, reset, setValue, watch, getValues } =
    useForm<OrdersFilterFormProps>({
      reValidateMode: 'onChange'
    });
  const [formData, setFormData] = useTableFiltersData('orders', defaultValues, reset);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<Date>();

  const leftSideClasses = classNames('flex flex-col gap-4 pr-7 border-r border-gray-20');
  const rightSideClasses = classNames('flex flex-col gap-4 pl-7');

  const onSubmit = (data: OrdersFilterFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...(data?.patient && { patient: encodeURIComponent(data.patient) }),
      ...(data?.orderId && { orderId: encodeURIComponent(data.orderId) }),
      ...(data?.createdAt && { createdAt: data.createdAt }),
      ...(data?.pharmacyType && { pharmacyType: data?.pharmacyType?.value }),
      ...(data?.orderStatus && { orderStatus: data?.orderStatus?.value })
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2">
          <div className={leftSideClasses}>
            <InputField
              name="patient"
              label="Patient"
              type="text"
              placeholder="Enter patient"
              control={control}
              value={formData?.patient}
            />
            <InputField
              name="orderId"
              label="Order Id"
              type="text"
              placeholder="Enter Id"
              control={control}
              value={formData?.orderId}
            />
            <DatePickerInput
              placeholder="Select date"
              label="Creation date"
              size="sm"
              labelDirection={'col'}
              inputValue={getValues('createdAt')}
              setInputValue={(value) => setValue('createdAt', value)}
              selectedDate={selected}
              setSelectedDate={setSelected}
              startAllowedDate={dayjs().subtract(100, 'year').toDate()}
              lastAllowedDate={new Date()}
              captionLayout={'dropdown'}
              wrapperClasses="w-full relative mr-14"
              isRtL
            />
          </div>
          <div className={rightSideClasses}>
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={PHARMACY_TYPE_OPTIONS}
              placeholder="Select type"
              label="Pharmacy type"
              name="pharmacyType"
              defaultValue={formData?.pharmacyType}
            />

            <ControlledSelect
              control={control}
              labelDirection="col"
              options={ORDER_STATUS_OPTIONS}
              placeholder="Select status"
              label="Status"
              name="orderStatus"
              defaultValue={formData?.orderStatus}
            />
          </div>
        </div>
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default OrdersFiltersForm;
