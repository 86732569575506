import { SortingFieldsLabelsProps } from './sortingBanner.types';

const alphabeticalSortingOptions = {
  ASC: { label: 'A to Z', tooltipList: ['Numbers 0-9', 'Letters A-Z', '(Empty field)'] },
  DESC: { label: 'Z to A', tooltipList: ['Letters Z-A', 'Numbers 9-0', '(Empty field)'] }
};

const dateSortingOptions = {
  ASC: { label: 'New to old', tooltipList: ['Newest to oldest', '(Empty field)'] },
  DESC: { label: 'Old to new', tooltipList: ['Oldest to newest', '(Empty field)'] }
};

const bulkEditIdsSortingOptions = {
  ASC: { label: 'Checked to Unchecked', tooltipList: [] },
  DESC: { label: 'Unchecked to Checked', tooltipList: [] }
};

export const sortingFieldsLabels: SortingFieldsLabelsProps = {
  ['patientInfo.name']: {
    label: 'Patient',
    options: alphabeticalSortingOptions
  },
  dueDate: {
    label: 'Date',
    options: dateSortingOptions
  },
  category: {
    label: 'Category',
    options: alphabeticalSortingOptions
  },
  details: {
    label: 'Details',
    options: alphabeticalSortingOptions
  },
  ['doctorInfo.name']: {
    label: 'Provider',
    options: alphabeticalSortingOptions
  },
  ['assignedToInfo.name']: {
    label: 'Assigned to',
    options: alphabeticalSortingOptions
  },
  assignedAt: {
    label: 'Assigned at',
    options: dateSortingOptions
  },
  status: {
    label: 'Status',
    options: alphabeticalSortingOptions
  },
  bulkEditIds: {
    label: 'Selected',
    options: bulkEditIdsSortingOptions
  }
};
