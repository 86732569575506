import { GroupRequestOrderOptionsProps, TaskRequestOrderReasonsType } from 'store/tasks/task.types';

export const getGroupedReasons = (
  reasonsList: TaskRequestOrderReasonsType[]
): GroupRequestOrderOptionsProps[] => {
  [...reasonsList].sort((a, b) => a.displayOrder - b.displayOrder);

  return reasonsList.map((reason) => ({
    label: reason.categoryName,
    options: reason.reasons.map((reasonItem) => {
      return {
        label: reasonItem.name,
        value: reasonItem.key,
        groupLabel: reasonItem.categoryName,
        toEscalate: reason.toEscalate
      };
    })
  }));
};
