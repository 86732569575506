import { createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { PatientsQueryParams } from 'models/patients.types';
import { baseQueryWithReauth } from 'utils/planex';

import { OrderProps, ReleaseOrderFromHoldQueryParams } from './orders.types';

const initialState: OrderProps = {
  id: '',
  email: '',
  status: '',
  orderStatus: '',
  maxioLink: '',
  createdAt: '',
  type: '',
  insurance: {
    memberId: '',
    rxGroup: '',
    rxBin: '',
    pcn: '',
    relationship: ''
  },
  _id: '',
  patient: '',
  prescriptions: [],
  metadata: {
    createdBy: '',
    createdAt: new Date(),
    version: 0
  }
};

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderDetails: initialState
  },
  reducers: {
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    resetOrderDetails: (state) => {
      state.orderDetails = initialState;
    }
  }
});

export const { setOrderDetails, resetOrderDetails } = orderSlice.actions;

export default orderSlice.reducer;

export const ordersApi = createApi({
  reducerPath: 'planex',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Order'],
  endpoints: (builder) => ({
    getOrders: builder.query<
      { orders: OrderProps[]; totalCount: number },
      { params: PatientsQueryParams }
    >({
      query: ({ params }) => ({
        url: '/v2/orders',
        params
      }),
      transformResponse: (response: { data: OrderProps[]; info: { totalCount: number } }) => ({
        orders: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? ['Order', ...result.orders.map(({ _id }) => ({ type: 'Order' as const, id: _id }))]
          : ['Order']
    }),
    getOrder: builder.query<OrderProps, { id: string }>({
      query: ({ id }) => `/orders/${id}`,
      transformResponse: (response: { data: OrderProps }) => response.data,
      providesTags: (_result, _error, arg) => [{ type: 'Order', id: arg.id }]
    }),
    updateOrder: builder.mutation({
      query: ({ orderId, order }: { orderId: string; order: unknown }) => ({
        url: `/orders/${orderId}`,
        method: 'PUT',
        body: order
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Order', id: arg.orderId }]
    }),
    releaseOrderFromHold: builder.mutation<{ message: string }, ReleaseOrderFromHoldQueryParams>({
      query: ({ orderId, shouldExpedite, reason }) => ({
        url: `/v2/orders/${orderId}/release`,
        method: 'POST',
        body: { shouldExpedite, reason }
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Order', id: arg.orderId }]
    })
  })
});

export const {
  useGetOrdersQuery,
  useLazyGetOrderQuery,
  useUpdateOrderMutation,
  useReleaseOrderFromHoldMutation
} = ordersApi;
