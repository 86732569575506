import React, { ChangeEvent, cloneElement, FocusEventHandler, useEffect, useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { getNewValue, getNewValueOnBlur } from './customTimeInput.settings';
import { CustomTimeInputProps } from './customTimeInput.types';

type ClonedInputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  [key: string]: unknown;
};

const CustomTimeInput = ({
  initialTime,
  input,
  onChange,
  className,
  onBlur,
  field,
  ...props
}: CustomTimeInputProps) => {
  const [value, setValue] = useState(initialTime);

  const inputClassName = classNames(
    'mr-0.5 w-1/2 border-none p-0 text-sm text-gray-500 focus:ring-0 focus:ring-offset-0',
    className
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const updatedValue = getNewValueOnBlur(value);
    setValue(updatedValue);
    if (onChange) {
      onChange(event);
    }
    // to update the value in the form
    if (field) {
      field.onChange(updatedValue);
    }
    onBlur?.(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = getNewValue(value, event.target.value);
    if (newValue) {
      setValue(newValue);
      if (onChange) {
        onChange(event);
      }
    }
  };

  useEffect(() => {
    if (
      initialTime &&
      initialTime &&
      initialTime !== dayjs(value, DateFormat.hh_mm).format(DateFormat.hh_mm)
    ) {
      setValue(initialTime);
    }
    // should depend on initialTime only
  }, [initialTime]);

  if (input && React.isValidElement<ClonedInputProps>(input)) {
    return cloneElement(input, {
      ...props,
      onChange: handleChange,
      value
    });
  }

  return (
    <input
      {...props}
      onBlur={handleBlur}
      onChange={handleChange}
      type="text"
      value={value}
      className={inputClassName}
    />
  );
};

export default CustomTimeInput;
