import React, { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { useNavigate } from 'react-router';
import { useGetUsersQuery } from 'store/lookup/lookupSlice';

const PhysicianSelect: React.FC = () => {
  const navigate = useNavigate();

  const { data: doctors } = useGetUsersQuery(
    { userType: 'Physician' },
    {
      selectFromResult: (result) => {
        if (result.isSuccess && result?.data) {
          return {
            data: result.data.map((item) => ({
              label: item.name || item.displayName,
              value: item._id,
              id: item._id
            }))
          };
        }
        return { data: [] };
      }
    }
  );

  const [inputValue, setInputValue] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');

  return (
    <div data-testid="physician_select_block" className="flex items-center gap-2">
      <div className="whitespace-nowrap text-sm font-semibold text-gray-700">
        Edit availability for
      </div>

      <div className="z-[15] ml-3.5 w-56">
        <Common.AutocompleteInputSelect
          placeholder="Find a provider…"
          labelDirection="row"
          options={doctors as Option[]}
          size="sm"
          name="doctors"
          onInputChange={(value) => {
            setInputValue(value.target.value);
          }}
          inputValue={inputValue}
          onChange={(value) => setSelectedDoctor(value?.id)}
          customLabelClassName="hidden"
          {...(selectedDoctor && { postIcon: 'check-circle', isSuccess: true })}
        />
      </div>
      <Common.Button
        size="sm"
        className="rounded-lg border border-gray-200 text-sm font-bold text-gray-700"
        onClick={() => navigate(`/dashboard/appointments/availability/${selectedDoctor}`)}
        disabled={!selectedDoctor}
      >
        Submit
      </Common.Button>
    </div>
  );
};

export default PhysicianSelect;
