import DateRangePickerInput from 'components/common/DateRangePickerInput';
import SearchCombobox from 'components/common/form/SearchCombobox';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import Footer from 'components/modals/Footer';
import dayjs from 'dayjs';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';
import { AT_LEAST_ONE_SPEC_CHAR_REGEXP } from 'utils/regExp';

import { defaultValues, RATING_FILTER, REVIEW_STATUS_FILTER } from './reviewsFiltersForm.settings';
import { ReviewsFiltersProps } from './reviewsFiltersForm.types';

const ReviewsFiltersForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, reset, watch, setValue, getValues } =
    useForm<ReviewsFiltersProps>();
  const [formData, setFormData] = useTableFiltersData('reviews', defaultValues, reset);
  const { timezone } = useAppSelector(selectUser);
  const location = useLocation();

  const onSubmit = (data: ReviewsFiltersProps) => {
    const parsedUrl = queryString.parse(location.search);
    setFormData({ ...formData, ...data });

    const createdDateParams = {
      createdAt:
        data?.startCreateDate && !data?.endCreateDate
          ? data?.startCreateDate
          : data?.createdAt && !data?.endCreateDate
            ? data?.createdAt
            : '',
      startCreateDate: data?.startCreateDate && data?.endCreateDate ? data?.startCreateDate : '',
      endCreateDate: data?.endCreateDate
    };

    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...createdDateParams,
      providerName: data?.providerName?.value.replace(AT_LEAST_ONE_SPEC_CHAR_REGEXP, '').trim(),
      patientName: data?.patientName?.value.replace(AT_LEAST_ONE_SPEC_CHAR_REGEXP, '').trim(),
      status: data?.status.value,
      rating: data?.rating.value,
      localTz: timezone ?? dayjs.tz.guess()
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  const createdAtFromValue = getValues('startCreateDate')
    ? getValues('startCreateDate')
    : getValues('createdAt')
      ? getValues('createdAt')
      : '';

  return (
    <form className="w-[312px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={REVIEW_STATUS_FILTER}
          placeholder="Select status"
          label="Status"
          name="status"
          defaultValue={formData?.status}
        />
        <SearchCombobox
          size="sm"
          control={control}
          setValue={setValue}
          name="patientName"
          label="Name"
          userType="Patient"
          placeholder="Enter patient"
          minLengthInput={1}
          labelDirection="col"
          defaultValue={formData?.patientName}
        />
        <SearchCombobox
          size="sm"
          control={control}
          setValue={setValue}
          name="providerName"
          label="Physician"
          userType="Physician"
          placeholder="Enter provider"
          minLengthInput={1}
          labelDirection="col"
          defaultValue={formData?.providerName}
        />
        <DateRangePickerInput
          labelDirection="col"
          label="Created date"
          placeholder={'Select a date range'}
          fromValue={createdAtFromValue}
          setFromValue={(value) => setValue('startCreateDate', value.toString())}
          toValue={getValues('endCreateDate') ? getValues('endCreateDate') : ''}
          setToValue={(value) => setValue('endCreateDate', value.toString())}
          startAllowedDate={dayjs().subtract(100, 'year').toDate()}
          lastAllowedDate={new Date()}
          size="sm"
          wrapperClasses="w-full relative"
        />
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={RATING_FILTER}
          placeholder="Select rating"
          label="Rating"
          name="rating"
          defaultValue={formData?.rating}
        />
      </div>
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default ReviewsFiltersForm;
