import { useMemo } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useAppliedFilters } from 'hooks/filters/useAppliedFilters';

import { normalizeAppliedFilters, presentFilter } from './appliedFiltersTags.settings';
import type { AppliedFiltersTagsType } from './appliedFiltersTags.type';

const AppliedFiltersTags = ({
  tagsOptions,
  teamsOptions,
  taskTagsOptions,
  planOptions
}: AppliedFiltersTagsType) => {
  const { appliedFilters, removeFilter } = useAppliedFilters();
  const filterTags = useMemo(
    () =>
      normalizeAppliedFilters({
        appliedFilters,
        tagsOptions,
        teamsOptions,
        taskTagsOptions,
        planOptions
      }),
    [appliedFilters, tagsOptions, teamsOptions, taskTagsOptions, planOptions]
  );

  const handleRemoveFilter = (filterLabel: string) => {
    switch (filterLabel) {
      case 'createdDate':
        removeFilter('startCreateDate');
        removeFilter('endCreateDate');
        break;
      case 'dueDate':
        removeFilter('endDueDate');
        removeFilter('startDueDate');
        break;
      default:
        removeFilter(filterLabel);
    }
  };

  return (
    <div className="flex flex-wrap">
      {filterTags?.map((filter) => (
        <Common.Button
          key={filter.label}
          color="white-alt"
          size="sm"
          preIcon="close"
          className="mb-4 mr-2 text-gray-500"
          onClick={() => handleRemoveFilter(filter.label)}
          preIconClassName="shrink-0"
          type="button"
        >
          <span
            data-testid="applied_filters_tags"
            className="whitespace-normal text-left font-semibold"
          >
            {presentFilter(filter)}
          </span>
        </Common.Button>
      ))}
    </div>
  );
};

export default AppliedFiltersTags;
