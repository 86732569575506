import StuckOrderStatus from 'components/tasks/slidingPane/StuckOrderStatus';
import dayjs from 'dayjs';
import { OrderWarning, TaskPatientInfo } from 'models/tasks.types';
import { getDisplayName } from 'store/patients/patients.settings';

type PatientNameCellProps = {
  patientInfo: TaskPatientInfo;
  orderWarning?: OrderWarning;
};

const PatientNameCell: React.FC<PatientNameCellProps> = ({ patientInfo, orderWarning }) => {
  return (
    <>
      <b>{getDisplayName(patientInfo, true)}</b>{' '}
      <span className="self-end pl-1">
        {patientInfo.dob ? ` ${dayjs().diff(patientInfo?.dob, 'year')}` : null}
      </span>
      <span className="self-end pl-1">
        <span>•</span>
        <span className="pl-1">{patientInfo?.stateShortCode}</span>
      </span>
      {!!Object.values(orderWarning || {})?.length && (
        <StuckOrderStatus
          stuckOrder={orderWarning}
          tooltipWrapperClasses="bottom-[200%] -ml-[121px]"
          iconWrapperClasses="pl-1"
        />
      )}
    </>
  );
};

export default PatientNameCell;
