import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { TimeConflictProps } from './timeConflict.types';

const TimeConflict: React.FC<TimeConflictProps> = ({
  appointmentStartTime,
  setShowModal,
  onTakePatient,
  providerName,
  assignProviderType,
  isLoading
}) => {
  const assignText = assignProviderType
    ? assignProviderType === 'Assign'
      ? 'assign the next queue patient to them'
      : 'reassign the next queue patient to them'
    : 'take the next queue patient';
  const btnText = assignProviderType
    ? assignProviderType === 'Assign'
      ? 'Yes, assign patient'
      : 'Yes, reassign patient'
    : 'Yes, take patient';

  return (
    <div data-testid="time_conflict_popup" className="fixed left-0 top-0 z-30 size-full">
      <div className="sticky inset-0 z-30 flex h-screen min-h-screen w-full items-center justify-center bg-gray-500/50">
        <div className="max-w-sm rounded-lg bg-white p-5">
          <h3 className="mb-2 text-xl font-bold leading-6">Time conflict</h3>
          <p className="my-6 text-base">
            {providerName ? `${providerName} has` : 'You have'} an appointment at{' '}
            <span className="font-bold">
              {dayjs(appointmentStartTime).tz(dayjs.tz.guess()).format(DateFormat.h_mma_z)}
            </span>
            . Are you sure you'd like to {assignText}?
          </p>
          <div className="flex w-full justify-between gap-2">
            <Common.Button
              onClick={() => setShowModal(false)}
              color="white-alt"
              size="sm"
              className="w-1/2 grow justify-center"
            >
              Cancel
            </Common.Button>
            <Common.Button
              onClick={onTakePatient}
              color="red"
              size="sm"
              disabled={isLoading}
              className="w-1/2 grow justify-center whitespace-nowrap"
            >
              {btnText}
            </Common.Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeConflict;
