import dayjs from 'dayjs';
import { isImageByFilename } from 'utils/files';

import { StaffNoteProps } from './staffNotes.types';

const normalizeStaffNote = (staffNote: StaffNoteProps, isGrouped?: boolean) => {
  return {
    id: staffNote._id,
    date: new Date(staffNote.createdAt).getTime(),
    patientId: staffNote.patientId,
    author: {
      displayName: staffNote.author.displayName,
      userType: staffNote.author.userType
    },
    userId: staffNote.author._id,
    message: staffNote.note,
    history: staffNote.history,
    deletedBy: staffNote.deletedBy,
    deletedDate: staffNote.deletedDate,
    profileImage: staffNote.author.profileImage,
    fileName: staffNote.fileName,
    filePath: staffNote.filePath,
    ...(staffNote.event && { event: staffNote.event }),
    ...(staffNote.system && { system: staffNote.system }),
    isGrouped
  };
};
export const formatStaffNotes = (notes: StaffNoteProps[]) => {
  return notes.map((note, index) => {
    const nextNote = notes[index + 1];
    let isGrouped;
    if (nextNote) {
      const diff = dayjs(nextNote.createdAt).diff(note.createdAt, 'minutes');
      // NOTE: we should differentiate between system notes and user notes
      isGrouped =
        nextNote.author._id === note.author._id && nextNote.system === note.system && diff < 5; // 5 min
    }
    return normalizeStaffNote(note, isGrouped);
  });
};

export const getStaffNotesImages = (notes: StaffNoteProps[]) => {
  const images: string[] = [];
  notes.forEach((note) => {
    if (note.fileName && note.filePath && isImageByFilename(note.fileName)) {
      images.unshift(note.filePath);
    }
  });
  return images;
};
