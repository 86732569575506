import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import {
  EditEventOptions,
  EventType,
  EventTypes,
  RepeatOptions,
  TimeZoneOptions
} from 'models/event.types';
import { RRule } from 'rrule';
import { EventDetailsProps } from 'store/calendar/calendar.types';
import { processCategories } from 'utils/calendar';

import type { GetEditEventOptionsProps, UpdateEventProps } from './editEvent.types';
import {
  convertEventTimeToDate,
  getDateTime,
  getDaysOfWeek,
  getRrule
} from '../manageAvailability.settings';
import { FormValues } from '../manageAvailability.types';

const getEditEventOptions = ({
  isAdmin,
  isEventRepeatsChanged,
  isEventDateChanged,
  isRecurringEventChanged,
  type
}: GetEditEventOptionsProps) => {
  const showCurrentOption = !isEventRepeatsChanged && !isRecurringEventChanged;
  const showAllOption = isAdmin && !isEventDateChanged;

  const result: { [key: string]: string } = {};

  if (showCurrentOption) {
    result[EditEventOptions.CURRENT] = type === 'time-off' ? 'This time off' : 'This shift';
  }

  result[EditEventOptions.CURRENT_AND_FOLLOWING] =
    type === 'time-off' ? 'This and following instances of time off' : 'This and following shifts';

  if (showAllOption) {
    result[EditEventOptions.ALL] =
      type === 'time-off'
        ? 'This and all time-offs before and after it'
        : 'This and all shifts before and after it';
  }

  return result;
};

const updateEvent = ({
  type,
  updateData,
  originalEvent,
  calendarTimezone,
  eventTime,
  userId,
  selectedEditOption,
  providerTimezone
}: UpdateEventProps) => {
  const updatedEvent: Partial<EventDetailsProps> = {};

  const eventType: EventType =
    type === 'shift'
      ? 'shift'
      : updateData.eventTypes[0].value === EventTypes.BREAK
        ? EventTypes.BREAK
        : EventTypes.TIME_OFF;

  const isDayOff = eventType === EventTypes.TIME_OFF;
  const isUntil = updateData.ends?.option === 'on' && updateData.ends.date;

  const shiftTypes = updateData.eventTypes.map((item) => ({
    _id: item.value,
    filters: updateData.categoryFilters?.length
      ? processCategories(item.label, updateData.categoryFilters)
      : [],
    exclusions: updateData.categoryExclusions?.length
      ? processCategories(item.label, updateData.categoryExclusions)
      : []
  }));

  if (eventTime.start && eventTime.end && updateData.shiftStart?.day) {
    const shiftStartDay =
      selectedEditOption !== EditEventOptions.ALL
        ? updateData.shiftStart.day
        : dayjs(originalEvent.start.dateTime).toDate();
    const dayOffTimeZone = providerTimezone ? providerTimezone : calendarTimezone;

    updatedEvent.start = {
      dateTime: isDayOff
        ? dayjs(shiftStartDay).startOf('D').format(DateFormat.YYYY_MM_DD_HH_mm_ss)
        : getDateTime(shiftStartDay, eventTime.start, DateFormat.YYYY_MM_DD_HH_mm_ss),
      timeZone: isDayOff ? dayOffTimeZone : calendarTimezone
    };

    updatedEvent.end = {
      dateTime: isDayOff
        ? dayjs(shiftStartDay).add(1, 'day').startOf('D').format(DateFormat.YYYY_MM_DD_HH_mm_ss)
        : getDateTime(shiftStartDay, eventTime.end, DateFormat.YYYY_MM_DD_HH_mm_ss),
      timeZone: isDayOff ? dayOffTimeZone : calendarTimezone
    };

    updatedEvent.type = eventType;

    if (selectedEditOption === EditEventOptions.CURRENT_AND_FOLLOWING) {
      updatedEvent.participants = [{ userId }];
    }

    if (eventType === EventTypes.SHIFT) {
      updatedEvent.shiftTypes = shiftTypes;
    }

    if (selectedEditOption !== EditEventOptions.CURRENT) {
      const endTime = updateData?.shiftTime?.[0]?.end;
      const endHours = dayjs(endTime, DateFormat.HH_mm).get('hour');
      const endMinutes = dayjs(endTime, DateFormat.HH_mm).get('minute');
      const until = dayjs(updateData.ends?.date)
        .set('hour', endHours)
        .set('minute', endMinutes + 1)
        .format(DateFormat.YYYY_MM_DDTHH_mm_ss_sss);

      updatedEvent.recurring = {
        type:
          updateData.repeats === RepeatOptions.BI_WEEKLY
            ? RepeatOptions.WEEKLY
            : updateData.repeats,
        daysOfWeek: getDaysOfWeek(updateData.repeats, shiftStartDay),
        interval: updateData.repeats === RepeatOptions.BI_WEEKLY ? 2 : 1,
        ...(isUntil && { until }),
        ...(updateData.ends?.count && { count: updateData.ends?.count })
      };
    }
  }

  return updatedEvent;
};

const getUpdatedEventTime = (
  eventDateTime: string,
  timezone: string,
  updatedShiftTime: { start: string; end: string }[]
): string[] => {
  const timeShifts = updatedShiftTime.map((shift) => {
    const startDate = dayjs(eventDateTime).tz(timezone);
    const endDate = dayjs(eventDateTime).tz(timezone);

    const [startHours, startMinutes] = shift.start.split(':');
    const [endHours, endMinutes] = shift.end.split(':');

    const start = startDate
      .set('hour', parseInt(startHours))
      .set('minute', parseInt(startMinutes))
      .format(DateFormat.MMM_DD__hh_mm_a);
    const end = endDate
      .set('hour', parseInt(endHours))
      .set('minute', parseInt(endMinutes))
      .format(DateFormat.hh_mm_a);

    return start + ' - ' + end;
  });

  return timeShifts;
};

const getLengthOfPreviousRecurringEvents = (
  eventData: FormValues,
  eventDetails: EventDetailsProps,
  calendarTimezone: string
) => {
  const day = convertEventTimeToDate(
    eventDetails.start.dateTime,
    TimeZoneOptions.UTC,
    calendarTimezone
  );
  const endTime = eventData.ends?.dayPickerMonth;
  // set the end time to the end of the current event
  endTime?.setHours(day.getHours(), day.getMinutes(), day.getSeconds(), day.getMilliseconds() - 1);
  const lengthOfPreviousRecurringEvents = eventData.ends?.dayPickerMonth
    ? new RRule({
        ...getRrule({
          repeats: eventData.repeats,
          day: convertEventTimeToDate(
            eventDetails.start.dateTime,
            TimeZoneOptions.UTC,
            calendarTimezone
          ),
          endTime
        })
        // do not include the current event
      }).all().length - 1
    : 0;
  return lengthOfPreviousRecurringEvents;
};

export {
  updateEvent,
  getEditEventOptions,
  getUpdatedEventTime,
  getLengthOfPreviousRecurringEvents
};
