import { Role, RoleShortName } from 'enums/role';

export const createLink = (inputLink: string) => {
  let link = inputLink;
  if (link.startsWith('/')) {
    return link;
  } else if (!link.startsWith('https://')) {
    link = 'https://' + link.replace(/^https?:\/\//, '');
  }
  return link;
};

export const AUDIENCE_OPTIONS = [
  { name: Role.AD, shortCode: RoleShortName.Admin },
  { name: Role.PH, shortCode: RoleShortName.Physician },
  { name: Role.MD, shortCode: RoleShortName.MasterPhysician },
  { name: Role.NP, shortCode: RoleShortName.NursePractitioner },
  { name: Role.MA, shortCode: RoleShortName.MedicalAssistant },
  { name: Role.CS, shortCode: RoleShortName.CustomerService }
];
