import { createColumnHelper } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PathName } from 'enums/pathName';
import { Link } from 'react-router';

import { RolesTableProps } from './rolesTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<RolesTableProps>();

export const columns = [
  columnHelper.accessor('name', {
    header: () => <Header text="Role" sortField="name" />
  }),
  columnHelper.accessor('userType.name', {
    header: () => <Header text="Role template" sortField="userType.name" />,
    size: 20
  }),
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date created" sortField="createdAt" />,
    cell: (info) => dayjs(info.getValue()).format(DateFormat.MMM_D__YYYY)
  }),
  columnHelper.accessor('color', {
    header: () => <Header text="Color" sortField="color" />
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
        {info.getValue()}
      </span>
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => (
      <div className="flex gap-1">
        <Link data-testid="pencil_icon" to={`${PathName.ManageRole}/${info.row.original._id}`}>
          <Common.Icon name="pencil" className="size-4 cursor-pointer hover:text-primary-500" />
        </Link>
      </div>
    ),
    enableSorting: false
  })
];
