import { useCallback, useEffect, useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { ColumnDef } from '@tanstack/react-table';
import { useFlag } from '@unleash/proxy-client-react';
import { notifyError } from 'components/common/Toast/Toast';
import AssignedToTask from 'components/modals/AssignedToTask';
import TimeConflict from 'components/modals/TimeConflict';
import dayjs from 'dayjs';
import { Role } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';
import useLocationParams from 'hooks/common/location/useLocationParams';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRefreshTasksList } from 'hooks/tasks/useRefreshTasksList/useRefreshTasksList';
import isEqual from 'lodash/isEqual';
import { TaskProps, TasksQueryParams } from 'models/tasks.types';
import { showQueueListPermission } from 'pages/Tasks/Tasks.settings';
import { useSearchParams } from 'react-router';
import { usePrevious } from 'react-use';
import socketStaff from 'socket/socketStaff';
import { useLazyGetAppointmentsQuery } from 'store/appointments/appointmentsSlice';
import { selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import { openModal } from 'store/modal/modalSlice';
import {
  resetQueueState,
  selectQueue,
  setOnGetQueueDetails,
  useLazyGetQueueInfoQuery,
  useLazyGetQueueListQuery
} from 'store/queue/queueSlice';
import { QueueTasksProps } from 'store/queue/queueSlice.types';
import { open } from 'store/slidingInPane/slidingInPaneSlice';
import { initialApptAsyncInfo } from 'store/tasks/task.types';
import {
  selectTasks,
  setApptAsyncInfo,
  setApptAsyncProviderEnabled,
  setAsyncProviderEnabled,
  setTasksSummary,
  useLazyGetTasksQuery
} from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { getISOStringIncludingTimezone } from 'utils/helpers';
import { parseQueryParams } from 'utils/router';

import { apptAsyncColumns, columns, queueColumns } from './columns';
import TasksTable from './TasksTable';
import { TasksTableColumnsProps } from './tasksTable.types';
import { getMultiSortFields } from './taskTable.settings';

const selectTasksTableState = createSelector(
  [selectUser, selectQueue, selectBulkEdit, selectTasks],
  (user, queue, bulkEdits, tasks) => ({
    queueScheduleDetails: queue.queueScheduleDetails,
    userType: user.userType,
    queueTask: queue.queueTask,
    userId: user._id,
    selectedTasks: bulkEdits.bulkEdits,
    apptAsyncInfo: tasks.apptAsyncInfo
  })
);

const ConnectedTasksTable: React.FC<{ patientId?: string }> = ({ patientId }) => {
  const dispatch = useAppDispatch();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const isQueueDisabled = useFlag('disable-queue');
  const isApptAsyncTaskDisabled = useFlag('disable-appt-async');

  const {
    queueTask,
    queueScheduleDetails,
    userType: { name: userRole },
    userId,
    selectedTasks,
    apptAsyncInfo
  } = useAppSelector(selectTasksTableState);

  const [getTasks, { data: tasks, isFetching }] = useLazyGetTasksQuery();
  const [abortTasksController, setAbortTasksController] = useState<{ abort: () => void } | null>(
    null
  );

  const [getQueueInfo, { data: queueInfo }] = useLazyGetQueueInfoQuery();
  const [getQueueList] = useLazyGetQueueListQuery();
  const [getAppointments, { isLoading, isFetching: isFetchingAppointments, data: appointments }] =
    useLazyGetAppointmentsQuery();

  const memoizedQueueTask = useMemo(() => {
    return [queueTask];
  }, [queueTask]);
  const memoizedQueueColumns = useMemo(() => queueColumns(userRole), [userRole]);

  const memoizedApptAsyncTask = useMemo(
    () => (apptAsyncInfo ? [apptAsyncInfo] : []),
    [apptAsyncInfo]
  );
  const memoizedApptAsyncColumns = useMemo(
    () => apptAsyncColumns(tasks?.summary?.apptAsyncCount || 0, isFetching),
    [tasks?.summary?.apptAsyncCount, isFetching]
  );

  const isLoadingCheckAvailable = isLoading || isFetchingAppointments;

  const { ['pageNo']: [pageNo = '0'] = [], ...locationParams } = useLocationParams();
  const [searchParams] = useSearchParams({ limit: '50', pageNo: '0' });
  const sortFields = getMultiSortFields(locationParams);

  const sortedByBulkType = searchParams.get('sort[bulkEditIds]') || '';

  const queryParams: TasksQueryParams = useMemo(() => {
    const parsedQueryParams = parseQueryParams(searchParams);
    // remove taskModalOpenId from request payload
    if (parsedQueryParams?.taskModalOpenID) delete parsedQueryParams?.taskModalOpenID;
    if (parsedQueryParams?.['sort[bulkEditIds]']) delete parsedQueryParams?.['sort[bulkEditIds]'];

    return parsedQueryParams;
  }, [searchParams]);

  const count = queryParams.limit ?? 50;
  const totalCount = tasks?.totalCount || 0;

  const showingFrom = +pageNo + 1 === 1 ? +pageNo + 1 : +pageNo * count + 1;
  const showingTo = (+pageNo + 1) * count > totalCount ? totalCount : (+pageNo + 1) * count;

  const buildParamsForRequest = useCallback(() => {
    const { sortField, sortOrder, ...restParams } = queryParams ?? {};

    const params = {
      ...restParams,
      ...(sortField && { [`sort[${sortField}]`]: sortOrder ?? 'ASC' }),
      ...(patientId && { patientId, enableSnoozedTasks: true, showAllPatientTasks: true })
    };

    return { params };
  }, [patientId, queryParams]);

  const prevQueryParams = usePrevious(queryParams);

  const wasParamsChanged = useMemo(
    () => !isEqual(queryParams, prevQueryParams),
    [queryParams, prevQueryParams]
  );

  const refreshTasksList = useCallback(() => {
    getTasks(buildParamsForRequest());
  }, [buildParamsForRequest, getTasks]);

  const {
    showRefreshTasksButton,
    newTasksCount,
    handleOnClick: handleRefreshTasks,
    dismissRefreshCounts
  } = useRefreshTasksList({ refreshTasksList });

  const havePermissionToQueue =
    userRole === Role.MA || userRole === Role.PH || userRole === Role.NP || userRole === Role.AD;
  const hasPhAbilityToQueue = queueScheduleDetails.providersQueueData?.find(
    (provider) => provider.userId === userId
  );
  const showQueueForPH =
    (userRole === Role.PH || userRole === Role.NP) &&
    queueInfo &&
    !queueInfo.data.taskInProgress &&
    hasPhAbilityToQueue;
  const showQueueList = showQueueListPermission(userRole);
  const showQueue = Boolean(showQueueList || showQueueForPH) && !isQueueDisabled;

  const isHistoryTab = searchParams.get('category') === TaskCategories.History;

  // Show apptAsync table to ALL users except PH without apptAsyncProviderEnabled on ALL categories tab and on ApptAsync tab,
  // but not on Sent Requests tab and History tab
  const showApptAsync =
    !isApptAsyncTaskDisabled &&
    !(userRole === Role.PH && !tasks?.apptAsyncProviderEnabled) &&
    (!searchParams.has('category') ||
      searchParams.getAll('category').includes(TaskCategories.ApptAsync)) &&
    !(searchParams.get('isSentRequests') === 'true') &&
    !isHistoryTab;

  const sortedTaskByBulkEdit = useMemo(() => {
    const tasksWithoutApptAsync =
      isHistoryTab || !showApptAsync
        ? tasks?.tasks
        : tasks?.tasks?.filter(
            // we have separate table for apptAsync tasks
            (task) => task.category !== TaskCategories.ApptAsync
          );

    const tasksList = tasksWithoutApptAsync ?? [];

    if (
      Array.isArray(tasksList) &&
      tasksList.length > 0 &&
      sortedByBulkType &&
      selectedTasks.length
    ) {
      return tasksList.slice().sort((a, b) => {
        const isASelected = selectedTasks.includes(a._id);
        const isBSelected = selectedTasks.includes(b._id);

        if (isASelected && !isBSelected) return sortedByBulkType === 'ASC' ? -1 : 1;
        if (!isASelected && isBSelected) return sortedByBulkType === 'ASC' ? 1 : -1;
        return 0;
      });
    }

    return tasksList;
  }, [sortedByBulkType, tasks?.tasks, selectedTasks, isHistoryTab]);

  const [regularTasks, regularTasksColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching,
    data: sortedTaskByBulkEdit,
    columns
  });

  const handleTakePatient = useCallback(() => {
    dispatch(open({ isQueue: true }));
    if (showCancelModal) setShowCancelModal(false);
  }, [dispatch, showCancelModal]);

  const handleOpenTask = useCallback(
    (task: TaskProps | QueueTasksProps) => {
      if (task._id) {
        const updatedTask = task as TaskProps;

        dispatch(
          openModal({
            size: 'sm',
            hideClose: true,
            modalContent: (
              <AssignedToTask
                taskId={updatedTask._id}
                assignToName={updatedTask?.assignedToInfo?.name}
              />
            )
          })
        );
        return;
      }
      if (!showQueueList && queueInfo?.data.patientsCount) {
        getAppointments(
          {
            startTime: getISOStringIncludingTimezone(dayjs(), dayjs.tz.guess()),
            endTime: getISOStringIncludingTimezone(dayjs().add(20, 'minute'), dayjs.tz.guess()),
            appointmentStatus: 'pending',
            limit: 1
          },
          true
        )
          .unwrap()
          .then((data) => {
            if (data && !data.length) {
              handleTakePatient();
            } else {
              setShowCancelModal(true);
            }
          })
          .catch((error) => notifyError(error.data?.message || error.message));
      }
    },
    [showQueueList, queueInfo?.data.patientsCount, dispatch, getAppointments, handleTakePatient]
  );

  useEffect(() => {
    return () => {
      // reset queue data on unmount
      dispatch(resetQueueState());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      // abort request of tasks on unmount
      if (abortTasksController) {
        abortTasksController.abort();
      }
    };
  }, [abortTasksController]);

  const getApptAsyncInfo = useCallback(
    (tasks: TaskProps[]) => {
      const appAsyncTasks = tasks?.filter((task) => task.category === TaskCategories.ApptAsync);

      return !!appAsyncTasks.length
        ? {
            ...appAsyncTasks[0],
            subRows: appAsyncTasks
          }
        : initialApptAsyncInfo;
    },
    [getTasks]
  );

  useEffect(() => {
    if (tasks) {
      dispatch(setTasksSummary(tasks));
      dispatch(setAsyncProviderEnabled(tasks.asyncProviderEnabled));
      dispatch(setApptAsyncProviderEnabled(tasks.apptAsyncProviderEnabled));
      if (showApptAsync) {
        dispatch(setApptAsyncInfo(getApptAsyncInfo(tasks.tasks)));
      }
    }
  }, [tasks, dispatch, getApptAsyncInfo, showApptAsync]);

  useEffect(() => {
    if (wasParamsChanged) {
      if (abortTasksController) {
        abortTasksController.abort(); // Abort the previous request of tasks
      }
      const { abort } = getTasks(buildParamsForRequest(), true);
      setAbortTasksController({ abort });
      dispatch(setOnGetQueueDetails(() => getTasks(buildParamsForRequest())));
      if (showRefreshTasksButton) dismissRefreshCounts();
    }
  }, [
    dispatch,
    getTasks,
    queryParams,
    dismissRefreshCounts,
    showRefreshTasksButton,
    wasParamsChanged,
    buildParamsForRequest
  ]);

  useEffect(() => {
    if (havePermissionToQueue && !isQueueDisabled) {
      getQueueInfo();
      if (showQueueList) {
        getQueueList();
      }

      const handleQueueDataUpdated = () => {
        getQueueInfo();
        if (showQueueList) {
          getQueueList();
        }
      };

      socketStaff.on('queueDataUpdated', handleQueueDataUpdated);

      return () => {
        socketStaff.off('queueDataUpdated', handleQueueDataUpdated);
      };
    }
  }, [getQueueInfo, getQueueList, havePermissionToQueue, showQueueList, isQueueDisabled]);

  return (
    <div className="relative">
      {showCancelModal && (
        <TimeConflict
          setShowModal={setShowCancelModal}
          appointmentStartTime={appointments?.[0]?.appointmentTime?.startTime || ''}
          onTakePatient={handleTakePatient}
          isLoading={isLoadingCheckAvailable}
        />
      )}
      <TasksTable
        regularTasksData={regularTasks}
        regularTasksColumns={regularTasksColumns as ColumnDef<TasksTableColumnsProps>[]}
        sortFields={sortFields}
        showQueue={showQueue}
        queueTableData={memoizedQueueTask}
        queueTableColumns={memoizedQueueColumns}
        totalCount={totalCount}
        isFetching={isFetching}
        showingFrom={showingFrom}
        showingTo={showingTo}
        handleOpenTask={handleOpenTask}
        showRefreshTasksButton={showRefreshTasksButton}
        newTasksCount={newTasksCount}
        handleRefreshTasks={handleRefreshTasks}
        showApptAsync={showApptAsync}
        apptAsyncTableData={memoizedApptAsyncTask as TaskProps[]}
        apptAsyncTableColumns={memoizedApptAsyncColumns as ColumnDef<TasksTableColumnsProps>[]}
      />
    </div>
  );
};

export default ConnectedTasksTable;
