import { Role } from 'enums/role';
import queryString from 'query-string';
import { RouteProps } from 'router/router.types';

export const getAllowedRoutes = (routes: RouteProps[], role: string, hiddenItems?: string[]) => {
  return routes.filter(
    ({ permissions, path }) =>
      !permissions?.length || (permissions.includes(role as Role) && !hiddenItems?.includes(path))
  );
};

export const checkIsProtectedRoute = (pathname: string, routes: RouteProps[]) =>
  routes.some((route) => {
    const regex = new RegExp('^' + route.path.replace(/:[^\s/]+/g, '([\\w-]+)') + '$');
    return regex.test(pathname);
  });

const decodeParamsList = [
  'searchKey',
  'name',
  'patientName',
  'displayName',
  'title',
  'author.name',
  'message',
  'phone',
  'shortCode'
];

export const parseQueryParams = <T extends Record<string, unknown>>(
  searchParams: URLSearchParams
): T => {
  const params = queryString.parse(searchParams.toString());
  Object.keys(params).map((key) => {
    if (key === 'email') {
      return (params.email = decodeURIComponent(params.email as string)?.trim());
    }
    if (decodeParamsList.includes(key)) {
      return (params[key] = decodeURIComponent(params[key] as string));
    }
  });
  return params as T;
};
