import { StatusFilterData } from 'constants/filters';

import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import BulkEdit from 'components/filters/BulkEdit';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/FrontDeskTable/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useSearchParams } from 'react-router';
import { FrontDeskRequestTypesQueryParams } from 'store/frontDeskRequestTypes/frontDeskRequestTypes.types';
import { useGetFrontDeskRequestTypesQuery } from 'store/frontDeskRequestTypes/frontDeskRequestTypesSlice';
import { parseQueryParams } from 'utils/router';

const FrontDeskRequestTypes: React.FC = () => {
  const [searchParams] = useSearchParams();

  const queryParams: FrontDeskRequestTypesQueryParams = useMemo(
    () => parseQueryParams(searchParams),
    [searchParams]
  );

  const { data, isFetching, isUninitialized } = useGetFrontDeskRequestTypesQuery({
    params: queryParams
  });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.data
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <div className="flex gap-2">
          <BulkEdit />
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default FrontDeskRequestTypes;
