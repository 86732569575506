import { useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';

export function useMembershipPlansOptions(usePlanName?: boolean) {
  const { data: membershipPlans } = useGetMembershipPlansQuery();
  return useMemo(
    () =>
      uniqBy(
        membershipPlans?.map((plan) => ({
          label: plan.planName,
          value: usePlanName ? plan.planName : plan._id
        })),
        'label'
      ),
    [membershipPlans]
  );
}
