import { ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import { useState } from 'react';

import DatePickerInput from 'components/common/DatePickerInput';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import dayjs from 'dayjs';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues } from './rolesFiltersForm.settings';
import { RolesFiltersFormProps } from './rolesFiltersForm.types';

const RolesFiltersForm = () => {
  const { control, watch, handleSubmit, reset, setValue, getValues } =
    useForm<RolesFiltersFormProps>({
      reValidateMode: 'onChange'
    });
  const [createdAtDate, setCreatedAtDate] = useState<Date>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useTableFiltersData('roles', defaultValues, reset);

  const onSubmit = (data: RolesFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      name: encodeURIComponent(data?.name),
      createdAt: data?.createdAt,
      color: encodeURIComponent(data?.color),
      _id: encodeURIComponent(data?._id),
      status: data?.status?.value,
      'userType.name': data?.userType?.value
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
            <InputField
              name="name"
              label="Role"
              type="text"
              placeholder="Enter role"
              control={control}
              value={formData?.name}
            />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={ROLE_OPTIONS}
              placeholder="Select role template"
              label="Role template"
              name="userType"
              defaultValue={formData?.userType}
            />
            <DatePickerInput
              placeholder="Enter date"
              label="Creation date"
              labelDirection="col"
              inputValue={getValues('createdAt')}
              setInputValue={(value) => setValue('createdAt', value)}
              selectedDate={createdAtDate}
              setSelectedDate={setCreatedAtDate}
              startAllowedDate={dayjs().subtract(100, 'year').toDate()}
              lastAllowedDate={new Date()}
              captionLayout={'dropdown'}
              wrapperClasses="w-full relative"
              size="sm"
            />
          </div>
          <div className="flex flex-col gap-4 pl-7">
            <InputField
              name="color"
              label="Color"
              type="text"
              placeholder="Enter color"
              control={control}
              value={formData?.color}
            />
            <InputField
              name="_id"
              label="Unique ID"
              type="text"
              placeholder="Enter unique ID"
              control={control}
              value={formData?._id}
            />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={STATUS_OPTIONS}
              placeholder="Select status"
              label="Status"
              name="status"
              defaultValue={formData?.status}
            />
          </div>
        </div>
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default RolesFiltersForm;
