import { ObjectValues } from 'utils/common/types';

export const TeamTags = {
  REQUEST_ORDER: 'request-order'
} as const;

export type TeamTags = ObjectValues<typeof TeamTags>;

export interface Member {
  userId: string;
  firstName: string;
  lastName: string;
  profileImage: string;
}

export interface Team {
  _id: string;
  name: string;
  description: string;
  members: Member[];
  tags?: TeamTags[];
}

export interface EditTeamData {
  name: string;
  description: string;
  members: string[];
}
