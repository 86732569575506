import { MissingPaymentReasons } from 'components/modals/ReleaseOrderFromHoldAlt/releaseOrderFromHold.types';
import { PatientPrescriptionsType, PaymentStatus } from 'enums/prescriptions';
import { TaskStatus } from 'models/tasks.types';
import { Address } from 'store/orders/orders.types';
export interface PatientPrescriptionsProps {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  type?: PatientPrescriptionsType;
  isDraft?: boolean;
  isStableDose?: boolean;
}

export interface RequestOrderTask {
  id: string;
  status: TaskStatus;
}

export interface PatientPrescription {
  id: string;
  _id?: string;
  elationPrescriptionId?: string;
  sig: string;
  name: string;
  subname?: string;
  type: PatientPrescriptionsType;
  qty: number;
  qtyUnits: 'ml' | 'tablets';
  createdAt: string;
  signedAt: string;
  supplements?: PatientPrescription[];
  orderStatus: string;
  paymentStatus: PaymentStatus;
  titration?: Titration;
  titrations: Titration[];
  orderId?: string;
  firstDoseTakenAt?: string;
  renews: string;
  renewal: number;
  renewalTaskId: string;
  fulfillmentState: string;
  deletedAt: string;
  renewalSurveys?: number;
  isCompound?: boolean;
  deliveredDate?: string;
  signedDate?: string;
  shippingStatus?: string;
  isRenewalRequestAllowed?: boolean;
  renewalRequestAllowedAt?: string;
  renewalFrequency?: {
    supply?: number;
  };
  isRenewalCreated?: boolean;
  renewalTask?: {
    id: string;
    status: RenewalStatus;
  };
  renewalSurvey?: {
    canceledAt: string | null;
  };
  bundleMedication?: {
    name: string;
    sigs: { name: string }[];
  };
  trackingNumber?: string;
  shippingAddress?: Address;
  pharmacy?: string;
  pharmacyStatus?: 'On hold' | 'Released from hold';
  invoiceId?: string;
  orderUpdatedAt?: string;
  releasedFromHoldAt?: string;
  requestOrderTask?: RequestOrderTask;
}

export enum RenewalStatus {
  URGENT = 'URGENT',
  Active = 'Active',
  Open = 'Open',
  Completed = 'Completed',
  Deleted = 'Deleted',
  Cancelled = 'Cancelled'
}

export interface Titration {
  qty: number;
  units: 'ml' | 'tablets';
  stableDose?: boolean;
  updatedAt?: string;
  createdAt?: string;
  adjustedAt: string;
  _id?: string;
}

export interface ReleaseOrderFromHoldAltQueryParams {
  orderId: string;
}
export interface LinkInvoiceQueryParams {
  orderId: string;
  invoiceId: string;
  reasons: MissingPaymentReasons[];
}
