import React, { useCallback, useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import Loader from 'components/common/Loader';
import CancelAppointment from 'components/modals/CancelAppointment';
import Pagination from 'components/tables/Pagination';
import { AppointmentListType } from 'enums/appointments';
import { Role } from 'enums/role';
import useLocationParams from 'hooks/common/location/useLocationParams';
import useZoomCall from 'hooks/common/useZoomCall';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';
import isEmpty from 'lodash/isEmpty';
import { AppointmentItemProps } from 'store/appointments/appointments.types';
import {
  useGetPatientAppointmentsQuery,
  useLazyGetPatientAppointmentsQuery
} from 'store/appointments/appointmentsSlice';
import { openModal } from 'store/modal/modalSlice';
import { PatientProps } from 'store/patients/patients.types';
import { useLazyLoginLikePatientQuery } from 'store/patients/patientsSlice';
import { useLazyGetTaskDetailsQuery } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

import { checkShowCancelButton, getAppointmentType } from './appointments.settings';
import Skeleton from './components/Skeleton';
import CreateAppointmentModal from './CreateAppointmentModal';

const Appointments: React.FC<{ patient: PatientProps }> = ({ patient }) => {
  const dispatch = useAppDispatch();
  const { appointmentId: appIdInProgress } = useZoomCall();
  const { ['pageNo']: [pageNo = '0'] = [] } = useLocationParams();
  const { handleOpenTask } = useOpenTask();
  const { userType, _id: staffId } = useAppSelector(selectUser);
  const isPhysician = userType?.name === Role.PH;

  const { _id: patientId, activePlanId } = patient;

  const [getTaskDetails, { isFetching: isFetchingTask }] = useLazyGetTaskDetailsQuery();
  const [loginLikePatient] = useLazyLoginLikePatientQuery();

  const { data: upcomingAppointments, isFetching: isFetchingUpcomingList } =
    useGetPatientAppointmentsQuery(
      {
        patientId: patientId || '',
        params: {
          appointmentListType: AppointmentListType.UpcomingAppointment
        }
      },
      { refetchOnMountOrArgChange: true }
    );
  const upcomingAppointmentsList = upcomingAppointments?.appointments || [];

  const [getPatientAppointments, { data: pastAppointments, isFetching: isFetchingPastList }] =
    useLazyGetPatientAppointmentsQuery();
  const pastAppointmentsList = pastAppointments?.appointments || [];

  const shouldShowCombineEmptyMessage =
    isEmpty(upcomingAppointmentsList) && isEmpty(pastAppointmentsList);

  const handleAppointmentOpen = (task: AppointmentItemProps) => {
    const taskId = task?.taskId;
    const appointmentStatus = task?.appointmentStatus;

    if (taskId && appointmentStatus === 'completed') {
      getTaskDetails({ taskId: taskId })
        .unwrap()
        .then((taskDetails) =>
          handleOpenTask({
            taskId: taskDetails?.postConsultTaskId ?? taskId,
            assignToId: taskDetails.assignedToInfo?._id ?? task.doctorId,
            assignToName: taskDetails.assignedToInfo?.name ?? task.staffName
          })
        );
    } else {
      handleOpenTask({ taskId: taskId, assignToId: task.doctorId, assignToName: task.staffName });
    }
  };

  const getPastAppointments = useCallback(() => {
    getPatientAppointments({
      patientId: patientId,
      params: {
        appointmentListType: AppointmentListType.PastAppointments,
        pageNo: +pageNo,
        limit: 10
      }
    });
  }, [getPatientAppointments, pageNo, patientId]);

  useEffect(() => {
    getPastAppointments();
  }, [getPastAppointments, pageNo]);

  const titleClassName = 'text-blue-700 text-lg font-bold';
  const itemListWrapperClassName = 'my-4 flex flex-col gap-4';
  const createAppointmentButtonClassName =
    'ml-5 flex h-8 items-center gap-1 rounded-lg border border-gray-200 px-4 py-1 text-sm font-bold text-gray-700 transition-all';

  const handleCancel = (appointmentId: string) => {
    dispatch(
      openModal({
        size: 'sm',
        modalContent: <CancelAppointment appointmentId={appointmentId} />,
        hideClose: true
      })
    );
  };

  const getAppointment = (item: AppointmentItemProps) => {
    const type = getAppointmentType(item, isPhysician);
    const startTime = item.appointmentTime?.startTime;
    const isQueue = item.category === 'queue';
    const title = isQueue ? 'ASAP Appointment' : item.appointmentType.displayName;

    return (
      <div className="rounded-2xl shadow" key={item._id}>
        <Common.Appointment
          title={title}
          {...(type === 'message' ? { type } : { type, ...(startTime && { startTime }) })}
          {...(isQueue && { description: item.appointmentType.displayName })}
          onClick={() => {
            item.missedAppointment
              ? loginLikePatient({ id: patientId, staffId })
              : handleAppointmentOpen(item);
          }}
          {...(checkShowCancelButton(item, appIdInProgress) && {
            handleCancel: () => handleCancel(item._id)
          })}
        />
      </div>
    );
  };

  const handleOpenModal = () => {
    dispatch(
      openModal({
        size: 'lg',
        modalContent: (
          <CreateAppointmentModal
            upcomingAppointmentsList={upcomingAppointmentsList || []}
            hasPastAppointment={pastAppointmentsList && pastAppointmentsList.length > 0}
          />
        )
      })
    );
  };

  return (
    <div data-testid="appointments_block" className="w-full px-6 pb-6 pt-4">
      <Loader isVisible={isFetchingUpcomingList || isFetchingPastList || isFetchingTask} />

      {shouldShowCombineEmptyMessage ? (
        <>
          <Skeleton
            title="This patient doesn’t have any appointments"
            description={
              activePlanId
                ? 'Create an appointment below using the button below'
                : 'They are a freemium user and would need to go on a subscription plan'
            }
            isFetching={isFetchingUpcomingList || isFetchingPastList}
            helperComponents={
              activePlanId && (
                <button className={createAppointmentButtonClassName} onClick={handleOpenModal}>
                  <Common.Icon name="lifemd" className="size-4" />
                  Create appointment
                </button>
              )
            }
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className={titleClassName}>Upcoming appointments</div>
            {activePlanId && (
              <button className={createAppointmentButtonClassName} onClick={handleOpenModal}>
                <Common.Icon name="lifemd" className="size-4" />
                Create appointment
              </button>
            )}
          </div>
          {upcomingAppointmentsList && upcomingAppointmentsList.length > 0 ? (
            <div className={itemListWrapperClassName} data-testid="upcomingAppointments">
              {upcomingAppointmentsList.map(getAppointment)}
            </div>
          ) : (
            <Skeleton
              title="This patient doesn’t have any upcoming appointments"
              description="Create an appointment by clicking the ‘Create appointment’ button above to the right"
              isFetching={isFetchingUpcomingList}
            />
          )}

          <span className={titleClassName}>Past appointments</span>
          {pastAppointmentsList && pastAppointmentsList.length > 0 ? (
            <>
              <div className={itemListWrapperClassName} data-testid="pastAppointments">
                {pastAppointmentsList.map(getAppointment)}
              </div>
              {pastAppointments?.totalCount && pastAppointments?.totalCount > 10 && (
                <Pagination
                  loading={isFetchingPastList}
                  defaultItemsPerPage={10}
                  totalCount={pastAppointments.totalCount}
                />
              )}
            </>
          ) : (
            <Skeleton
              title="This patient doesn’t have any past appointments"
              description="Create an appointment by clicking the ‘Create appointment’ button above to the right"
              isFetching={isFetchingPastList}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Appointments;
