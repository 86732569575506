import { Common } from '@thecvlb/design-system';
import StaffAnnouncement from 'components/modals/AnnouncementEditor';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string; hiddenPencil?: boolean }> = ({ id, hiddenPencil }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      {!hiddenPencil && (
        <button
          data-testid="pencil_icon"
          onClick={() =>
            dispatch(
              openModal({
                modalContent: <StaffAnnouncement id={id} />,
                size: 'xl',
                hideClose: true
              })
            )
          }
        >
          <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
        </button>
      )}
    </div>
  );
};

export default EditCell;
