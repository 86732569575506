import { useEffect, useRef, useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import Header from 'components/patient/Header';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { useGetPatientActivePricePoint } from 'hooks/patient/useGetPatientActivePricePoint';
import { useAppDispatch } from 'hooks/redux';
import { useParams, useSearchParams } from 'react-router';
import { RequestStatus, SuggestedMessageRequest } from 'store/aiAssistant/aiAssistant.types';
import { useGetSuggestedResponseMutation } from 'store/aiAssistant/aiAssistantSlice';
import { checkIsPatientWMorPrevWM } from 'store/patients/patients.settings';
import {
  resetBenefits,
  resetDocuments,
  resetPatientInfo,
  resetPaymentProfiles,
  setIsTripleTherapyPatient,
  useLazyGetPatientQuery
} from 'store/patients/patientsSlice';

import Banners from './Banners';
import { renderContentList } from './patient.settings';

const Patient = () => {
  const { patientId = '' } = useParams<{ patientId: string }>();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('active-tab');
  const dispatch = useAppDispatch();
  const isChartDisable = useFlag('hide-patient-chart-tab');
  const isAIPatientDisabled = useFlag('disable-ai-patient-summary');

  const retryRef = useRef<ReturnType<typeof setTimeout>>(undefined);
  const retryCount = useRef(0);

  const [getPatient, { data: patient, isLoading }] = useLazyGetPatientQuery();

  const [getSuggestedResponse, patientSummaryResult] = useGetSuggestedResponseMutation();
  const { currentPricePoint } = useGetPatientActivePricePoint(patient);

  const patientSummaryObj = {
    data: patientSummaryResult.data?.data,
    isLoading: patientSummaryResult.isLoading || retryCount.current > 0,
    isError: patientSummaryResult.isError && !retryCount.current
  };

  const isWMPatient = checkIsPatientWMorPrevWM(patient?.tags);

  const [activeTab, setActiveTab] = useState<PatientOverviewTab>(PatientOverviewTab.Overview);

  useEffect(() => {
    if (currentPricePoint) {
      dispatch(
        setIsTripleTherapyPatient(currentPricePoint?.planPricePointId === 'wm-tt-one-month')
      );
    }

    return () => {
      dispatch(setIsTripleTherapyPatient(false));
    };
  }, [currentPricePoint, dispatch]);

  useEffect(() => {
    if (patientId) {
      getPatient({ id: patientId });
    }
    if (activeTabParam) setActiveTab(activeTabParam as PatientOverviewTab);

    return () => {
      dispatch(resetDocuments());
      dispatch(resetBenefits());
    };
  }, [dispatch, activeTabParam, getPatient, patientId]);

  const generateSummary = () => {
    const params: SuggestedMessageRequest = {
      body: {
        flow: 'patientSummary',
        userId: patientId,
        text: ''
      }
    };
    const handleRetry = () => {
      if (retryCount.current < 4) {
        retryCount.current++;
      } else {
        retryCount.current = 0;
      }
    };
    getSuggestedResponse(params)
      .unwrap()
      .then((res) => {
        if (res?.info?.status === RequestStatus.IN_PROGRESS) {
          handleRetry();
        } else {
          retryCount.current = 0;
        }
      })
      .catch((error) => {
        if ('status' in error && (error.status === 504 || error.status === 'FETCH_ERROR')) {
          console.error(`Request failed with status: ${error.status}`);
          handleRetry();
          return;
        }
      });
  };

  useEffect(() => {
    if (patientId && isWMPatient && !isAIPatientDisabled) {
      generateSummary();
    }
  }, [getSuggestedResponse, patientId, isWMPatient]);

  useEffect(() => {
    if (retryCount.current && retryCount.current < 4) {
      retryRef.current = setTimeout(() => generateSummary(), 30000);
    }

    return () => {
      clearTimeout(retryRef.current);
    };
  }, [retryCount.current]);

  useEffect(() => {
    return () => {
      dispatch(resetPatientInfo());
      dispatch(resetPaymentProfiles());
      retryRef.current && clearTimeout(retryRef.current);
    };
  }, [dispatch]);

  return (
    <>
      <Loader isVisible={isLoading} />
      {patient && (
        <>
          <Card dataTestId="patient_page">
            <Header activeTab={activeTab} setActiveTab={setActiveTab} patient={patient} />
            <div className="border-t border-gray-200">
              {!!patient && <Banners patient={patient} />}
              {renderContentList(activeTab, patient, patientId, patientSummaryObj, isChartDisable)}
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default Patient;
