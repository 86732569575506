import { StatusFilterData } from 'constants/filters';

import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { ArticlesTableProps } from 'components/tables/ArticlesTable/articlesTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/StaffAnnouncementsTable/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router';
import { useGetAnnouncementsQuery } from 'store/staffAnnouncements/staffAnnouncementsSlice';
import { selectUser } from 'store/user/userSlice';

const Announcements = () => {
  const [searchParams] = useSearchParams();
  const { userType } = useAppSelector(selectUser);

  const queryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);
  const {
    data: originalData,
    isFetching,
    isLoading,
    isUninitialized
  } = useGetAnnouncementsQuery({ params: queryParams });
  const data = originalData;

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns: columns(userType.name),
    data: data?.staffAnnouncements
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<ArticlesTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isLoading} />
      </Card>
    </>
  );
};

export default Announcements;
