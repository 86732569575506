import { useEffect, useState } from 'react';

import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import Header from 'components/order/Header';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { useParams, useSearchParams } from 'react-router';
import { useLazyGetOrderQuery } from 'store/orders/ordersSlice';

import { renderContentList } from './orderDetails.settings';

const OrderDetails = () => {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('active-tab');

  const [getOrder, { data: order, isLoading }] = useLazyGetOrderQuery();
  const [activeTab, setActiveTab] = useState<PatientOverviewTab>(PatientOverviewTab.Overview);

  useEffect(() => {
    if (orderId) {
      getOrder({ id: orderId }, true);
    }
    if (activeTabParam) setActiveTab(activeTabParam as PatientOverviewTab);
  }, [activeTabParam, getOrder, orderId]);

  return (
    <>
      <Loader isVisible={isLoading} />
      {order && (
        <div className="pb-32">
          <Card dataTestId="patient_page" className="p-8">
            <Header activeTab={activeTab} order={order} />
            <div className="border-t border-gray-100">{renderContentList(activeTab, order)}</div>
          </Card>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
