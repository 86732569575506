import classNames from 'classnames';
import SearchInput from 'components/filters/SearchForm/SearchInput';
import { PathName } from 'enums/pathName';
import { userRenderFilters } from 'hooks/filters/useRenderFilters';
import { matchPath, useLocation } from 'react-router';

const TableFilters = () => {
  const location = useLocation();
  const isAgingConfiguration = !!matchPath(PathName.AgingConfiguration, location.pathname);
  const isInternalAutomations = !!matchPath(PathName.InternalAutomations, location.pathname);
  const isBillingConfiguration = !!matchPath(PathName.BillingConfiguration, location.pathname);
  const isAppointmentTypes = !!matchPath(PathName.AppointmentTypes, location.pathname);
  const wrapperClasses = classNames(
    'relative px-2 py-6 flex-auto',
    { 'w-[568px]': isAgingConfiguration },
    { 'w-[568px]': isBillingConfiguration },
    { 'w-[568px]': isAppointmentTypes },
    { 'w-[568px]': isInternalAutomations }
  );
  const filters = userRenderFilters(location.pathname, location.search);

  return (
    <div data-testid="table_filters_popup" className={wrapperClasses}>
      <h2 className="mb-3 text-lg font-bold text-gray-700">Search</h2>
      <SearchInput />
      <h2 className="mb-4 mt-8 text-lg font-bold text-gray-700">Filter</h2>
      {filters}
    </div>
  );
};

export default TableFilters;
