export enum AppointmentStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Missed = 'Appointment • Missed',
  CareTeamSentMessage = 'careTeamSentMessage'
}

export enum PlanCodesProps {
  // Components
  InitialAppointment = 'initial-appointment',
  Appointment = 'appointment-30',
  // Old plans
  UnlimitedMembership = 'unlimited-membership',
  LifeMDMembership = 'membership',
  WeightManagementMembership = 'weight-management',
  AnnualMembership = 'lifemd-annual-membership',
  // New plans
  TotalCareMembership = 'total-care',
  ConciergeMembership = 'concierge-care',
  LimitedAccess = 'limited-access',
  // --
  UrgentCare = 'urgent-care'
}
