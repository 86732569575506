import { useCallback } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import ProspectDetails from 'components/modals/ProspectDetails';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router';
import { openModal } from 'store/modal/modalSlice';
import { ProspectPatientProps } from 'store/patients/patients.types';

const ActionsCell: React.FC<{ prospectPatient: ProspectPatientProps }> = ({ prospectPatient }) => {
  const { initiatePhoneCallProps, showCallCard } = usePhoneCall();
  const dispatch = useDispatch();
  const isOrdered = prospectPatient.prospectStatus === 'Ordered';

  const handleCall = useCallback(async () => {
    if (!showCallCard) {
      initiatePhoneCallProps({ isSlidingPanel: false, patientToCall: prospectPatient });
    }
  }, [showCallCard]);

  const handleClick = () => {
    dispatch(
      openModal({
        size: 'lg',
        hideClose: true,
        modalContent: <ProspectDetails patientId={prospectPatient._id} handleCall={handleCall} />
      })
    );
  };

  return (
    <div className="flex gap-3">
      {!isOrdered ? (
        <button data-testid="details-btn" onClick={handleClick}>
          <Common.Icon name="list-view" className="size-5 text-gray hover:text-primary-500" />
        </button>
      ) : (
        <Common.Icon name="list-view" className="size-5 text-gray" />
      )}

      {prospectPatient.phone?.internationalFormat && !isOrdered ? (
        <button data-testid="call-btn" onClick={handleCall}>
          <Common.Icon name="phone-filled" className="size-5 text-gray hover:text-primary-500" />
        </button>
      ) : (
        <span data-testid="call-btn">
          <Common.Icon name="phone-filled" className="size-5 text-gray-300" />
        </span>
      )}
      {!isOrdered ? (
        <Link data-testid="cart-btn" to={`/cross-sell?userId=${prospectPatient._id}`}>
          <Common.Icon name="checkout" className="size-5 text-gray hover:text-primary-500" />
        </Link>
      ) : (
        <span data-testid="cart-btn">
          <Common.Icon name="checkout" className="size-5 text-gray-300" />
        </span>
      )}
    </div>
  );
};

export default ActionsCell;
