import { HealthProfileNavItem } from 'enums/chart';

import { ChartTab } from './chart.types';

export const chartTabs: ChartTab[] = [
  { icon: 'list-view', label: HealthProfileNavItem.MIF },
  { icon: 'reports', label: HealthProfileNavItem.HealthMetrics },
  { icon: 'heartbeat', label: HealthProfileNavItem.HealthConditions },
  { icon: 'lungs', label: HealthProfileNavItem.ChronicDiseases },
  { icon: 'female', label: HealthProfileNavItem.WomenHealth },
  { icon: 'martini', label: HealthProfileNavItem.SocialHistory },
  { icon: 'prescription-outline', label: HealthProfileNavItem.Medications },
  { icon: 'allergy', label: HealthProfileNavItem.Allergies }
];
