import { DEFAULT_LIMIT_LOADED_ONCE_MESSAGES } from 'constants/chat';

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { MessageType } from 'enums/messages';
import { useSocketConnection } from 'hooks/common/messages/useSocketConnection';
import { useAppDispatch } from 'hooks/redux';
import { SocketResponseProps } from 'models/socket.types';
import { useLocation } from 'react-router';
import socket from 'socket/socketFrontDesk';
import type { Socket } from 'socket.io-client';
import { updateChannel } from 'store/channels/channelsSlice';
import {
  clearMessages,
  useDeleteMessageMutation,
  useLazyGetCareMessagesQuery,
  useUpdateMessageMutation
} from 'store/chat/chatSlice';
import { eventCallBack, logStyles } from 'utils/socket';

import { FrontDeskContextProps } from './frontDeskContext.types';
import {
  DeleteMessageFromServerParams,
  NewMessageToServerParams,
  UpdatedMessageToServerParams
} from '../MessagesContext/messagesContext.types';

const FrontDeskContext = createContext<FrontDeskContextProps | undefined>(undefined);

const FrontDeskProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [getSupportMessages] = useLazyGetCareMessagesQuery();
  const [updateMessage] = useUpdateMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();

  const [channelDetails, setChannelDetails] = useState<
    FrontDeskContextProps['channelDetails'] | null
  >(null);

  const { socketInit } = useSocketConnection({ socket: socket });

  const [isConnected, setIsConnected] = useState(socket.connected);

  const closeChannel = useCallback(() => {
    dispatch(clearMessages());
  }, [dispatch]);

  /**
   * Marks messages as seen for a specific user in a specific channel.
   *
   * @param {string} channelId - The ID of the channel where the messages are marked as seen.
   * @param {string} id - The ID of the user for whom the messages are marked as seen.
   * @returns {void}
   * @throws Will throw an error if the socket is not connected.
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---emit---markseen|Socket event details}
   */
  const markSeen = useCallback(({ channelId, id }: { channelId: string; id: string }) => {
    if (channelId && id && socket.connected) {
      socket.emit('markSeen', { channelId, userId: id });
    }
  }, []);

  /**
   * Joins a Support Channel, disconnecting the user from other Care Channels.
   * On success, emits channelDetails and historyCatchUp events.
   *
   * @param {string} roomId - The ID of the Support Channel to join.
   * @returns {void}
   * @throws Will throw an error if the socket is not connected.
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---emit---joinroom|Socket event details}
   */
  const joinRoom = useCallback(
    (channelId: string, unreadMessageCount: number) => {
      if (channelId) {
        // if there are more than 10 unread messages, load all unread messages at once
        // to have ability to scroll to the first unread message;
        const limit =
          unreadMessageCount > DEFAULT_LIMIT_LOADED_ONCE_MESSAGES
            ? unreadMessageCount
            : DEFAULT_LIMIT_LOADED_ONCE_MESSAGES;

        socket.emit('joinRoom', channelId, (response: SocketResponseProps) => {
          eventCallBack('joinRoom', response);
          getSupportMessages({ channelId, limit, type: MessageType.Support });
        });
      }
    },
    [getSupportMessages]
  );

  /**
   * Sends a new message to the server.
   * The server will then broadcast this message to all other users in the same Support Channel.
   *
   * @param {Object} newMessage - The new message to be sent.
   * @param {boolean} isAppointmentChat - Whether the message was sent durning appointment.
   * @returns {void}
   * @throws Will throw an error if the socket is not connected.
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---emit---newmessagetoserver|Socket event details}
   */
  const sendMessageToServer = useCallback(
    ({ newMessage }: { newMessage: NewMessageToServerParams }) => {
      if (socket.connected) socket.emit('sendMessage', newMessage);
    },
    []
  );

  /**
   * Sends updated message to the server.
   *
   * @param {Object} params - object with the necessary params.
   * @returns {void}
   */
  const sendUpdatedMessageToServer = (params: UpdatedMessageToServerParams) => {
    updateMessage(params);
  };

  /**
   * Deletes message from the server.
   *
   * @param {Object} params - object with the necessary params.
   * @returns {void}
   */
  const deleteMessageFromServer = (params: DeleteMessageFromServerParams) => {
    deleteMessage(params);
  };

  const handleDisconnect = useCallback(() => {
    socket.disconnect();
    closeChannel();
  }, [closeChannel]);

  // Disconnect socket on route change
  useEffect(() => {
    if (socket.connected) handleDisconnect();
  }, [handleDisconnect, location.pathname]);

  useEffect(() => {
    socketInit();

    // Disconnect socket on unmount
    return () => {
      handleDisconnect();
    };
  }, [socketInit, handleDisconnect]);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
      console.info('%c successfully connected to /frontdesk namespace', logStyles.connectMsgStyles);
    };

    socket.on('connect', onConnect);

    return () => {
      socket.off('connect', onConnect);
    };
  }, []);

  /**
   * Sets up a listener for the 'channelDetails' event from the socket.
   * When the 'channelDetails' event is received, it updates the channel details state.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'channelDetails' event listener and resets the channel details state.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-any|Socket.IO event details}
   */
  useEffect(() => {
    socket.on('channelDetails', setChannelDetails);

    return () => {
      socket.off('channelDetails', () => setChannelDetails(null));
    };
  }, []);

  /**
   * Sets up a listener for the 'unreadMessageUpdated' event from the socket.
   * When the 'unreadMessageUpdated' event is received, it dispatches an action to update the channel with the new data.
   * The response object contains the channelId, latestMessage, latestMessageDate, and unreadMessageCount.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'unreadMessageUpdated' event listener.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-unreadMessageUpdated|Socket.IO event details}
   */
  useEffect(() => {
    const handleUnreadMessageUpdated = (response: {
      channelId: string;
      latestMessage: string;
      latestMessageDate: string;
      unreadMessageCount: number;
    }) => dispatch(updateChannel(response));

    socket.on('unreadMessageUpdated', handleUnreadMessageUpdated);

    return () => {
      socket.off('unreadMessageUpdated', handleUnreadMessageUpdated);
    };
  }, [dispatch]);

  /**
   * Sets up a listener for the 'connect_error' event from the socket.
   * When the 'connect_error' event is received, it checks if the error message is 'AUTH_EXPIRED'.
   * If so, it reinitialize the socket connection.
   * Also, it logs the error message.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'connect_error' event listener.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-connect_error|Socket.IO event details}
   */
  useEffect(() => {
    const handleSocketError = (error: Error) => {
      if (error.message === 'AUTH_EXPIRED' || error.message === 'AUTH_INVALID') {
        // reinitialize socket connection
        socketInit();
      }
      console.error(
        `%c~ file: FrontDeskContext.tsx:108 ~ handleSocketError ~ error: ${error}`,
        logStyles.errorMsgStyles
      );
    };

    socket.on('connect_error', handleSocketError);

    return () => {
      socket.off('connect_error', handleSocketError);
    };
  }, [socketInit]);

  /**
   * Sets up a listener for the 'disconnect' event from the socket.
   * When the 'disconnect' event is received, it checks if the reason is 'io server disconnect'.
   * If so, it tries to reconnect manually.
   * Otherwise, the socket will automatically try to reconnect.
   * It also logs the reason for the disconnection.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'disconnect' event listener.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-disconnect|Socket.IO event details}
   */
  useEffect(() => {
    const onDisconnect = (reason: Socket.DisconnectReason) => {
      setIsConnected(false);
      if (reason === 'io server disconnect') {
        // The disconnection was initiated by the server, try to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
      console.info(
        `%c~ file: FrontDeskContext.tsx:133 ~ socket.on ~ reason: ${reason}`,
        logStyles.disconnectMsgStyles
      );
    };

    socket.on('disconnect', onDisconnect);
    return () => {
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  const value = {
    channelDetails,
    isConnected,
    joinRoom,
    closeChannel,
    markSeen,
    sendMessageToServer,
    sendUpdatedMessageToServer,
    deleteMessageFromServer
  };

  return <FrontDeskContext.Provider value={value}>{children}</FrontDeskContext.Provider>;
};

FrontDeskProvider.displayName = 'Front Desk Provider';

const useFrontDesk = () => {
  const context = useContext(FrontDeskContext);
  if (context === undefined) {
    throw new Error(`useFrontDesk must be used within a FrontDeskProvider`);
  }
  return context;
};

export { FrontDeskProvider, useFrontDesk };
