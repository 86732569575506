import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { ArrowDown } from '@thecvlb/design-system/lib/generated/icons';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { AccordionProps } from './accordion.types';

const Accordion = ({
  title,
  children,
  isOpen = true,
  wrapperClassNames,
  panelClassNames,
  buttonClassNames,
  showInfoButton = true,
  isModal,
  hasError,
  appear,
  ref
}: AccordionProps) => {
  const wrapperClasses = twMerge(!isModal && 'mb-8', wrapperClassNames);
  const buttonClasses = classNames(
    'flex w-full items-center justify-between ',
    { 'bg-gray-100 px-6 py-3': !isModal, 'p-1': isModal, 'bg-red-100 rounded': hasError },
    buttonClassNames
  );
  const panelClasses = twMerge('bg-white', isModal ? 'py-2' : 'px-6 py-8', panelClassNames);
  const buttonTitleClasses = classNames(
    'mr-3 font-bold',
    hasError ? 'text-red-500' : 'text-gray-700'
  );
  const arrowDownClasses = (isAccordionOpen: boolean) =>
    classNames('size-5', isModal ? 'text-base' : 'text-gray', {
      'rotate-180': isAccordionOpen && !isModal,
      '-rotate-90': !isAccordionOpen && isModal,
      '!text-red-500': hasError
    });

  return (
    <Disclosure defaultOpen={isOpen}>
      {({ open }) => (
        <div ref={ref} className={wrapperClasses}>
          <DisclosureButton className={buttonClasses}>
            <div className="flex items-center">
              <h3 className={buttonTitleClasses}>{title}</h3>
              {showInfoButton && (
                <Common.Icon name="info" className="mt-0.5 size-4 text-gray-400" />
              )}
            </div>

            <ArrowDown className={arrowDownClasses(open)} />
          </DisclosureButton>
          <Transition
            appear={appear}
            enter="transition duration-150 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <DisclosurePanel className={panelClasses}>{children}</DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default Accordion;
