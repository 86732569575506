import React from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { StaffCardTypes } from './staffCard.types';
import { Provider } from '../../dateAndTime.types';

const StaffCard: React.FC<StaffCardTypes> = ({
  activeProvider,
  currentProvider,
  setProvider,
  staffInfo,
  isLoading
}) => {
  const isAnyProvider = currentProvider === Provider.any;

  const wrapperClassName = classNames(
    'flex-grow cursor-pointer basis-1/2 py-3.5 px-6 border rounded-2xl disabled:opacity-30 disabled:cursor-default',
    activeProvider === currentProvider && 'border-primary-300 bg-primary-100 border-2',
    isLoading && 'animate-pulse'
  );

  const description = isAnyProvider ? 'Typically available in a day' : 'Their primary provider';

  return (
    <button
      data-testid="provider_select_btn"
      type="button"
      disabled={isLoading}
      className={wrapperClassName}
      onClick={() => setProvider(currentProvider)}
    >
      <div className="flex items-center gap-2 text-left">
        {isAnyProvider ? (
          <Common.Icon className="size-9 text-primary-600" name="doctor" />
        ) : (
          <Common.ProfileImage src={staffInfo?.profileImage} />
        )}

        <div>
          <h4 className={isAnyProvider ? 'font-bold' : 'font-semibold'}>
            {isAnyProvider ? 'Any available provider' : staffInfo?.displayName}
          </h4>
          <span className="text-mSm font-medium text-gray">{description}</span>
        </div>
      </div>
    </button>
  );
};

export default StaffCard;
