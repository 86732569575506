import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { FrontDeskRequestTypesFiltersFormProps } from './frontDeskRequestTypesFiltersForm.types';
import { defaultValues } from './frontDeskRequestTypesForm.settings';

const FrontDeskRequestTypesFiltersForm = () => {
  const { control, watch, handleSubmit, reset } = useForm<FrontDeskRequestTypesFiltersFormProps>({
    reValidateMode: 'onChange'
  });
  const [formData, setFormData] = useTableFiltersData(
    'frontdeskRequestTypes',
    defaultValues,
    reset
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (data: FrontDeskRequestTypesFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...data,
      searchKey: data.searchKey && encodeURIComponent(data.searchKey),
      requestType: data.requestType && encodeURIComponent(data.requestType),
      routeTo: data.routeTo && encodeURIComponent(data.routeTo),
      uniqueId: data.uniqueId && encodeURIComponent(data.uniqueId),
      status: data.status?.value
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex w-[312px] flex-col gap-4 2xl:w-[410px]">
      <InputField
        dataTestId="request_type_field"
        name="requestType"
        label="Type"
        type="text"
        placeholder="Enter type"
        control={control}
        value={formData?.requestType}
      />
      <InputField
        dataTestId="route_to_field"
        name="routeTo"
        label="Route to"
        type="text"
        placeholder="Enter route to"
        control={control}
        value={formData?.routeTo}
      />
      <InputField
        dataTestId="unique_id"
        name="uniqueId"
        label="Unique ID"
        type="text"
        placeholder="Enter unique ID"
        control={control}
        value={formData?.uniqueId}
      />
      <ControlledSelect
        dataTestId="status_field"
        control={control}
        labelDirection="col"
        options={STATUS_OPTIONS}
        placeholder="Select status..."
        label="Status"
        name="status"
        defaultValue={formData?.status}
      />
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default FrontDeskRequestTypesFiltersForm;
