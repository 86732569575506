import { ChangeEvent, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledCombobox from 'components/forms/controlled/ControlledCombobox';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router';
import { useToggle } from 'react-use';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { reset } from 'store/slidingInPane/slidingInPaneSlice';
import { AvailableStaffResponse } from 'store/tasks/task.types';
import { resetTask, selectTask, useAssignStaffToTaskMutation } from 'store/tasks/tasksSlice';

import { useGetAvailableStaff } from './assignToUser.settings';
import type { AssignToUserProps } from './assignToUser.types';
import TaskUpdated from '../CreateTaskForm/TaskUpdated';

const AssignToUser = ({ taskId, assignedToId, taskCategory, patientId }: AssignToUserProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { taskDetails } = useAppSelector(selectTask);
  const [query, setQuery] = useState('');
  const [includeLeadership, toggleIncludeLeadership] = useToggle(false);
  const [isTaskNotUrgent, toggleIsTaskNotUrgent] = useToggle(false);

  const { searchData, hasClinicalLeader, isLoading, isSuccess } = useGetAvailableStaff(
    query,
    assignedToId ?? taskDetails?.assignedToInfo?._id,
    taskId,
    includeLeadership
  );
  const [assignStaffToTask, { isLoading: isLoadingAssignStaff }] = useAssignStaffToTaskMutation();
  const { handleSubmit, control, setValue, setError, clearErrors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit'
  });
  const formData = useWatch({ control }) as { staffMember: Option };
  const error = (formData?.staffMember?.value as AvailableStaffResponse)?.warning
    ? (formData?.staffMember?.value as AvailableStaffResponse)?.warning
    : undefined;

  const cancelAssignUser = () => dispatch(closeModal());

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrors('staffMember');
    setQuery(event?.target.value);
    if (!event.target.value?.length) {
      setValue('staffMember', '');
    }
  };

  const handleChangeStaff = (option: Option) => {
    clearErrors('staffMember');
    setValue('staffMember', option);

    const warningMessage = (option.value as AvailableStaffResponse)?.warning?.message;

    if (warningMessage) {
      setError(`staffMember`, { type: 'manual', message: warningMessage });
    }
    toggleIsTaskNotUrgent(false);
  };

  const openSuccessModal = () =>
    dispatch(
      openModal({
        modalContent: (
          <div className="p-6">
            <TaskUpdated
              type="assigned"
              assignedTo={formData.staffMember?.label ?? ''}
              patientId={taskDetails?.patientId || patientId || ''}
              taskCategory={taskCategory ?? taskDetails?.category}
              closeModal={cancelAssignUser}
              heading="Assign task"
            />
          </div>
        ),
        size: 'sm',
        hideClose: true
      })
    );

  const handleAssignUser = () => {
    if (formData?.staffMember) {
      assignStaffToTask({
        taskId: taskId ?? taskDetails._id,
        staffId: formData.staffMember.id || '',
        timezone: dayjs.tz.guess()
      })
        .unwrap()
        .then((data) => {
          notifySuccess(data.message);
          if (searchParams.has('taskModalOpenID')) {
            searchParams.delete('taskModalOpenID');
            setSearchParams(searchParams);
          }
          dispatch(reset());
          dispatch(resetTask());
          openSuccessModal();
        });
    }
  };

  const disabledSubmit =
    error?.canAssignStaff === false || (!!error?.shouldConfirm && !isTaskNotUrgent);

  const successHelperText =
    !!formData?.staffMember?.value && !error?.message
      ? "Provider is online and licensed in patient's state"
      : '';

  const noAvailableStaffMessage =
    isSuccess && !isLoading && !!query && !searchData.length ? 'No staff members found' : undefined;

  const helperText = error?.message || noAvailableStaffMessage || successHelperText;

  return (
    <div data-testid="assign_provider_popup" className="p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">Assign task</h2>

      {hasClinicalLeader && (
        <div className="my-4">
          <Common.Checkbox checked={includeLeadership} onChange={toggleIncludeLeadership}>
            Include clinical leadership - please only do this when other providers are unavailable
          </Common.Checkbox>
        </div>
      )}

      <form onSubmit={handleSubmit(handleAssignUser)} className="flex flex-col gap-4">
        <ControlledCombobox
          size="sm"
          label="Staff member"
          labelDirection="col"
          control={control}
          name="staffMember"
          defaultValue=""
          options={searchData}
          isLoading={isLoading}
          preIcon="search"
          inputValue={query}
          onChange={handleChangeStaff}
          onInputChange={handleInputChange}
          hideComboboxButton
          errors={error || undefined}
          helperText={helperText}
          placeholder="Search staff"
          rules={{
            required: {
              value: true,
              message: 'Please select a staff member'
            }
          }}
          immediate
          showAllOptions
        />
        {error?.shouldConfirm && (
          <div className="my-4">
            <Common.Checkbox checked={isTaskNotUrgent} onChange={toggleIsTaskNotUrgent}>
              I confirm that the task is not urgent so it can wait more than 48 hours
            </Common.Checkbox>
          </div>
        )}

        <div className="mt-2 grid grid-cols-2 gap-x-2">
          <Common.Button
            type="button"
            color="white-alt"
            className="!w-full justify-center"
            onClick={cancelAssignUser}
            size="sm"
          >
            Cancel
          </Common.Button>
          <Common.Button
            className="!w-full justify-center"
            type="submit"
            color={error?.message ? 'red' : 'blue'}
            size="sm"
            disabled={disabledSubmit}
            isLoading={isLoadingAssignStaff}
          >
            Assign
          </Common.Button>
        </div>
      </form>
    </div>
  );
};

export default AssignToUser;
