import { useCallback, useEffect } from 'react';

import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import useLocationParams from 'hooks/common/location/useLocationParams';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isNumber from 'lodash/isNumber';
import { selectBasicTables } from 'store/basicTable/basicTableSlice';
import { resetBulkEdits } from 'store/bulkEdit/bulkEditSlice';

import { BasicTableProps } from './basicTable.types';
import Pagination from '../Pagination';

const BasicTable = <TData extends object>({
  table,
  tableName,
  isFetching,
  totalCount,
  theadClassName = 'sticky top-24 z-10',
  onClickRow,
  customSortingTableName,
  shortPaginationPageRange
}: BasicTableProps<TData>) => {
  const dispatch = useAppDispatch();
  const {
    [tableName ? `${tableName}` : 'pageNo']: [pageNo = '0'] = [],
    sortField,
    limit = [10]
  } = useLocationParams();
  const basicTablesParams = useAppSelector(selectBasicTables);
  const defaultColumnWidth = 150;
  const currPageNo = customSortingTableName
    ? (basicTablesParams?.[customSortingTableName]?.pageNo ?? 0)
    : pageNo;
  const currSortField = customSortingTableName
    ? basicTablesParams?.[customSortingTableName]?.sortField
    : sortField;
  const currLimit = customSortingTableName
    ? (basicTablesParams?.[customSortingTableName]?.limit ?? [10])
    : limit;

  const count = Number(currLimit?.[0]) ?? 10;
  const showingFrom = +currPageNo + 1 === 1 ? +currPageNo + 1 : +currPageNo * count + 1;
  const showingTo = (+currPageNo + 1) * count > totalCount ? totalCount : (+currPageNo + 1) * count;
  const showPagination = isNumber(totalCount) && totalCount > 0;

  const getThClasses = (columnId: string) =>
    classNames(
      `p-3.5 text-left`,
      (currSortField?.[0] === 'uniqueId' && columnId === '_id') ||
        currSortField?.[0] === columnId ||
        currSortField?.[0].split('.')[0] === columnId.split('.')[0]?.split('[')[0]
        ? 'bg-gray-200'
        : 'bg-gray-100',
      {
        'min-w-[125px]': columnId === 'outcome'
      }
    );

  const rowClasses = (onClick: BasicTableProps<{}>['onClickRow']) => {
    return classNames('h-14 border-t border-t-gray-100 text-sm hover:bg-gray-50', {
      'cursor-pointer': !!onClick
    });
  };

  const resetBulkEditState = useCallback(() => {
    table.resetRowSelection();
    dispatch(resetBulkEdits());
  }, [dispatch, table]);

  useEffect(() => {
    return () => {
      dispatch(resetBulkEdits());
    };
  }, [dispatch]);

  return (
    <>
      <div data-testid="table_container" className="relative h-4/5 min-h-fit w-full max-w-full">
        {table.getRowModel().rows.length ? (
          <table data-testid="data_table" className="w-full rounded-lg">
            <thead className={theadClassName}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className={getThClasses(header.column.id)}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="w-fit bg-white">
              {table.getRowModel().rows.map((row) => (
                <tr
                  data-testid="table_row"
                  key={row.id}
                  className={rowClasses(onClickRow)}
                  onClick={() => onClickRow && onClickRow(row.original)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const celWidth =
                      cell.column.columnDef.size === defaultColumnWidth
                        ? 'auto'
                        : cell.column.columnDef.size;
                    return (
                      <td
                        key={cell.id}
                        className="border-b border-gray-200 p-3 text-sm font-medium"
                        style={{
                          width: celWidth,
                          maxWidth: celWidth
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="my-2 p-4 text-center">{isFetching ? 'Loading...' : 'No data available'}</p>
        )}
      </div>

      <div
        className={classNames('flex max-w-full items-center justify-between', {
          'p-4': (showPagination && !isFetching) || isFetching
        })}
      >
        {showPagination && (
          <>
            <Pagination
              loading={isFetching}
              showingFrom={showingFrom}
              showingTo={showingTo}
              totalCount={totalCount}
              pageNoParamName={tableName}
              resetBulkEditState={resetBulkEditState}
              customSortingTableName={customSortingTableName}
              shortPageRange={shortPaginationPageRange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default BasicTable;
