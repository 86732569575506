import { useState } from 'react';

import { Common } from '@thecvlb/design-system';
import Tooltip from 'components/tables/TaskTable/OnboardingDetails/Tooltip';
import { PathName } from 'enums/pathName';
import { Role } from 'enums/role';
import { useAppSelector } from 'hooks/redux';
import { useNavigate } from 'react-router';
import { selectUser } from 'store/user/userSlice';
import { twMerge } from 'tailwind-merge';

import { StuckOrderStatusProps } from './stuckOrderStatus.types';

const StuckOrderStatus: React.FC<StuckOrderStatusProps> = ({
  stuckOrder,
  tooltipWrapperClasses = '',
  iconWrapperClasses = ''
}) => {
  const { userType } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const {
    orderId: stuckOrderId,
    status: stuckOrderStatus,
    exception: stuckOrderException
  } = stuckOrder || {
    orderId: '',
    status: '',
    exception: ''
  };

  const [isIconHovered, setIsIconHovered] = useState(false);
  const isPH = userType?.name === Role.PH;

  const statusValueClassNames = 'font-bold text-yellow-300';
  const stuckOrderIconWrapperClasses = twMerge(
    'relative self-end pl-2 align-bottom',
    iconWrapperClasses
  );

  // NOTE: PH doesn't have access to the order details page
  const handleStuckOrderStatusIconClick = () => {
    !isPH && navigate(`${PathName.Orders}?orderModalOpenID=${stuckOrderId}`);
  };

  const iconFocusProps = {
    onMouseOver: () => {
      setIsIconHovered(true);
    },
    onMouseLeave: () => {
      setIsIconHovered(false);
    }
  };
  return (
    <div
      className={stuckOrderIconWrapperClasses}
      data-testid="stuck-order-icon"
      {...iconFocusProps}
      onClick={handleStuckOrderStatusIconClick}
    >
      <Common.Icon name="tag-exception" className="size-4 text-yellow-500" />
      {isIconHovered && (
        <Tooltip wrapperClasses={tooltipWrapperClasses}>
          {`Pharmacy: `}
          <span className={statusValueClassNames} data-testid="stuck-order-status-tooltip">
            {stuckOrderException || stuckOrderStatus}
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default StuckOrderStatus;
