import { Editor } from '@tinymce/tinymce-react';
import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useParams } from 'react-router';
import { useGetArticleQuery } from 'store/articles/articlesSlice';

const SingleArticle = () => {
  const { articleId = '' } = useParams<{ articleId: string }>();
  const { data, isLoading, isFetching } = useGetArticleQuery({ id: articleId });

  return (
    <>
      <Loader isVisible={isLoading || isFetching} />
      {data && (
        <Card dataTestId="single_article_section" className="mt-1 flex-wrap p-12">
          <h1 data-testid="article_heading" className="text-mLg font-bold text-secondary">
            {data?.title}
          </h1>
          <p data-testid="created_at_+_by" className="mt-2 font-bold text-primary">
            {dayjs(data?.createdAt).format(DateFormat.MMM_DD__YYYY)}
            {' - By ' + data?.author.name}
          </p>
          <p
            data-testid="article_desc"
            className="mt-5 border-b border-gray-400 pb-6 text-lg font-normal text-gray-700"
          >
            {data?.description}
          </p>
          <div className="flex justify-between">
            <div data-testid="article_content" className="w-full pt-2">
              {data?.subTitle && (
                <h3 className="mb-5 mt-2 font-bold text-primary">{data?.subTitle}</h3>
              )}
              <Editor
                initialValue={data?.content}
                disabled={true}
                init={{
                  disabled: true,
                  menubar: 'false',
                  plugin: 'false',
                  statusbar: false,
                  toolbar: false,
                  inline_boundaries: false,
                  content_style: 'body { font-family:Greycliff CF,serif; font-size:14px }'
                }}
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default SingleArticle;
