import { createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import {
  NotificationProps,
  NotificationQueryParamsProps,
  NotificationsState,
  UnreadNotificationsCountProps
} from './notifications.types';
import { apiSlice } from '../api/apiSlice';

const notificationsAdapter = createEntityAdapter<NotificationProps, EntityId>({
  selectId: (notification) => notification._id,
  sortComparer: (a, b) => b.createdAt?.localeCompare(a.createdAt)
});

const initialState: NotificationsState = {
  notificationsList: notificationsAdapter.getInitialState(),
  unreadNotificationsCount: 0,
  totalNotificationsCount: 0
};

const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      getNotifications: build.query<
        { data: NotificationProps[]; info: { totalCount: number } },
        { params: NotificationQueryParamsProps }
      >({
        query: ({ params }) => {
          let queryParams = params;
          if (params?.startCreateDate && !params?.endCreateDate) {
            queryParams = {
              ...params,
              createdAt: params.startCreateDate
            };
            delete queryParams.startCreateDate;
          }
          return {
            url: `/notifications`,
            params: queryParams
          };
        },
        transformResponse: (response: {
          data: NotificationProps[];
          info: { totalCount: number };
        }) => response,
        providesTags: (result) =>
          result
            ? [
                'Notification',
                ...result.data.map(({ _id }) => ({ type: 'Notification' as const, id: _id }))
              ]
            : ['Notification'],
        keepUnusedDataFor: 0
      }),
      getNotificationsUnreadCount: build.query<UnreadNotificationsCountProps, void>({
        query: () => '/notifications/unread-count',
        transformResponse: (response: { data: UnreadNotificationsCountProps }) => response.data
      }),
      clearNotification: build.mutation<string, string>({
        query: (id: string) => ({
          url: '/notifications/clear',
          method: 'PATCH',
          body: { notificationIds: [id] }
        }),
        transformResponse: (_response, _meta, arg) => arg,
        invalidatesTags: ['Notification']
      }),
      clearAllNotifications: build.mutation<void, void>({
        query: () => ({
          url: '/notifications/clear',
          method: 'PATCH',
          body: { clearAll: true }
        }),
        invalidatesTags: ['Notification']
      }),
      markSeenNotifications: build.mutation<void, void>({
        query: () => ({
          url: '/notifications/mark-seen',
          method: 'PATCH'
        }),
        invalidatesTags: ['Notification']
      })
    };
  }
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearAllNotifications: (state) => {
      notificationsAdapter.removeAll(state.notificationsList);
    },
    resetNotifications: (state) => {
      state.notificationsList = notificationsAdapter.getInitialState();
    },
    addNewNotification: (state, action: PayloadAction<NotificationProps>) => {
      state.unreadNotificationsCount += 1;
      notificationsAdapter.addOne(state.notificationsList, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        notificationsApiSlice.endpoints.getNotifications.matchFulfilled,
        (state, { payload }) => {
          notificationsAdapter.upsertMany(state.notificationsList, payload.data);
          state.totalNotificationsCount = payload.info.totalCount;
        }
      )
      .addMatcher(
        notificationsApiSlice.endpoints.getNotificationsUnreadCount.matchFulfilled,
        (state, { payload }) => {
          state.unreadNotificationsCount = payload.unreadNotificationsCount;
        }
      )
      .addMatcher(notificationsApiSlice.endpoints.markSeenNotifications.matchFulfilled, (state) => {
        state.unreadNotificationsCount = 0;
      })
      .addMatcher(
        notificationsApiSlice.endpoints.clearNotification.matchFulfilled,
        (state, { payload: id }) => {
          notificationsAdapter.removeOne(state.notificationsList, id);
        }
      )
      .addMatcher(notificationsApiSlice.endpoints.clearAllNotifications.matchFulfilled, (state) => {
        notificationsAdapter.removeAll(state.notificationsList);
      });
  }
});

export const notificationsSelectors = notificationsAdapter.getSelectors<RootState>(
  (state) => state.notifications.notificationsList
);

export const selectNotifications = (state: RootState) => state.notifications;

export const { clearAllNotifications, addNewNotification, resetNotifications } =
  notificationsSlice.actions;

export const {
  useLazyGetNotificationsQuery,
  useLazyGetNotificationsUnreadCountQuery,
  useClearNotificationMutation,
  useClearAllNotificationsMutation,
  useMarkSeenNotificationsMutation
} = notificationsApiSlice;

export default notificationsSlice.reducer;
