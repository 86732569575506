import { ColorTagProps } from '@thecvlb/design-system/lib/src/common/ColorTag/colorTag.types';
import { QueueTaskStatuses, TaskCategories } from 'enums/taskCategories';
import { ActionLogs } from 'store/history/history.types';
import { CreatedTaskResponseProps, UpdatedTaskResponseProps } from 'store/tasks/task.types';

export const isQueueGroupedTask = (data: CreatedTaskResponseProps) =>
  data?.task &&
  data.task.subCategory === 'QUEUE' &&
  data.task.category !== TaskCategories.Appointment &&
  data.task.patientStatus === QueueTaskStatuses.MIF_INCOMPLETE &&
  !data.task.assignedToInfo;

export const checkToRemoveQueueGroupedTask = (data: UpdatedTaskResponseProps) =>
  data.updatedData.patientStatus !== QueueTaskStatuses.MIF_INCOMPLETE ||
  data.updatedData.subCategory !== 'QUEUE' ||
  data.updatedData.assignedToInfo;

const setColor = (eventKey: string) => {
  switch (eventKey) {
    case 'appointment':
      return 'yellow';
    case 'message':
      return 'secondary';
    case 'pre-consult':
    case 'onboarding':
      return 'orange';
    case 'front-desk':
    case 'billing/account':
    case 'marked-urgent':
      return 'red';
    case 'review':
    case 'request':
    case 'request-order':
    case 'pa-request':
    case 'task':
      return 'violet';
    case 'post-consult':
      return 'primary';
    case 'result':
      return 'blue';
    case 'notification':
      return 'pink';
    case 'app/patient activity':
    case 'provider-activity':
    case 'wm':
    case 'renewal':
      return 'green';
    case 'async':
      return 'warm-gray';
    case 'billing/appointment':
    case 'patient-verification':
    case 'system':
    case 'automations':
    case 'maintenance':
    case 'other':
    case 'AI':
      return 'gray';
    default:
  }
};

export const getTaskEventHistoryColor = (event: ActionLogs) => {
  let eventKey = '';
  Object.entries(event).forEach(([key, val]) => {
    if (key === 'eventKey' && val) {
      eventKey = val as string;
    }
  });
  return setColor(eventKey) as ColorTagProps['color'];
};
