import dayjs from 'dayjs';
import { CustomerFeedbackProblemType } from 'enums/customerFeedback';
import { Role } from 'enums/role';
import { VideoCallIssueType } from 'enums/videoCall';
import { ReviewStatuses } from 'store/reviews/reviews.types';

export const TaskFilterData = [
  {
    label: 'All',
    value: { name: 'all', param: 'category' },
    color: 'bg-white',
    param: 'category',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Appointment',
    value: { name: 'Appointment', param: 'category' },
    color: 'bg-yellow',
    param: 'category',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appt • Async',
    value: { name: 'Appt • Async', param: 'category' },
    color: 'bg-yellow',
    param: 'category',
    roles: [Role.MA, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Queue',
    value: { name: 'Queue', param: 'category' },
    color: 'bg-orange-500',
    param: 'category',
    roles: [Role.AD, Role.MA]
  },
  {
    label: 'Onboard',
    value: { name: 'Onboarding', param: 'category' },
    color: 'bg-orange-400',
    param: 'category',
    roles: [Role.MA, Role.AD]
  },
  {
    label: 'Pre-consult',
    value: { name: 'Pre-consult', param: 'category' },
    color: 'bg-orange-400',
    param: 'category',
    roles: [Role.AD, Role.MA]
  },
  {
    label: 'Renewal',
    value: { name: 'Renewal', param: 'category' },
    color: 'bg-green-500',
    param: 'category',
    roles: [Role.NP, Role.PH, Role.AD, Role.MA, Role.CS]
  },
  {
    label: 'Async',
    value: { name: 'Async', param: 'category' },
    color: 'bg-warm-gray-500',
    param: 'category',
    roles: [Role.MA, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Post-consult',
    value: { name: 'Post-consult', param: 'category' },
    color: 'bg-primary',
    param: 'category',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Message',
    value: { name: 'Messages', param: 'category' },
    color: 'bg-secondary',
    param: 'category',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Request',
    value: { name: 'Requests', param: 'category' },
    color: 'bg-violet',
    param: 'category',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS]
  },
  {
    label: 'Request • PA',
    value: { name: 'Request • PA', param: 'category' },
    color: 'bg-violet',
    param: 'category',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS]
  },
  {
    label: 'Request • Order',
    value: { name: 'Request • Order', param: 'category' },
    color: 'bg-violet',
    param: 'category',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS]
  },
  {
    label: 'Review',
    value: { name: 'Review', param: 'category' },
    color: 'bg-blue',
    param: 'category',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD]
  },
  {
    label: 'Front desk',
    value: { name: 'Front desk', param: 'category' },
    color: 'bg-red',
    roles: [Role.CS, Role.AD, Role.MA, Role.PH]
  },
  {
    label: 'History',
    value: { name: 'History', param: 'category' },
    color: 'bg-gray-700',
    roles: [Role.PH, Role.NP, Role.MA, Role.AD, Role.CS]
  }
];

export const SentRequestFilterData = [
  {
    label: 'All',
    value: { name: 'all', param: 'category' },
    color: 'bg-white',
    param: 'category',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS]
  }
];

export const StatusFilterForPhysician = [
  { label: 'Active', value: 'active', param: 'status' },
  { label: 'Inactive', value: 'inactive', param: 'status' }
];

export const StatusFilterData = [
  { label: 'All', value: 'all', param: 'status' },
  ...StatusFilterForPhysician
];

export const StaffsFilterData = [
  { label: 'All', value: 'all', param: 'userType.name' },
  { value: Role.PH, label: 'Physician', param: 'userType.name' },
  { value: Role.NP, label: 'Nurse-practitioner', param: 'userType.name' },
  { value: Role.MA, label: 'Medical assistant', param: 'userType.name' },
  { value: Role.CS, label: 'Customer service', param: 'userType.name' },
  { value: Role.AD, label: 'Admin', param: 'userType.name' }
];

export const ProspectsFilterData = [
  { label: 'All', value: 'all', param: 'prospectStatus' },
  { label: 'New', value: 'New', param: 'prospectStatus' },
  { label: 'Called', value: 'Called', param: 'prospectStatus' },
  { label: 'Ordered', value: 'Ordered', param: 'prospectStatus' },
  { label: 'Not interested', value: 'Not interested', param: 'prospectStatus' }
];

export const PatientTriggersFilterData = [
  { label: 'All', value: 'all', param: 'triggerType' },
  { label: 'Webhook', value: 'Webhook', param: 'triggerType' },
  { label: 'Patient Message', value: 'Patient Message', param: 'triggerType' }
];

export const InternalTriggersFilterData = [
  { label: 'All', value: 'all', param: 'triggerType' },
  { label: 'Notification', value: 'Notification', param: 'triggerType' },
  { label: 'Task', value: 'Task', param: 'triggerType' }
];

export const ReportsFilterData = [
  {
    label: 'All',
    value: {
      name: 'all',
      param: [
        { name: 'all', param: 'startDate' },
        { name: 'all', param: 'endDate' }
      ]
    },
    param: 'reports'
  },
  {
    label: 'Last 7 Days',
    value: {
      name: 'last-7-days',
      param: [
        {
          name: new Date(dayjs().subtract(7, 'days').startOf('day').toString()).getTime(),
          param: 'startDate'
        },
        { name: new Date(dayjs().endOf('day').toString()).getTime(), param: 'endDate' }
      ]
    },
    param: 'reports'
  },
  {
    label: 'Last 30 Days',
    value: {
      name: 'last-30-days',
      param: [
        {
          name: new Date(dayjs().subtract(30, 'days').startOf('day').toString()).getTime(),
          param: 'startDate'
        },
        { name: new Date(dayjs().endOf('day').toString()).getTime(), param: 'endDate' }
      ]
    },
    param: 'reports'
  },
  {
    label: 'Custom',
    value: {
      name: 'custom',
      param: []
    },
    param: 'reports'
  }
];

interface ReviewsCountsProps {
  totalCount: number;
  newCount: number;
  publishedCount: number;
  hiddenCount: number;
}
export const getReviewsFiltersData = (counts: ReviewsCountsProps) => [
  { label: 'All', value: 'all', param: 'status', count: counts.totalCount },
  { label: 'New', value: ReviewStatuses.New, param: 'status', count: counts.newCount },
  {
    label: 'Published',
    value: ReviewStatuses.Published,
    param: 'status',
    count: counts.publishedCount
  },
  { label: 'Hidden', value: ReviewStatuses.Hidden, param: 'status', count: counts.hiddenCount }
];

export const StaffFeedbackFilterData = [
  { label: 'All', value: 'all', param: 'issueType' },
  { label: 'Patient issue', value: VideoCallIssueType.PatientIssue, param: 'issueType' },
  { label: 'Provider issue', value: VideoCallIssueType.ProviderIssue, param: 'issueType' },
  { label: 'Both', value: VideoCallIssueType.Both, param: 'issueType' }
];

export const CustomFeedbackFilterData = [
  { label: 'All', value: 'all', param: 'problemType' },
  {
    label: 'Problem with my provider',
    value: CustomerFeedbackProblemType.MyProvider,
    param: 'problemType'
  },
  {
    label: 'Problem using the app',
    value: CustomerFeedbackProblemType.UsingApp,
    param: 'problemType'
  }
];

// TODO: Convert arrays values to Enum
export const AllFiltersParams = [
  'category',
  'patientNames',
  'searchKey',
  'doctorName',
  'dueDate',
  'endDueDate',
  'assignedToNames',
  'startCreateDate',
  'endCreateDate',
  'createdAt',
  'futureTasksRange',
  'startDueDate',
  'status',
  'pharmacyType',
  'orderId',
  'patient',
  'orderStatus',
  'doctor',
  'patient',
  'email',
  'membership',
  'uniqueId',
  'author.name',
  'taskType',
  'criterionField',
  'alertAmount',
  'alertUnit',
  'name',
  'audience',
  'displayName',
  'length',
  'requestType',
  'routeTo',
  'message',
  'shortCode',
  'activeStates',
  'onboardingStatuses',
  'lastLogin',
  'userType.name',
  '_id',
  'color',
  'payAmount',
  'payType',
  'staff',
  'startDate',
  'audience.shortCode',
  'userType',
  'triggerUnit',
  'occurenceOrder',
  'title',
  'author',
  'triggerType',
  'triggerTime.timeInHours',
  'triggerTime.occurenceOrder',
  'triggerHours',
  'assignedTo',
  'due',
  'patientName',
  'phone',
  'planId',
  'appointmentType',
  'state',
  'prospectStatus',
  'eventKey',
  'eventType',
  'endDate',
  'urgencyTask',
  'rating',
  'providerName',
  'patientStatuses',
  'dynamicStatus',
  'problemType',
  'tags',
  'issueType',
  'issueDescriptions',
  'patientTags',
  'taskTags',
  'patientStates',
  'states',
  'escalated',
  'audiences',
  'teams'
];
