import { ALERT_UNIT_OPTIONS } from 'constants/alerts';
import { PATIENT_TRIGGER_OPTIONS } from 'constants/automation';
import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues } from './patientAutomationsFiltersForm.settings';
import { PatientAutomationsFiltersFormProps } from './patientAutomationsFiltersForm.types';

const PatientAutomationsFiltersForm = () => {
  const { control, watch, handleSubmit, reset } = useForm<PatientAutomationsFiltersFormProps>({
    reValidateMode: 'onChange'
  });
  const [formData, setFormData] = useTableFiltersData('patientAutomations', defaultValues, reset);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onSubmit = (data: PatientAutomationsFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      name: encodeURIComponent(data?.name),
      _id: encodeURIComponent(data?._id),
      ...(data?.timeInHours && { 'triggerTime.timeInHours': data.timeInHours }),
      triggerType: data?.triggerType?.value,
      ...(data?.triggerUnit && {
        triggerUnit: data?.triggerUnit?.value.split('.')[0],
        'triggerTime.occurenceOrder': data?.triggerUnit?.value.split('.')[1]
      }),
      status: data?.status?.value
    };
    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form className="w-[600px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid max-w-screen-sm grid-cols-2">
          <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
            <InputField
              name="name"
              label="Name"
              type="text"
              placeholder="Enter name"
              control={control}
              value={formData?.name}
            />
            <InputField
              name="_id"
              label="Unique ID"
              type="text"
              placeholder="Enter unique ID"
              control={control}
              value={formData?._id}
            />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={PATIENT_TRIGGER_OPTIONS}
              placeholder="Select type..."
              label="Type"
              name="triggerType"
              defaultValue={formData?.triggerType}
            />
          </div>
          <div className="flex flex-col gap-4 pl-7">
            <InputField
              name="timeInHours"
              label="Timing (amount)"
              type="number"
              placeholder="Enter ID"
              control={control}
              value={formData?.timeInHours}
            />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={ALERT_UNIT_OPTIONS}
              placeholder="Select unit"
              label="Units"
              name="triggerUnit"
              defaultValue={formData?.triggerUnit}
            />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={STATUS_OPTIONS}
              placeholder="Select status..."
              label="Status"
              name="status"
              defaultValue={formData?.status}
            />
          </div>
        </div>
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default PatientAutomationsFiltersForm;
