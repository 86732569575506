import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import {
  getProfileImageWrapperClassName,
  parseString
} from 'components/common/Chat/Message/message.settings';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { SystemMessageProps } from './systemMessage.types';

const SystemMessage = ({
  description,
  date,
  seenByPatient,
  self,
  author,
  ref
}: SystemMessageProps) => {
  const containerClasses = classNames('flex flex-col gap-3 mb-1 pb-3');

  const messageWrapperClasses = getProfileImageWrapperClassName(self, false);
  const seenStatusClasses = classNames(
    'w-full text-mXs text-gray-500',
    self ? 'text-right' : 'text-left'
  );
  const wrapperMessageBlockClasses = classNames('flex flex-col gap-3', { 'items-end': self });
  const nameClasses = classNames('flex text-gray items-center text-xs text-left -mt-2 mb-2', {
    'ml-auto flex-row-reverse': self,
    'mr-auto': !self
  });

  const messageClasses = classNames(
    'w-fit rounded-2xl py-3 px-5 border border-solid border-gray-300',
    {
      'border border-solid border-gray-300': !self,
      'rounded-br-none': self,
      'rounded-bl-none': !self
    }
  );

  return (
    <div className={containerClasses} ref={ref}>
      <div data-testid="message" className={messageWrapperClasses}>
        <div className="mb-[26px] mr-3 size-12 min-w-12 rounded-full bg-secondary-100 p-2">
          <Common.Logo name="cross" className="size-full" />
        </div>

        <div className={wrapperMessageBlockClasses}>
          {description && (
            <div data-testid="msg_container" className={messageClasses}>
              <div
                data-testid="msg_content"
                className="whitespace-pre-line break-normal text-base text-gray-700"
              >
                {parseString(description)}
              </div>
              <p className="text-sm text-gray">Lab follow-up request • Created by {author}</p>
            </div>
          )}
          <span data-testid="message_datestamp" className={nameClasses}>
            {!self ? (
              `System message • ${dayjs(date).format(DateFormat.h_mm_a)}`
            ) : (
              <span className={seenStatusClasses}>
                {seenByPatient ? 'Seen • ' : 'Sent • '} {dayjs(date).format(DateFormat.h_mm_a)}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SystemMessage;
