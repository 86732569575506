import type {
  AppointmentType,
  AppointmentTypesQueryParams
} from 'store/appointments/appointments.types';

import { apiSlice } from '../api/apiSlice';

const appointmentTypesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAppointmentTypes: build.query<
      { appointmentTypes: AppointmentType[]; count: number },
      { params: AppointmentTypesQueryParams }
    >({
      query: ({ params }) => ({ url: `/appointment-types`, params }),
      transformResponse: (response: { data: AppointmentType[]; info: { totalCount: number } }) => ({
        appointmentTypes: response.data,
        count: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'AppointmentType',
              { type: 'AppointmentType', id: 'LIST' },
              ...result.appointmentTypes.map(({ _id }) => ({
                type: 'AppointmentType' as const,
                id: _id
              }))
            ]
          : ['AppointmentType']
    }),
    getAppointmentTypeById: build.query<AppointmentType, { id: string }>({
      query: ({ id }) => `/appointment-types/${id}`,
      transformResponse: (response: { data: AppointmentType }) => response.data,
      providesTags: (_result, _error, arg) => [{ type: 'AppointmentType', id: arg.id }]
    }),
    deleteAppointmentType: build.mutation<{ message: string }, string>({
      query: (appointmentTypeId) => ({
        url: `/appointment-types/${appointmentTypeId}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: [{ type: 'AppointmentType', id: 'LIST' }]
    }),
    createAppointmentType: build.mutation({
      query: (body) => ({
        url: `appointment-types`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: AppointmentType; message: string }) => response,
      invalidatesTags: ['AppointmentType']
    }),
    editAppointmentType: build.mutation({
      query: ({ appointmentTypeId, body }) => ({
        url: `appointment-types/${appointmentTypeId}`,
        method: 'PUT',
        body
      }),
      transformResponse: (response: { data: AppointmentType; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [
        { type: 'AppointmentType', id: arg.appointmentTypeId }
      ]
    })
  })
});

export const {
  useGetAppointmentTypesQuery,
  useGetAppointmentTypeByIdQuery,
  useDeleteAppointmentTypeMutation,
  useCreateAppointmentTypeMutation,
  useEditAppointmentTypeMutation
} = appointmentTypesApi;
