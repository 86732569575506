import { DocumentsDataItemProps } from 'components/patient/Documents/documents.types';
import { useAppSelector } from 'hooks/redux';
import { useAppDispatch } from 'hooks/redux';
import { selectTask } from 'store/tasks/tasksSlice';
import { setTaskDetailsDocuments } from 'store/tasks/tasksSlice';

export const useUpdatePatientDocument = () => {
  const { taskDetails } = useAppSelector(selectTask);
  const dispatch = useAppDispatch();

  const removeDocumentFromTaskDetails = (documentId: string) => {
    const patientDocuments = taskDetails?.patientDocuments || [];
    const updatedDocuments = patientDocuments.filter((doc) => doc._id !== documentId) || [];
    dispatch(setTaskDetailsDocuments(updatedDocuments));
  };

  const updateTaskDetailsDocument = (document: DocumentsDataItemProps) => {
    const patientDocuments = taskDetails?.patientDocuments || [];
    const currentDocumentIdx = patientDocuments.findIndex((doc) => doc._id === document._id);

    if (currentDocumentIdx !== -1) {
      const newArray = Array.from(patientDocuments);
      newArray[currentDocumentIdx] = document;
      dispatch(setTaskDetailsDocuments(newArray));
    }
  };

  return { removeDocumentFromTaskDetails, updateTaskDetailsDocument };
};
