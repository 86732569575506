import AssignedToTask from 'components/modals/AssignedToTask';
import { PathName } from 'enums/pathName';
import { Role } from 'enums/role';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useMatch, useSearchParams } from 'react-router';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { open } from 'store/slidingInPane/slidingInPaneSlice';
import { selectUser } from 'store/user/userSlice';

type HandleOpenTaskProps = {
  taskId?: string;
  assignToId?: string;
  assignToName?: string;
  shouldClosePrevModal?: boolean;
  shouldExcludeForAdmin?: boolean;
  shouldUseUrlOnlyInTaskPage?: boolean;
  isStaffNote?: boolean;
};

/**
 * @description How it's working.
 * @link https://github.com/thecvlb/care-physician-portal-web/wiki/Open-Task-Modal
 */

const useOpenTask = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userType, _id: userId } = useAppSelector(selectUser);
  const isTasksPage = !!useMatch(PathName.Tasks);

  const handleOpenTask = ({
    taskId,
    assignToId,
    assignToName,
    shouldClosePrevModal = false,
    shouldExcludeForAdmin = false,
    shouldUseUrlOnlyInTaskPage = false,
    isStaffNote
  }: HandleOpenTaskProps) => {
    const isAdmin = userType?.name === Role.AD;
    // Condition that checks if we need to add an id as a parameter in the URL
    const shouldOpenWithoutUrl = shouldUseUrlOnlyInTaskPage && !isTasksPage;

    // Condition that checks if it's not our task
    const isNotUserTask = assignToId && assignToId !== userId;

    // Condition that checks if it's not our task and if there's no exception for the administrator
    const isNotAssignToTask = taskId && isNotUserTask && (shouldExcludeForAdmin ? !isAdmin : true);

    if (isStaffNote) {
      searchParams.set('messageType', 'Staff notes');
    }

    // If we want to close the previous modal window
    if (shouldClosePrevModal) {
      dispatch(closeModal());
    }

    if (isNotAssignToTask) {
      // If we have a doctor assigned to the task and it's not us
      // And if there's no exception for the administrator
      // We warn the user that it's not their task
      dispatch(
        openModal({
          size: 'sm',
          hideClose: true,
          modalContent: (
            <AssignedToTask
              taskId={taskId}
              assignToName={assignToName}
              openWithoutUrl={shouldOpenWithoutUrl}
              isStaffNote={isStaffNote}
            />
          )
        })
      );
    } else if (taskId && !shouldOpenWithoutUrl) {
      // Under the condition that we want to open anywhere or if we want to open only on the tasks page
      // We open using a parameter in the URL
      searchParams.set('taskModalOpenID', taskId);
      setSearchParams(searchParams);
    } else if (taskId && shouldOpenWithoutUrl) {
      // Otherwise, if we want it to open only on the tasks page
      // And if it's not the tasks page, then we simply open the task.
      // Additionally, we remove the taskModalOpenID parameter because the useOpenTaskModalWatcher hook will rewrite the ID from the URL
      // In this way, we won't be able to open the task
      searchParams.delete('taskModalOpenID');
      setSearchParams(searchParams);
      dispatch(open({ taskId }));
    }
  };

  return { handleOpenTask };
};

export default useOpenTask;
