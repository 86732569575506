import {
  AppointmentCategoriesValue,
  CategoryFilterAppointmentValues,
  CategoryFilterValues
} from 'constants/taskFilter';

import { Options } from 'components/patient/History/history.types';
import { UseFormSetValue } from 'react-hook-form';

import { TasksFiltersFormProps } from './tasksFiltersForm.types';

export const defaultValues: TasksFiltersFormProps = {
  patientNames: [],
  searchKey: '',
  doctorName: { label: '', value: '', id: '' },
  localTz: '',
  assignedToNames: [],
  category: [],
  startCreateDate: '',
  endCreateDate: '',
  createdAt: '',
  status: '',
  futureTasksRange: { value: '', label: '' },
  startDueDate: '',
  endDueDate: '',
  patientStatuses: '',
  dynamicStatus: '',
  onboardingStatuses: [],
  patientTags: [],
  taskTags: [],
  patientStates: [],
  escalated: false,
  audiences: [],
  teams: []
};

export const handleChangeCategory = (
  setValue: UseFormSetValue<TasksFiltersFormProps>,
  tags: Options[],
  categories: string | string[]
) => {
  const name = 'category';
  const updatedCategoryList = typeof categories === 'string' ? [categories] : categories;

  // Check which Appointment tag is selected in the list of selected task tags
  const appointmentTagsInTags = tags
    .map((tag) => tag.value)
    .filter((item) => AppointmentCategoriesValue.includes(item as CategoryFilterAppointmentValues));

  // Check which Appointment tag is selected in the list of selected categories
  const appointmentTagsInCategories = updatedCategoryList.filter((item) =>
    AppointmentCategoriesValue.includes(item as CategoryFilterAppointmentValues)
  );

  /**
   * If these lists are not the same it means that there have been some changes in the selected tags and category list needs to be updated
   * We select category which are not in the AppointmentCategoriesValue list (all other category)
   * and in the list of selected tags (all appointment tags)
   */
  const newCategoryList =
    appointmentTagsInTags.length !== appointmentTagsInCategories.length
      ? [...new Set([...updatedCategoryList, ...appointmentTagsInTags])].filter(
          (item) =>
            !AppointmentCategoriesValue.includes(item as CategoryFilterAppointmentValues) ||
            appointmentTagsInTags.includes(item)
        )
      : updatedCategoryList;

  /**
   * Update the list of categories
   * If the list is empty, we need to pass 'all' to activate the trigger in the category component correctly
   * But before that, check if the category list does not contain the “Appointment” category
   * And the list of selected tags contains the Appointment tag
   * If so, remove the Appointment category from the list of categories
   */
  const finalCategoryList =
    newCategoryList.length === 0
      ? ['all']
      : appointmentTagsInTags.length > 0 &&
          newCategoryList.includes(CategoryFilterValues.Appointment)
        ? newCategoryList.filter((item) => item !== CategoryFilterValues.Appointment)
        : newCategoryList;

  setValue(name, finalCategoryList);
};
