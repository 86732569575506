import { StatusFilterData, StatusFilterForPhysician } from 'constants/filters';

import { useEffect, useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/PatientTable/columns';
import { TagTypes } from 'enums/tagTypes';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppSelector } from 'hooks/redux';
import { PatientsQueryParams } from 'models/patients.types';
import { useNavigate, useSearchParams } from 'react-router';
import { useGetPatientsQuery, useGetTagsListQuery } from 'store/patients/patientsSlice';
import { selectUser } from 'store/user/userSlice';
import { parseQueryParams } from 'utils/router';

import { onClickPatientRow } from './patients.settings';

const Patients = () => {
  const { displayName, userType } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: PatientsQueryParams = useMemo(() => {
    return parseQueryParams(searchParams);
  }, [searchParams]);

  const { data, isFetching, isUninitialized } = useGetPatientsQuery({ params: queryParams });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.patients
  });
  const { data: response } = useGetTagsListQuery(
    { tagTypes: [TagTypes.Both, TagTypes.Manual, TagTypes.System] },
    {
      selectFromResult: (result) => result
    }
  );
  const patientTags = useMemo(
    () => response?.data.map((tag) => ({ label: tag.name, value: tag._id })),
    [response?.data]
  );
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  const isPhysician = userType?.shortCode === 'PH';
  const statusFilterData = isPhysician ? StatusFilterForPhysician : StatusFilterData;
  const additionalFilterData = isPhysician
    ? [
        { label: 'My patients', value: displayName, param: 'doctor' },
        { label: 'All', value: 'all', param: 'doctor' }
      ]
    : [];

  useEffect(() => {
    if (isPhysician && !searchParams.has('status')) {
      searchParams.set('doctor', displayName);
      setSearchParams(searchParams);
    }
    // NOTE: because we need set search params only on mount phase
  }, []);

  return (
    <>
      <div
        data-testid="patients_filter_section"
        className="relative my-5 flex items-center justify-between"
      >
        <div className="flex gap-2">
          {isPhysician && <FilterButtons filterData={additionalFilterData} queryParam={'doctor'} />}
          <FilterButtons filterData={statusFilterData} queryParam={'status'} />
        </div>
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags tagsOptions={patientTags} />
      <Card>
        <BasicTable
          table={table}
          totalCount={data?.totalCount || 0}
          isFetching={isFetching}
          onClickRow={(row) => onClickPatientRow(row as { [key: string]: unknown }, navigate)}
        />
      </Card>
    </>
  );
};

export default Patients;
