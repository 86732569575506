import React, { useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { notifySuccess } from 'components/common/Toast/Toast';
import DocumentInfo from 'components/patient/Documents/Document/DocumentInfo';
import { useUpdatePatientDocument } from 'hooks/tasks/useUpdatePatientDocument/useUpdatePatientDocument';
import { Option } from 'models/form.types';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/modalSlice';
import { useGetDocumentTagsQuery, useUpdateDocumentMutation } from 'store/patients/patientsSlice';

import type { AddDocumentTagsTypes } from './addDocumentTags.types';

const AddDocumentTags: React.FC<AddDocumentTagsTypes> = ({
  document,
  patientId,
  shouldUpdateTask
}) => {
  const { updateTaskDetailsDocument } = useUpdatePatientDocument();
  const { data: documentTags } = useGetDocumentTagsQuery({});
  const [updateDocument, { isLoading }] = useUpdateDocumentMutation();
  const { fileName, createdAt, uploadedBy, isUploadedByCurrentUser, tags, _id } = document;

  const dispatch = useDispatch();
  const [selectedTag, setSelectedTags] = useState<Option[]>([]);

  const tagOptions = useMemo(
    () => documentTags?.data?.tags?.map((team) => ({ label: team.name, value: team._id })),
    [documentTags]
  );

  const handleUpdateTags = () => {
    updateDocument({ patientId, documentId: _id, tags: selectedTag.map((tag) => tag.value) })
      .unwrap()
      .then((result) => {
        notifySuccess(result.message);
        shouldUpdateTask && updateTaskDetailsDocument(result.data);
        dispatch(closeModal());
      });
  };

  useEffect(() => {
    if (tags) {
      setSelectedTags(tags?.map((tag) => ({ label: tag.name, value: tag._id })));
    }
  }, [tags]);

  return (
    <div className="p-6">
      <h3 className="text-xl font-bold">
        {tags && tags.length > 0 ? 'Edit tags to' : 'Add tags to'}
      </h3>

      <div className="mb-6">
        <DocumentInfo
          title={fileName}
          date={createdAt}
          uploadedByName={uploadedBy}
          byCurrentUser={isUploadedByCurrentUser}
          tags={tags}
        />

        <Common.MultiSelect
          label="Tags"
          placeholder="Select tags"
          size="md"
          value={selectedTag}
          onChange={(value: unknown) => setSelectedTags(value as Option[])}
          options={tagOptions}
        />
      </div>

      <div className="flex flex-col-reverse gap-3 md:flex-row">
        <Common.Button
          className="grow"
          fullWidthOnMobile
          onClick={() => dispatch(closeModal())}
          color="white-alt"
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button
          className="grow"
          fullWidthOnMobile
          onClick={handleUpdateTags}
          disabled={isLoading}
          color="blue"
          size="sm"
        >
          Save
        </Common.Button>
      </div>
    </div>
  );
};

export default AddDocumentTags;
