import { AppointmentItemProps } from 'store/appointments/appointments.types';

export enum Provider {
  primary = 'primary',
  any = 'any'
}

export type ProviderType = Provider.any | Provider.primary;

export interface DateAndTimeProps {
  upcomingAppointmentsList: AppointmentItemProps[];
  pastAppointmentsList: AppointmentItemProps[];
  onSelect: () => void;
  handleBack: () => void;
  isAdHoc: boolean;
  toggleIsAdHoc: (value: boolean) => void;
  initialDate?: string;
  isLoading?: boolean;
  isReschedule?: boolean;
  appointmentId?: string;
}

export interface SlotData {
  displayName: string;
  doctorId: string;
  label: string;
  value: string;
  valueEnd: string;
}
