import { apiSlice } from 'store/api/apiSlice';

const versionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMinAppVersion: build.query<{ physician: { min: string } }, void>({
      query: () => ({
        url: `min-app-versions`
      })
    })
  })
});

export const { useLazyGetMinAppVersionQuery } = versionsApiSlice;
