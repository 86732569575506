export interface StaffAnnouncementProps {
  _id: string;
  name: string;
  bannerType: AnnouncementType;
  author?: AnnouncementAuthor;
  audience: AnnouncementAudience[];
  status: AnnouncementStatus;
  data: BannerData;
  startAt: string;
  expiresAt: string;
  createdAt: string;
  modifiedBy?: string;
  createdBy?: string;
  modifiedAt: string;
  deletedAt?: string;
}

export interface AnnouncementAuthor {
  userId: string;
  name: string;
}

export interface AnnouncementAudience {
  name: string;
  shortCode: string;
  displayName?: string;
}

export enum AnnouncementStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
  DRAFT = 'draft'
}

export interface Modal {
  body: string;
  button?: {
    label: string;
    linkTo: string;
  };
}
type BannerData = Modal;

export interface StaffAnnouncementsQueryParams {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  searchKey?: string;
  name?: string;
  uniqueId?: string;
  status?: string;
  audience?: string[];
}

export enum AnnouncementType {
  Banner = 'banner',
  ModalStandard = 'modal-standard',
  ModalRequiredReading = 'modal-required-reading'
}
