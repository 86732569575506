import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { twMerge } from 'tailwind-merge';

import { ViewAsButtonProps } from './viewAsButton.types';

const ViewAsButton: React.FC<ViewAsButtonProps> = ({
  type,
  onClick,
  disabled,
  className,
  onMouseOver,
  onMouseLeave
}) => {
  const buttonClasses = twMerge(
    'flex items-center gap-1 rounded-lg bg-white px-4 py-[7.5px] text-sm font-bold text-gray-700 ring-1 ring-gray-200 transition-all hover:ring-gray-400 disabled:bg-gray-200 disabled:text-gray',
    className
  );

  return (
    <button
      data-testid={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {type === 'case' && (
        <>
          <Common.Icon name="list-view" />
          Details
        </>
      )}
      {type === 'emr' && (
        <>
          <Common.Icon name="view-emr" />
          EMR
        </>
      )}
      {type === 'patient' && (
        <>
          <Common.Icon name="log-out" />
          Sign in as patient
        </>
      )}
      {type === 'document' && (
        <>
          <Common.Icon name="document" />
          View PDF
        </>
      )}
      {type === 'info' && (
        <>
          <Common.Icon name="info" />
          Description
        </>
      )}
    </button>
  );
};

export default ViewAsButton;
