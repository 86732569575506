import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import type { RootState } from 'store';
import { apiSlice } from 'store/api/apiSlice';

import {
  initialState,
  StaffFeedbackListProps,
  StaffFeedbackQueryParams
} from './staffFeedback.types';

const staffFeedbackApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStaffFeedbacks: build.query<StaffFeedbackListProps, { params: StaffFeedbackQueryParams }>({
      query: ({ params }) => {
        const paramsString = queryString.stringify(params, { arrayFormat: 'bracket' });
        return { url: `/admin/call-quality-feedbacks?${paramsString}` };
      },
      transformResponse: (response: StaffFeedbackListProps) => ({
        data: response.data,
        info: response.info
      })
    })
  })
});

export const { useLazyGetStaffFeedbacksQuery } = staffFeedbackApi;

const staffFeedbackSlice = createSlice({
  name: 'staffFeedback',
  initialState: initialState,
  reducers: {
    setStaffFeedback: (state, action) => {
      state.data = action.payload.data;
      state.info = action.payload.info;
    },
    resetStaffFeedback: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      staffFeedbackApi.endpoints.getStaffFeedbacks.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.info = action.payload.info;
        }
      }
    );
  }
});

export const selectStaffFeedback = (state: RootState) => state.staffFeedback;

export default staffFeedbackSlice.reducer;

export const { setStaffFeedback, resetStaffFeedback } = staffFeedbackSlice.actions;
