import { EditTeamData, Team } from './teams.types';
import { apiSlice } from '../api/apiSlice';
import { DefaultQueryParams } from '../patients/patients.types';

const teamsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTeams: build.query<{ teams: Team[]; totalCount: number }, { params?: DefaultQueryParams }>({
      query: ({ params }) => ({ url: `/teams`, params }),
      transformResponse: (response: { data: Team[]; info: { totalCount: number } }) => ({
        teams: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: ['Teams']
    }),
    getTeam: build.query<Team, { teamId: string }>({
      query: ({ teamId }) => `/admin/teams/${teamId}`,
      transformResponse: (response: { data: Team }) => response.data
    }),
    createTeam: build.mutation({
      query: ({ team }: { team: EditTeamData }) => ({
        url: `/admin/teams`,
        method: 'POST',
        body: team
      }),
      transformResponse: (response: { data: Team; message: string }) => response,
      invalidatesTags: ['Teams']
    }),
    updateTeam: build.mutation({
      query: ({ teamId, team }: { teamId: string; team: EditTeamData }) => ({
        url: `/admin/teams/${teamId}`,
        method: 'PATCH',
        body: team
      }),
      transformResponse: (response: { data: Team; message: string }) => response,
      invalidatesTags: ['Teams']
    }),
    deleteTeam: build.mutation({
      query: ({ id }: { id: string }) => ({
        url: `/admin/teams/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['Teams']
    })
  })
});

export const {
  useGetTeamsQuery,
  useLazyGetTeamsQuery,
  useLazyGetTeamQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation
} = teamsApiSlice;
