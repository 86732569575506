import { pointConfig, tooltip as tooltipConf } from 'constants/weightChart';

import { Dispatch, MutableRefObject, RefObject, SetStateAction } from 'react';

import Pencil from 'assets/icons/pane/Pencil.svg';
import { Chart, ChartType, ChartTypeRegistry, TooltipItem, TooltipModel } from 'chart.js';
import dayjs, { ManipulateType } from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { HealthMetricItem } from 'store/patientChart/patientChart.types';

import { ChartDatePoint } from './weightChart.types';

interface ContextProps {
  chart: Chart<keyof ChartTypeRegistry>;
  tooltip: TooltipModel<keyof ChartTypeRegistry>;
}

export const getRangeFormat = (labels: number[], minX?: number, maxX?: number) => {
  let isRangeWide: boolean;
  if (!minX || !maxX) {
    isRangeWide = dayjs(labels[labels.length - 1]).diff(labels[0], 'days') > 180;
  } else {
    isRangeWide = dayjs(maxX).diff(minX, 'days') > 180;
  }

  return isRangeWide ? DateFormat.MMM_YY : DateFormat.MMM_D;
};

export const getChartData = (
  selectedTab: string,
  labels: number[],
  current: ChartDatePoint[],
  targetData: { date: number; value: number }[]
) => {
  let constraintDuration: ManipulateType | undefined, minX, maxX;

  switch (selectedTab) {
    case 'Week':
      constraintDuration = 'week';
      break;
    case 'Month':
      constraintDuration = 'month';
      break;
    case 'Year':
      constraintDuration = 'year';
      break;
  }

  if (!constraintDuration || !current.length) {
    return { labels, maxX, minX, targetData };
  }

  const firstMeasurementDate = current[0].date;
  const lastMeasurementDate = current[current.length - 1].date;

  if (dayjs(lastMeasurementDate).subtract(1, constraintDuration).isBefore(firstMeasurementDate)) {
    minX = dayjs(firstMeasurementDate).valueOf();
    maxX = dayjs(firstMeasurementDate).add(1, constraintDuration).valueOf();
  } else {
    minX = dayjs(lastMeasurementDate).subtract(1, constraintDuration).valueOf();
    maxX = dayjs(lastMeasurementDate).valueOf();
  }
  return { maxX, minX };
};

const getOrCreateTooltip = (chart: ContextProps['chart'], onChangeTarget?: () => void) => {
  let tooltipEl: HTMLElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('DIV');
    tooltipEl.id = 'tooltip-external';
    tooltipEl.classList.add(
      'absolute',
      'rounded-lg',
      'bg-white',
      'text-gray-700',
      'border-gray-200',
      'border',
      'px-2',
      'py-1',
      'flex',
      'gap-3',
      'items-center',
      'shadow',
      'leading-none',
      'w-max',
      'opacity-0',
      'transition',
      'duration-500',
      'ease-in-out'
    );
    const tooltipUl = document.createElement('UL');
    const tooltipButton = document.createElement('BUTTON');
    const tooltipImg = document.createElement('IMG');

    tooltipButton.addEventListener('click', () => onChangeTarget?.());
    tooltipButton.classList.add('rounded-full', 'p-1.5', 'bg-gray-100', 'flex-none');

    tooltipImg.classList.add('w-2.5', 'h-2.5');
    tooltipImg.setAttribute('src', Pencil);
    tooltipButton.appendChild(tooltipImg);

    tooltipEl.appendChild(tooltipUl);
    tooltipEl.appendChild(tooltipButton);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }
  return tooltipEl;
};

export const externalTooltipHandler = (context: ContextProps, onChangeTarget?: () => void) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, onChangeTarget);

  if (tooltip.body) {
    tooltip.opacity = 0;
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tooltipLI = document.createElement('LI');
    titleLines.forEach((title: string) => {
      tooltipEl.appendChild(tooltipLI);
      const tooltipSPAN = document.createElement('SPAN');
      tooltipSPAN.classList.add('text-mXs');

      tooltipLI.appendChild(tooltipSPAN);
      const tooltipTitle = document.createTextNode(title);
      tooltipSPAN.appendChild(tooltipTitle);
    });

    const tooltipBodyP = document.createElement('P');
    tooltipBodyP.classList.add('text-mSm', 'font-bold');

    bodyLines.forEach((body) =>
      body.forEach((text) => {
        const textLabel = document.createTextNode(text);
        tooltipBodyP.appendChild(textLabel);
      })
    );

    const ULnode = tooltipEl.querySelector('ul');

    while (ULnode?.firstChild) {
      ULnode.firstChild.remove();
    }

    ULnode?.appendChild(tooltipLI);
    tooltipLI.appendChild(tooltipBodyP);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    setTimeout(() => {
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY - 47 + 'px';
      tooltipEl.style.opacity = String(1);
    }, 150);
  }
};

export const generateChart = (
  chartRef: RefObject<HTMLCanvasElement | null>,
  currentData: { date: number; value: number }[],
  targetData: { date: number; value: number }[],
  chartType: 'current' | 'target',
  chartId: MutableRefObject<string | null>,
  setChart: Dispatch<
    SetStateAction<
      Chart<keyof ChartTypeRegistry, { date: number; value: number }[], unknown> | undefined
    >
  >
) => {
  if (chartRef.current === null || chartId.current !== null) {
    return;
  }

  const isCurrent = chartType === 'current';
  const ctx = (chartRef.current as HTMLCanvasElement).getContext?.('2d');
  const gradientStroke = isCurrent ? ctx?.createLinearGradient(350, 0, 1, 0) : null;
  if (isCurrent) {
    gradientStroke?.addColorStop(0, '#00698B');
    gradientStroke?.addColorStop(1, '#19D1CB');
  }
  let completeTooltip = false;

  const chart = new Chart(chartRef.current as HTMLCanvasElement, {
    data: isCurrent
      ? {
          datasets: [
            {
              ...pointConfig,
              data: currentData,
              ...(gradientStroke && {
                pointBackgroundColor: gradientStroke,
                borderColor: gradientStroke
              })
            },
            {
              data: targetData,
              pointHoverRadius: 0,
              pointRadius: 0,
              showLine: false
            }
          ]
        }
      : {
          datasets: [
            {
              data: currentData,
              pointRadius: 0,
              showLine: false
            },
            {
              ...pointConfig,
              borderCapStyle: 'round',
              borderColor: '#C4CACC',
              borderDash: [10, 9],
              data: targetData,
              pointBackgroundColor: '#C4CACC',
              pointBorderColor: '#C4CACC',
              pointHoverBackgroundColor: '#00B167',
              pointHoverBorderColor: '#00B167'
            }
          ]
        },
    options: {
      animation: {
        onComplete: () => {
          try {
            if (!completeTooltip && chart.canvas) {
              const chartDatesetMetoInfo = !!chart?.getDatasetMeta?.(0);
              if (!chartDatesetMetoInfo) return;

              const segment = chart.getActiveElements?.()?.[0];
              const isSegmentElementHasValue = segment?.element?.hasValue?.();
              if (segment?.element && isSegmentElementHasValue) {
                chart.tooltip?.setActiveElements?.(
                  [{ ...{ datasetIndex: 0, index: 0 }, ...segment }],
                  {
                    x: segment.element?.x ?? 0,
                    y: segment.element?.y ?? 0
                  }
                );
                chart.update?.();
                completeTooltip = true;
              }
            }
          } catch (error) {
            console.error('Chart was crashed with error: ', error);
          }
        }
      },
      clip: false,
      events: isCurrent ? ['mousemove'] : [],
      interaction: { intersect: false },
      parsing: { xAxisKey: 'date', yAxisKey: 'value' },
      plugins: {
        legend: { display: false },
        tooltip: {
          ...tooltipConf,
          ...(isCurrent
            ? {
                ...tooltipConf,
                backgroundColor: '#388FAB',
                bodyColor: '#ffffff',
                // borderColor: '#00698B',
                filter: (tooltipItem: TooltipItem<ChartType>) => tooltipItem.datasetIndex === 0,
                titleColor: '#ffffff',
                yAlign: 'bottom'
              }
            : {
                backgroundColor: 'transparent',
                bodyColor: 'transparent',
                borderColor: 'transparent',
                callbacks: {
                  ...tooltipConf.callbacks,
                  title: () => 'Target'
                }
              })
        }
      },
      maintainAspectRatio: false,
      layout: {
        autoPadding: false
      },
      scales: {
        x: {
          border: { width: 0 },
          grid: { display: false },
          ticks: isCurrent
            ? {
                color: '#424647',
                font: { weight: 'bold' }
              }
            : {
                color: 'transparent'
              },
          type: 'time'
        },
        y: {
          border: { width: 0 },
          grid: isCurrent ? { display: false } : { color: '#F4F7F8' },
          ticks: { display: false }
        }
      }
    },
    type: 'line'
  });

  const data = isCurrent ? currentData : targetData;
  const datasetIndex = isCurrent ? 0 : 1;
  data.length && chart?.setActiveElements([{ datasetIndex, index: data.length - 1 }]);
  chartId.current = chart?.id;
  setChart(chart);
};

export const sortMetricsDesc = (metrics: HealthMetricItem[]) => {
  if (!metrics || !metrics.length) {
    return [];
  }
  return [...metrics]?.sort(
    (a, b) => new Date(b.collectedDate).getTime() - new Date(a.collectedDate).getTime()
  );
};
