import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import ZoomVideo, {
  ConnectionChangePayload,
  ConnectionState,
  MediaSDKEncDecPayload,
  Participant,
  ParticipantPropertiesPayload,
  VideoClient
} from '@zoom/videosdk';
import { notifyError } from 'components/common/Toast/Toast';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { useAppSelector } from 'hooks/redux';
import { TaskDetailsProps } from 'models/tasks.types';
import { VirtualBackgroundsTypes } from 'pages/ZoomCall/Controls/SettingsPopup/VideoEffects/videEffects.types';
import { useSearchParams } from 'react-router';
import { useLocalStorage, useToggle } from 'react-use';
import { GetZoomTokenResProps } from 'store/appointments/appointments.types';
import {
  useCallLogTracksMutation,
  useLazyGetZoomTokenQuery,
  useTrackConnectErrorMutation
} from 'store/appointments/appointmentsSlice';
import { selectUser } from 'store/user/userSlice';
import { isFirefoxBrowser, isSafariBrowser } from 'utils/helpers';

import { setVirtualBackground } from './zoomCallContext.settings';
import { ZoomCallContextProps } from './zoomCallContext.types';

const defaultValue: ZoomCallContextProps = {
  isZoomCallOpen: false,
  setIsZoomCallOpen: () => {},
  room: null,
  startVideoCall: () => {},
  taskInfo: {
    _id: '',
    status: '',
    category: '',
    appointmentTime: '',
    advancedCallLogging: false
  },
  isPiP: false, // Picture in Picture
  isLandscape: false,
  showControls: true,
  appointmentId: '',
  toggleIsPiP: () => {},
  setIsLandscape: () => {},
  setShowControls: () => {},
  setAppointmentId: () => {},
  audioEnabled: true,
  toggleAudioEnabled: () => {},
  videoEnabled: true,
  toggleVideoEnabled: () => {},
  isFullscreen: false,
  toggleFullscreen: () => {},
  patientInfo: { fullname: 'patient', image: '', _id: '', firstname: 'patient', phone: '' },
  networkQualityLevel: { local: 3, remote: 3 },
  showTimer: false,
  remoteParticipant: null,
  remoteAudioEnabled: false,
  remoteVideoEnabled: true,
  isConnectionError: false,
  remoteParticipantDisconnected: false,
  handleEndVideoCall: () => {},
  startVideo: () => {},
  backgroundSettings: VirtualBackgroundsTypes.None,
  onChangeBackground: () => {},
  isLoadingBackground: false,
  isVideoStarting: false,
  loggerClient: null
};

export const ZoomCallContext = createContext<ZoomCallContextProps>(defaultValue);

export const ZoomCallProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { firstName, lastName } = useAppSelector(selectUser);
  const fullName = `${firstName} ${lastName}`;
  const { showCallCard } = usePhoneCall();
  const [searchParams] = useSearchParams();
  const [isZoomCallOpen, setIsZoomCallOpen] = useState(defaultValue.isZoomCallOpen);
  const [getZoomToken] = useLazyGetZoomTokenQuery();
  const [room, setRoom] = useState<typeof VideoClient | null>(defaultValue.room);
  const [taskInfo, setTaskInfo] = useState(defaultValue.taskInfo);
  const [isPiP, toggleIsPiP] = useToggle(defaultValue.isPiP);
  const [isLandscape, setIsLandscape] = useState(defaultValue.isLandscape);
  const [showControls, setShowControls] = useState(defaultValue.showControls);
  const [appointmentId, setAppointmentId] = useState(defaultValue.appointmentId);
  const [audioEnabled, setAudioEnabled] = useState(defaultValue.audioEnabled);
  const [videoEnabled, setVideoEnabled] = useState(defaultValue.videoEnabled);
  const [isFullscreen, toggleFullscreen] = useToggle(defaultValue.isFullscreen);
  const [patientInfo, setPatientInfo] = useState(defaultValue.patientInfo);
  const [networkQualityLevel, setNetworkQualityLevel] = useState(defaultValue.networkQualityLevel);
  const [showTimer, setShowTimer] = useState(defaultValue.showTimer);
  const [remoteParticipant, setRemoteParticipant] = useState(defaultValue.remoteParticipant);
  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(defaultValue.remoteVideoEnabled);
  const [remoteAudioEnabled, setRemoteAudioEnabled] = useState(defaultValue.remoteAudioEnabled);
  const [isConnectionError, setIsConnectionError] = useState(defaultValue.isConnectionError);
  const [remoteParticipantDisconnected, setRemoteParticipantDisconnected] = useState(
    defaultValue.remoteParticipantDisconnected
  );
  const [backgroundSettings, setBackgroundSettings] = useLocalStorage(
    'zoom-background-settings',
    defaultValue.backgroundSettings
  );
  const [isLoadingBackground, setIsLoadingBackground] = useState(defaultValue.isLoadingBackground);
  const [isVideoStarting, setIsVideoStarting] = useState(defaultValue.isVideoStarting);
  const [loggerClient, setLoggerClient] = useState(defaultValue.loggerClient);
  const [callLogTracks] = useCallLogTracksMutation();
  const [trackConnectError] = useTrackConnectErrorMutation();

  const isSafari = useMemo(() => isSafariBrowser(), []);
  const isFirefox = useMemo(() => isFirefoxBrowser(), []);
  const isVirtualBGSupported = !isSafari && !isFirefox;

  const resetDefaultValues = useCallback(() => {
    setLoggerClient(defaultValue.loggerClient);
    setIsZoomCallOpen(defaultValue.isZoomCallOpen);
    setTaskInfo(defaultValue.taskInfo);
    toggleIsPiP(defaultValue.isPiP);
    setIsLandscape(defaultValue.isLandscape);
    setShowControls(defaultValue.showControls);
    setAppointmentId(defaultValue.appointmentId);
    setAudioEnabled(defaultValue.audioEnabled);
    setVideoEnabled(defaultValue.videoEnabled);
    toggleFullscreen(defaultValue.isFullscreen);
    setPatientInfo(defaultValue.patientInfo);
    setNetworkQualityLevel(defaultValue.networkQualityLevel);
    setShowTimer(defaultValue.showTimer);
    setRemoteParticipant(defaultValue.remoteParticipant);
    setRemoteVideoEnabled(defaultValue.remoteVideoEnabled);
    setRemoteAudioEnabled(defaultValue.remoteAudioEnabled);
    setIsConnectionError(defaultValue.isConnectionError);
    setRemoteParticipantDisconnected(defaultValue.remoteParticipantDisconnected);
    setIsLoadingBackground(defaultValue.isLoadingBackground);
  }, [toggleIsPiP, toggleFullscreen]);

  const logTracksState = useCallback(
    async (video: boolean, audio: boolean, appId?: string) => {
      if (!appId && !appointmentId) return;
      await callLogTracks({
        appointmentId: appId ?? appointmentId,
        tracks: {
          audio: {
            enabled: audio
          },
          video: {
            enabled: video
          }
        }
      }).unwrap();
    },
    [appointmentId, callLogTracks]
  );

  const handleJoinCall = async (token: GetZoomTokenResProps, appId: string) => {
    if (!token?.data) return;

    await logTracksState(videoEnabled, audioEnabled, appId);

    const zoomVideo = ZoomVideo.createClient();
    zoomVideo
      .init('en-US', `Global`, {
        patchJsMedia: false,
        enforceVirtualBackground: isVirtualBGSupported
      })
      .then(() => {
        setLoggerClient(zoomVideo?.getLoggerClient());
        return zoomVideo.join(
          token.data.roomName,
          token.data.token,
          fullName ?? 'Physician Portal'
        );
      })
      .then(() => {
        setRoom(zoomVideo);

        zoomVideo.getLiveTranscriptionClient().startLiveTranscription();

        // Set Remote Participant if They joined before the Provider
        zoomVideo
          ?.getAllUser()
          .filter(
            (participant) =>
              zoomVideo?.getCurrentUserInfo()?.userId &&
              participant.userId !== zoomVideo?.getCurrentUserInfo()?.userId
          )
          .forEach((user) => {
            setRemoteParticipant(user);
            setIsLandscape(!user.isPhoneUser);
          });
      })
      .catch(() => {
        setIsConnectionError(true);
      });
  };

  const startVideoCall = async (task: TaskDetailsProps) => {
    if (task?.appointmentInfo?._id) {
      setIsZoomCallOpen(true);

      getZoomToken(task?.appointmentInfo?._id)
        .unwrap()
        .then((token) => handleJoinCall(token, task?.appointmentInfo?._id))
        .catch(() => resetDefaultValues());

      setTaskInfo({
        _id: task._id,
        status: task.status,
        category: task.category,
        appointmentTime: task.appointmentInfo?.appointmentTime?.startTime ?? '',
        advancedCallLogging: task.appointmentInfo?.advancedCallLogging ?? false
      });
      setAppointmentId(task.appointmentInfo?._id ?? '');
      setPatientInfo({
        fullname: `${task?.personalInfo?.firstName ?? ''} ${task?.personalInfo?.lastName ?? ''}`,
        firstname: task?.personalInfo?.firstName ?? 'patient',
        image: task?.personalInfo?.profileImage ?? '',
        _id: task?.personalInfo?._id ?? '',
        phone: task?.patientInfo?.phone?.internationalFormat ?? ''
      });
    }
  };

  const reportToZoom = useCallback(async () => {
    if (loggerClient && taskInfo?.advancedCallLogging) {
      try {
        await loggerClient?.reportToGlobalTracing();
      } catch (error) {
        console.error('Report To Zoom: ', error);
      }
    }
  }, [loggerClient, taskInfo.advancedCallLogging]);

  const handleEndVideoCall = useCallback(async () => {
    reportToZoom();
    await room?.leave();
    resetDefaultValues();
  }, [resetDefaultValues, room, reportToZoom]);

  useEffect(() => {
    // always keep videocall in PiP mode when task is closed
    if (isZoomCallOpen && !searchParams.has('taskModalOpenID') && !isFullscreen) {
      toggleIsPiP(true);
    }
  }, [searchParams, isFullscreen, toggleIsPiP, isZoomCallOpen]);

  useEffect(() => {
    if (isPiP && isFullscreen) toggleFullscreen(false);
  }, [isFullscreen, isPiP, toggleFullscreen]);

  useEffect(() => {
    room
      ?.getAllUser()
      .filter(
        (participant) =>
          room?.getCurrentUserInfo()?.userId &&
          participant.userId !== room?.getCurrentUserInfo()?.userId
      )
      .forEach(setRemoteParticipant);

    const handlePeerVideoStateChange = ({ action }: { action: string }) =>
      setRemoteVideoEnabled(action === 'Start');

    const handleUserAdded = (data: Participant[]) => {
      const isUserAlreadyConnected = data.find(
        (el) => el.userIdentity === room?.getCurrentUserInfo()?.userIdentity
      );
      if (isUserAlreadyConnected) {
        handleEndVideoCall();
        return;
      }

      setRemoteParticipant(data[0]);
      setRemoteParticipantDisconnected(false);
      setRemoteAudioEnabled(!data[0].muted);
      setIsLandscape(!data[0]?.isPhoneUser);
    };

    const handlePatientLeft = (data: Participant[]) => {
      const zoomSession = room?.getMediaStream();
      data?.forEach((participant) => {
        zoomSession?.stopRenderVideo(
          document.querySelector('#remote-video') as HTMLCanvasElement,
          participant?.userId
        );
      });

      const currentUserId = room?.getCurrentUserInfo()?.userId;
      const noRemoteParticipant = !room
        ?.getAllUser()
        .find((el) => currentUserId && el.userId !== currentUserId);

      if (noRemoteParticipant) {
        setRemoteParticipantDisconnected(true);
        setRemoteVideoEnabled(false);
        setRemoteAudioEnabled(false);
      }
    };

    const handlePatientUpdated = (payload: ParticipantPropertiesPayload[]) => {
      const user = payload.find((el) => el.userId === remoteParticipant?.userId);
      if (user && 'muted' in user) {
        setRemoteAudioEnabled(!user.muted);
      }
    };

    const handleRoomDisconnected = (
      event: 'connect' | 'reconnecting' | 'disconnected',
      message?: string
    ) => {
      if (!appointmentId) return;
      trackConnectError({
        appointmentId: appointmentId,
        body: {
          event: event,
          callType: 'video',
          message
        }
      });
    };

    const handleConnectionChange = (payload: ConnectionChangePayload) => {
      switch (payload.state) {
        case ConnectionState.Closed:
          handleRoomDisconnected('disconnected', payload.reason);
          payload.reason && notifyError(`Connection closed: ', ${payload.reason}`);
          handleEndVideoCall();
          break;
        case ConnectionState.Reconnecting:
          handleRoomDisconnected('reconnecting', payload.reason);
          break;
        case ConnectionState.Fail:
          resetDefaultValues();
          handleRoomDisconnected('connect', payload.reason);
          notifyError(`Connection failed: ', ${payload.reason}`);
          break;
      }
    };

    room?.on('peer-video-state-change', handlePeerVideoStateChange);
    room?.on('user-added', handleUserAdded);
    room?.on('user-removed', handlePatientLeft);
    room?.on('user-updated', handlePatientUpdated);
    room?.on('connection-change', handleConnectionChange);

    return () => {
      room?.off('peer-video-state-change', handlePeerVideoStateChange);
      room?.off('user-added', handleUserAdded);
      room?.off('user-removed', handlePatientLeft);
      room?.off('user-updated', handlePatientUpdated);
      room?.off('connection-change', handleConnectionChange);
    };
  }, [
    room,
    remoteParticipant?.userId,
    networkQualityLevel.local,
    networkQualityLevel.remote,
    appointmentId,
    trackConnectError,
    resetDefaultValues,
    handleEndVideoCall
  ]);

  useEffect(() => {
    const handleNetworlQualityChange = (payload: {
      level: number;
      type: 'uplink' | 'downlink';
      userId: number;
    }) => {
      if (!payload?.userId || !payload?.level) return;

      if (
        payload?.userId === room?.getCurrentUserInfo()?.userId &&
        payload.level !== networkQualityLevel.local
      ) {
        setNetworkQualityLevel((prev) => ({ ...prev, local: payload.level }));
      }
      if (
        payload.userId === remoteParticipant?.userId &&
        payload.level !== networkQualityLevel.remote
      ) {
        setNetworkQualityLevel((prev) => ({ ...prev, remote: payload.level }));
      }
    };
    room?.on('network-quality-change', handleNetworlQualityChange);
    return () => {
      room?.off('network-quality-change', handleNetworlQualityChange);
    };
  }, [networkQualityLevel.local, networkQualityLevel.remote, remoteParticipant?.userId, room]);

  useEffect(() => {
    const handleRotationChange = (payload: { aspectRatio: number; userId: number }) => {
      if (payload.userId === remoteParticipant?.userId) {
        setIsLandscape(payload.aspectRatio > 1);
      }
    };
    const handleVideoDimansionChange = (payload: {
      width: number;
      height: number;
      type: string;
    }) => {
      const isRemoteParticipant = payload?.type === 'received';
      const isRemoteInLandScape = payload?.width > payload?.height;
      if (isRemoteParticipant && isRemoteInLandScape !== isLandscape) {
        setIsLandscape(isRemoteInLandScape);
      }
    };
    room?.on('video-aspect-ratio-change', handleRotationChange);
    room?.on('video-dimension-change', handleVideoDimansionChange);
    return () => {
      room?.off('video-aspect-ratio-change', handleRotationChange);
      room?.off('video-dimension-change', handleVideoDimansionChange);
    };
  }, [remoteParticipant?.userId, room, isLandscape]);

  useEffect(() => {
    remoteParticipant && setRemoteVideoEnabled(remoteParticipant.bVideoOn);
  }, [remoteParticipant]);

  const startVideo = useCallback(
    async (backgroundType?: VirtualBackgroundsTypes) => {
      const zoomSession = room?.getMediaStream();
      const isSupportedHD = zoomSession?.isSupportHDVideo();
      const isRenderSelfViewWithVideoElement = zoomSession?.isRenderSelfViewWithVideoElement();

      if (!videoEnabled || !room || !zoomSession) {
        return;
      }

      if (isRenderSelfViewWithVideoElement) {
        setIsLoadingBackground(true);

        try {
          await zoomSession.startVideo({
            videoElement: document.querySelector('#local-participant-video') as HTMLVideoElement,
            hd: isSupportedHD,
            ...setVirtualBackground(isVirtualBGSupported, backgroundSettings, backgroundType)
          });
        } catch (error) {
          console.error('Start video if isRenderSelfViewWithVideoElement: ', error);
        }

        setIsLoadingBackground(false);
        setTimeout(() => setIsVideoStarting(false), 1000);
      } else {
        setIsVideoStarting(true);
        try {
          await zoomSession.startVideo({
            hd: isSupportedHD,
            ...setVirtualBackground(isVirtualBGSupported, backgroundSettings, backgroundType)
          });
        } catch (error) {
          console.error('Start video block if NOT isRenderSelfViewWithVideoElement: ', error);
        }

        try {
          room?.getCurrentUserInfo()?.bVideoOn &&
            (await zoomSession.renderVideo(
              document.querySelector('#local-participant-canvas') as HTMLCanvasElement,
              room.getCurrentUserInfo()?.userId,
              1920,
              1080,
              0,
              0,
              3
            ));
        } catch (error) {
          console.error('Render video in a canvas: ', error);
        }

        setIsLoadingBackground(false);
        setTimeout(() => setIsVideoStarting(false), 1000);
      }
    },
    [backgroundSettings, isVirtualBGSupported, room, videoEnabled]
  );

  const onChangeBackground = async (type: VirtualBackgroundsTypes) => {
    const zoomSession = room?.getMediaStream();
    if (videoEnabled) {
      setIsLoadingBackground(true);
      setBackgroundSettings(type);

      try {
        await zoomSession?.stopVideo();
      } catch (error) {
        console.error('Stop video before changing background: ', error);
      }

      startVideo(type);
    } else {
      setBackgroundSettings(type);
    }
  };

  const toggleVideoEnabled = async () => {
    const zoomSession = room?.getMediaStream();

    try {
      videoEnabled ? await zoomSession?.stopVideo() : startVideo();
    } catch (error) {
      console.error('Toggle video: ', error);
    }

    await logTracksState(!videoEnabled, audioEnabled);
    setVideoEnabled(!videoEnabled);
  };

  useEffect(() => {
    const startAudioAndCheck = async () => {
      const stream = room?.getMediaStream();

      try {
        const audioStarted = await stream?.startAudio();
        if (!audioStarted) {
          return false;
        }
      } catch (error) {
        console.error('Start audio on call start: ', error);
      }

      let audioDecode = false;
      let audioEncode = false;

      const mediaSdkChangeHandler = async ({ action, type, result }: MediaSDKEncDecPayload) => {
        if (type === 'audio' && result === 'success') {
          if (action === 'encode') {
            audioEncode = true;
          } else if (action === 'decode') {
            audioDecode = true;
          }
          if (audioDecode && audioEncode) {
            try {
              await stream?.startAudio();
            } catch (error) {
              console.error('Start audio on mediaSdkChangeHandler: ', error);
            }
          }
        }
      };

      room?.on('media-sdk-change', mediaSdkChangeHandler);
    };

    !!room && startAudioAndCheck();
  }, [room]);

  const toggleAudioEnabled = async () => {
    const zoomSession = room?.getMediaStream();
    try {
      audioEnabled ? await zoomSession?.muteAudio() : await zoomSession?.unmuteAudio();
    } catch (error) {
      console.error('Toggle audio: ', error);
    }

    await logTracksState(videoEnabled, !audioEnabled);
    setAudioEnabled(!audioEnabled);
  };

  useEffect(() => {
    // NOTE: if user ends the call before the call is connected, then reset the default values
    if (!isZoomCallOpen && room) {
      handleEndVideoCall();
      // NOTE: seting room to default is not in the resetDefaultValues function because
      // there is a case when the user ends the call while camera is starting
      // To turn off camera, we need to call stopVideo, but we can't call it if room is null
      setRoom(defaultValue.room);
    }
  }, [isZoomCallOpen, room, handleEndVideoCall]);

  useEffect(() => {
    const handlePhoneCall = async () => {
      // if Provider does phone call, then mute audio and stop video, then unmute audio when call is closed
      const zoomSession = room?.getMediaStream();
      if (showCallCard) {
        try {
          await zoomSession?.muteAudio();
          await zoomSession?.stopVideo();
        } catch (error) {
          console.error('Mute audio and stop video on phone call: ', error);
        }

        setAudioEnabled(false);
        setVideoEnabled(false);
      } else {
        try {
          if (room?.getCurrentUserInfo()?.muted) {
            await zoomSession?.unmuteAudio();
          }
        } catch (error) {
          console.error('Unmute audio on phone call end: ', error);
        }
        setAudioEnabled(true);
      }
    };

    handlePhoneCall();
  }, [room, showCallCard]);

  useEffect(() => {
    const currentUserId = room?.getCurrentUserInfo()?.userId;
    setShowTimer(!!remoteParticipant && !!currentUserId);
  }, [remoteParticipant, room]);

  return (
    <ZoomCallContext.Provider
      value={{
        isZoomCallOpen,
        setIsZoomCallOpen,
        room,
        startVideoCall,
        taskInfo,
        isPiP,
        isLandscape,
        showControls,
        appointmentId,
        toggleIsPiP,
        setIsLandscape,
        setShowControls,
        setAppointmentId,
        videoEnabled,
        toggleVideoEnabled,
        audioEnabled,
        toggleAudioEnabled,
        isFullscreen,
        toggleFullscreen,
        patientInfo,
        networkQualityLevel,
        showTimer,
        remoteParticipant,
        remoteVideoEnabled,
        remoteAudioEnabled,
        isConnectionError,
        remoteParticipantDisconnected,
        handleEndVideoCall,
        startVideo,
        backgroundSettings,
        onChangeBackground,
        isLoadingBackground,
        isVideoStarting,
        loggerClient
      }}
    >
      {children}
    </ZoomCallContext.Provider>
  );
};
