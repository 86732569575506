import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import { useEffect, useState } from 'react';

import DatePickerInput from 'components/common/DatePickerInput';
import Loader from 'components/common/Loader';
import { notifyError } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import { RenewsDateProps } from './orderDate.types';

const OrderDate: React.FC<RenewsDateProps> = ({
  date,
  type,
  orderId,
  isRtL,
  startAllowedDate = new Date(),
  role
}) => {
  const [updateOrder, { isLoading }] = useUpdateOrderMutation();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>('');
  const canEdit = role !== 'MA';

  const handleRenewsDateUpdate = (newDate: string) => {
    const formattedDate = dayjs(newDate, FRONTEND_DATE_FORMAT).format(BACKEND_DATE_FORMAT);
    setInputValue(newDate);
    if (orderId && formattedDate) {
      updateOrder({ orderId: orderId, order: { [type]: formattedDate } })
        .unwrap()
        .catch(() => {
          notifyError('Failed to update renews date');
        });
    }
  };

  useEffect(() => {
    if (date) {
      setSelectedDate(dayjs(date, DateFormat.MM_DD_YYYY).toDate());
      setInputValue(date);
    }
  }, [date]);

  return (
    <>
      <Loader isVisible={isLoading} />
      <div onClick={(e) => e.stopPropagation()}>
        <DatePickerInput
          placeholder="Select renews"
          size="md"
          inputValue={inputValue}
          setInputValue={handleRenewsDateUpdate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          startAllowedDate={startAllowedDate}
          lastAllowedDate={dayjs().add(100, 'year').toDate()}
          captionLayout="dropdown"
          wrapperClasses="w-full relative"
          labelClassNames="text-gray-500"
          isRtL={isRtL}
          showDateLabel
          showPostIcon={canEdit}
        />
      </div>
    </>
  );
};

export default OrderDate;
