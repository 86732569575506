import { StaffsFiltersFormProps } from './staffsFiltersForm.types';

export const defaultValues: StaffsFiltersFormProps = {
  name: '',
  email: '',
  lastLogin: '',
  uniqueId: '',
  'userType.name': '',
  searchKey: '',
  activeStates: [],
  status: { label: '', value: '' },
  userType: { label: '', value: '' }
};
