import { RolesFiltersFormProps } from './rolesFiltersForm.types';

export const defaultValues: RolesFiltersFormProps = {
  name: '',
  createdAt: '',
  color: '',
  _id: '',
  searchKey: '',
  'userType.name': '',
  userType: { label: '', value: '' },
  status: { label: '', value: '' }
};
