import { SubmitPAFormBodyProps } from 'components/modals/SubmitPA/submitPA.types';
import {
  MarkCompleteReasons,
  NotPrescribingReason,
  SnoozeDuration
} from 'enums/markCompleteOption';
import { PARequestStatus } from 'enums/taskDetailsStatus';
import {
  IntakeItemsInfo,
  TaskDetailsProps,
  TaskPatientInfo,
  TaskProps,
  TasksSummaryProps
} from 'models/tasks.types';
import { ObjectValues } from 'utils/common/types';

export const initialStateTask: {
  taskDetails: TaskDetailsProps;
  intakeItems: IntakeItemsInfo[];
  selectRequireAppointment: boolean;
} = {
  taskDetails: {
    _id: '',
    status: 'Inactive',
    isPendingApproval: false,
    isAutomated: false,
    agingAlertAudience: [],
    dueDate: '',
    category: 'Review',
    doctorInfo: {
      _id: '',
      name: ''
    },
    createdAt: '',
    requestOrder: {
      orderId: '',
      orderType: '',
      updatedAt: '',
      createdAt: ''
    },
    intakeItemsInfo: [
      {
        _id: '',
        title: '',
        taskCategory: '',
        sortNum: 0,
        active: false
      }
    ],
    appointmentInfo: {
      _id: '',
      appointmentStatus: '',
      images: [],
      postConsultTasks: [],
      hasPatientJoined: false,
      doctorId: '',
      appointmentTime: {
        startTime: '',
        endTime: ''
      },
      appointmentMethod: '',
      appointmentDescription: '',
      patientId: '',
      appointmentType: {
        _id: '',
        displayName: '',
        category: ''
      },
      preConsultTaskId: '',
      staffName: '',
      taskId: ''
    },
    personalInfo: {
      dob: '',
      phone: {
        phoneNumber: '',
        countryCode: ''
      },
      _id: '',
      status: '',
      firstName: '',
      lastName: '',
      email: '',
      zipCode: '',
      state: '',
      profileImage: '',
      planInfo: {
        _id: '',
        planName: '',
        planPrice: 0
      }
    },
    patientInfo: {
      _id: '',
      state: '',
      name: '',
      dob: '',
      stateShortCode: '',
      phone: {
        phoneNumber: '',
        countryCode: ''
      }
    },
    requestInfo: [],
    upcomingAppointmentInfo: [],
    patientId: '',
    preConsultTaskId: '',
    showBackToCall: false,
    categoryInfo: null,
    patientDocuments: [
      {
        _id: '671908cd3a0c76949a385b1c',
        fileName: 'Screenshot at May 18 10-05-06.png',
        filePath: '',
        createdAt: '2024-10-23T14:31:42.001Z',
        tags: [
          {
            name: 'Medication information (bottle)',
            code: 'medication-information',
            _id: '675967fe901ce3351dca09ba'
          },
          {
            name: 'Lab document',
            code: 'lab-document',
            _id: '675967fe901ce3351dca09b9'
          }
        ],
        uploadedBy: 'Roman Keeptestik',
        isUploadedByCurrentUser: false,
        description: 'This is a test description',
        updatedByPatientAt: '2024-11-23T14:31:42.001Z'
      },
      {
        _id: '671908cc09ed6f5b643a045d',
        fileName: 'identity_8.png',
        filePath: '',
        createdAt: '2024-10-23T14:31:40.718Z',
        uploadedBy: 'Roman Keeptestik',
        isUploadedByCurrentUser: false,
        tags: []
      },
      {
        _id: '66bcb9a6b0562d8503ad86ae',
        fileName: 'image.png',
        filePath: '',
        createdAt: '2024-08-14T14:05:26.630Z',
        tags: [
          {
            name: 'Lab document',
            code: 'lab-document',
            _id: '675967fe901ce3351dca09b9'
          }
        ],
        uploadedBy: 'ShauNni',
        isUploadedByCurrentUser: true,
        description: 'This is a test description',
        updatedByPatientAt: '2024-11-14T14:05:26.630Z'
      }
    ]
  },
  intakeItems: [{ active: false, taskCategory: '', sortNum: 0, title: '', _id: '' }],
  selectRequireAppointment: false
};

export const initialApptAsyncInfo = {
  assignedAt: '',
  category: '',
  details: { note: '' },
  dueDate: '',
  subRows: [],
  patientInfo: {
    _id: '',
    state: '',
    name: '',
    dob: '',
    stateShortCode: '',
    phone: { phoneNumber: '', countryCode: '' }
  },
  doctorInfo: { _id: '', name: '' },
  status: ''
};

export const initialStateTasks: {
  tasksSummary: TasksSummaryProps;
  asyncProviderEnabled: boolean;
  apptAsyncProviderEnabled: boolean;
  apptAsyncInfo?: ApptAsyncInfo;
} = {
  tasksSummary: {
    messageCount: 0,
    renewalCount: 0,
    requestCount: 0,
    requestPACount: 0,
    reviewCount: 0,
    asyncCount: 0,
    escalationsCount: 0,
    apptAsyncCount: 0
  },
  asyncProviderEnabled: false,
  apptAsyncProviderEnabled: false,
  apptAsyncInfo: initialApptAsyncInfo
};

export interface OrderForFollowUp {
  label: string;
  value: string;
}

export interface RequestReason {
  label: string;
  value: string;
  groupLabel: string;
}

export type ApptAsyncInfo = Pick<
  TaskProps,
  | 'assignedAt'
  | 'category'
  | 'details'
  | 'dueDate'
  | 'subRows'
  | 'patientInfo'
  | 'doctorInfo'
  | 'status'
>;

export interface CreateNewTaskParams {
  patientId: string;
  assignedTo?: string;
  audience?: string;
  requestReason?: RequestReason[];
  orderForFollowUp?: OrderForFollowUp;
  note: string;
  dueDate?: string;
  isUrgent?: boolean;
  parentTaskId?: string;
  elationPrescriptionId?: string;
  tags?: string[];
  tpsBenefit?: {
    request_id: string;
    drug_name: string;
    insurance_name: string;
  };
  timezone?: string;
}

export interface TeamsProps {
  id: string;
  name?: string;
}

export interface CreateRequestOrderTaskParams {
  patientId: string;
  note: string;
  dueDate?: string;
  elationPrescriptionId?: number;
  reasons?: string[];
  teams?: TeamsProps[];
}

export interface DiagnosisCodesProps {
  _id?: string;
  code: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface UpdatedTaskResponseProps {
  taskAudience: string[];
  taskId: string;
  updatedData: TaskProps;
}

export interface CreatedTaskResponseProps {
  taskAudience: string[];
  taskId: string;
  task: TaskProps;
}

export interface AvailableStaffWarning {
  message: string;
  shouldConfirm?: boolean;
  canAssignStaff?: boolean;
}

export interface AvailableStaffResponse {
  _id: string;
  userType: {
    name: string;
    shortCode: 'MD' | 'NP' | 'MA' | 'CS' | 'AD' | 'PH';
  };
  name: string;
  profileImage: string;
  warning: AvailableStaffWarning;
  isClinicalLeadership?: boolean;
}

export interface AvailableStaffProps {
  limit?: number;
  pageNo?: number;
  searchKey?: string;
  timezone?: string;
}

export interface AvailableStaffForPatientProps {
  taskCategory: string;
  patientId?: string;
  searchKey?: string;
  isUrgentTask?: boolean;
  timezone?: string;
}

export interface RequestPAProps {
  patientUserId: string;
  audience?: string;
  assignedTo?: string;
  note: string;
  isUrgent: boolean;
  tpsBenefit?: { request_id: string; drug_name: string; insurance_name: string };
  medication?: string;
  timezone?: string;
}

export type ChangeDispositionRequestProps = {
  taskId: string;
  body: {
    note?: string;
    disposition?: MarkCompleteReasons;
    notPrescribingReason?: NotPrescribingReason;
    snoozeDuration?: SnoozeDuration;
  };
};

export type SubmitPAFormRequestProps = {
  taskId: string;
  body: SubmitPAFormBodyProps;
};

export type AssignTaskToTeamRequestProps = {
  taskId: string;
  teamIds?: string[];
};

export type AsyncScheduleDetails = {
  new: number;
  active: number;
  completedNoRx: number;
  prescribed: number;
  additionalInfoRequired: number;
  averageWaitingTime: number;
  longestWaitingTime: number;
};

export type ApptAsyncScheduleDetails = {
  new: number;
  active: number;
  incomplete: number;
  completedNoRx: number;
  prescribed: number;
  additionalInfoRequired: number;
  averageWaitingTime: number;
  longestWaitingTime: number;
  dueTasksCount: number;
  overdueTasksCount: number;
};

export type ChangePAStatusProps = {
  status: PARequestStatus;
  patientId?: string;
  requestId?: string;
  drugName?: string;
  insuranceName?: string;
  taskId?: string;
};

export type EscalateTaskRequestProps = {
  taskId: string;
  userId?: string;
  teamIds?: string[];
  isConfirmed?: boolean;
  note?: string;
};

export type TaskTagsType = {
  name: string;
  _id: string;
  category: string;
};

export interface RequestOrderOptionsProps {
  label: string;
  value: string;
  groupLabel: string;
  toEscalate?: boolean;
}

export type GroupRequestOrderOptionsProps = {
  label: string;
  toEscalate?: boolean;
  options: RequestOrderOptionsProps[];
};

export interface TaskOrderReasons {
  key: string;
  name: string;
  categoryName: string;
}

export interface TaskRequestOrderReasonsType {
  categoryName: string;
  displayOrder: number;
  toEscalate: boolean;
  reasons: TaskOrderReasons[];
}

export const AssignValueType = {
  releaseToList: 'release-to-list',
  takeOver: 'takeover',
  role: 'assign-role',
  team: 'assign-teams',
  staff: 'assign-staff-member',
  primary: 'assign-primary-provider'
} as const;

export type AssignValueTypes = ObjectValues<typeof AssignValueType>;

export type BulkEditTaskParams = {
  taskIds: string[];
  updateData: {
    operationType: AssignValueTypes;
    audience?: string[];
    timezone?: string;
    staffUserId?: string;
    teamIds?: string[];
  };
};

export type BulkEditUpdatedResponseItem = {
  taskId: string;
  category: string;
  patientInfo: TaskPatientInfo;
  dueDate?: string;
  createdAt?: string;
};

export type BulkEditFailedResponseItem = BulkEditUpdatedResponseItem & {
  reason: string;
};

export type BulkEditTaskResponse = {
  message: string;
  data: {
    updated: BulkEditUpdatedResponseItem[];
    failed: BulkEditFailedResponseItem[];
  };
};

export type ReviewRejectedLabAction = 'rejected' | 'approved';

export type ReviewRejectedLabRequest = {
  taskId: string;
  orderId: string;
  reason: string;
  action: ReviewRejectedLabAction;
};
