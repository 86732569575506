import { ALERT_BASED_ON_OPTIONS, ALERT_UNIT_OPTIONS } from 'constants/alerts';
import { AUDIENCE } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';
import { TASK_CATEGORIES } from 'constants/tasks';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { AlertUnit, OccurrenceOrder } from 'enums/agingAlert';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

import { defaultValues } from './agingFiltersForm.settings';
import { AgingFiltersProps } from './agingFiltersForm.types';

const AgingFiltersForm = () => {
  const { control, watch, handleSubmit, reset, formState } = useForm<AgingFiltersProps>({
    reValidateMode: 'onChange'
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useTableFiltersData('aging', defaultValues, reset);

  const onSubmit = (data: AgingFiltersProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      name: data?.name && encodeURIComponent(data?.name),
      taskType: data.taskType?.value,
      criterionField: data.criterionField?.value,
      ...(data.alertType && { alertType: data.alertType?.value }),
      ...(data.alertUnit?.label && {
        alertUnit: data.alertUnit?.label.includes(AlertUnit.Mins) ? AlertUnit.Min : AlertUnit.Hrs,
        occurenceOrder: data.alertUnit?.value.includes(OccurrenceOrder.Before)
          ? OccurrenceOrder.Before
          : OccurrenceOrder.After
      }),
      ...(!data.alertUnit?.value && { occurenceOrder: '' }),
      alertAmount: data.alertAmount,
      uniqueId: data.uniqueId && encodeURIComponent(data.uniqueId),
      audience: data.audience?.value,
      status: data.status?.value
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid max-w-[600px] grid-cols-2">
        <div className="flex flex-col gap-4 border-r border-gray-200 pr-6">
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={TASK_CATEGORIES}
            placeholder="Select task type"
            label="Task type"
            name="taskType"
            defaultValue={formData?.taskType}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={ALERT_BASED_ON_OPTIONS}
            placeholder="Select based on"
            label="Based on"
            name="criterionField"
            defaultValue={formData?.criterionField}
          />
          <InputField
            name="alertAmount"
            label="Amount"
            type="number"
            placeholder="Enter amount"
            control={control}
            errors={formState.errors.alertAmount}
            helper={formState.errors.alertAmount?.message}
            rules={validation('Alert amount', NUMBER_REGEXP, '', true)}
            value={formData?.alertAmount}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={ALERT_UNIT_OPTIONS}
            placeholder="Select unit"
            label="Unit"
            name="alertUnit"
            defaultValue={formData?.alertUnit}
          />
        </div>
        <div className="flex flex-col gap-4 pl-6">
          <InputField
            name="name"
            label="Name"
            placeholder="Enter name"
            control={control}
            type="text"
            value={formData?.name}
          />
          <InputField
            name="uniqueId"
            label="Unique ID"
            type="text"
            placeholder="Enter ID"
            control={control}
            value={formData?.uniqueId}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={AUDIENCE}
            placeholder="Select audience"
            label="Audience"
            name="audience"
            defaultValue={formData?.audience}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={STATUS_OPTIONS}
            placeholder="Select status"
            label="Status"
            name="status"
            defaultValue={formData?.status}
          />
        </div>
      </div>
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default AgingFiltersForm;
