import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { parseHTMLToText } from 'utils/common/parseHTML';

import { MIFOptionProps } from './mifOption.types';

const MIFOption: React.FC<MIFOptionProps> = ({
  warning,
  option,
  showAllOptions,
  answersArray,
  isAdditionalOption,
  isGrouped,
  isNotComplete
}) => {
  const parsedOption = parseHTMLToText({ text: String(option), className: '' });
  const isChosen =
    !isGrouped && ((!showAllOptions && !isAdditionalOption) || answersArray?.includes(option));
  const isChosenOrAdditional = !isGrouped && (isChosen || isAdditionalOption);

  const optionClassNames = classNames(
    'flex items-center gap-1 mif-option',
    isChosenOrAdditional || (isGrouped && !isNotComplete) ? 'font-bold' : 'font-medium',
    isChosenOrAdditional ? (warning ? 'text-red-500' : 'text-green-500') : 'text-black/30',
    isGrouped && !isNotComplete ? (warning ? 'text-red-500' : 'text-green-500') : '',
    { 'pl-5': !isChosen || isAdditionalOption }
  );
  return (
    <span className={optionClassNames}>
      {isChosen && (
        <span data-testid="arrow-alt-right">
          <Common.Icon name="arrow-alt-right" className={classNames('h-4 w-4')} />
        </span>
      )}
      {parsedOption}
    </span>
  );
};

export default MIFOption;
