import { ProspectFilterFormTypes } from './prospectFilterForm.types';

export const defaultValues: ProspectFilterFormTypes = {
  patientName: '',
  endDate: '',
  startDate: '',
  planId: { label: '', value: '' },
  appointmentType: { label: '', value: '' },
  email: '',
  state: { label: '', value: '' },
  prospectStatus: { label: '', value: '' },
  phone: ''
};
