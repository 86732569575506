import { createSlice } from '@reduxjs/toolkit';
import { BulkEditResponseProps } from 'components/modals/bulkEditModals/UpdateFailed/updateFailed.types';

import {
  initialState,
  ReviewResponseProps,
  ReviewsProps,
  ReviewsQueryParams
} from './reviews.types';
import { apiSlice } from '../api/apiSlice';

const reviewsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getReviews: build.query<ReviewsProps, { params: ReviewsQueryParams }>({
      query: ({ params }) => ({ url: `/admin/reviews`, params }),
      transformResponse: (response: ReviewsProps) => ({
        data: response.data,
        info: response.info
      }),
      providesTags: (result) =>
        result
          ? ['Reviews', ...result.data.map(({ _id }) => ({ type: 'Reviews' as const, id: _id }))]
          : ['Reviews']
    }),
    getReview: build.query<ReviewResponseProps, { id: string; params: ReviewsQueryParams }>({
      query: ({ id, params }) => ({ url: `/admin/reviews/${id}`, params }),
      transformResponse: (response: ReviewResponseProps) => ({
        data: response.data,
        info: response.info
      }),
      providesTags: (_result, _error, arg) => [{ type: 'Reviews', id: arg.id }]
    }),
    moderateReviews: build.mutation({
      query: (body) => ({
        url: `/admin/reviews/moderate`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: BulkEditResponseProps) => response,
      invalidatesTags: ['Reviews']
    })
  })
});

export const { useGetReviewsQuery, useModerateReviewsMutation } = reviewsApi;

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: initialState,
  reducers: {
    setReviews: (state, action) => {
      state.data = action.payload.data;
      state.info = action.payload.info;
    },
    resetReviews: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(reviewsApi.endpoints.getReviews.matchFulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload.data;
        state.info = action.payload.info;
      }
    });
  }
});

export default reviewsSlice.reducer;

export const { setReviews, resetReviews } = reviewsSlice.actions;
