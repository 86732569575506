import { AppointmentCategories } from 'store/appointments/appointments.types';

import { apiSlice } from '../api/apiSlice';

const appointmentCategoriesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAppointmentCategories: build.query<AppointmentCategories[], void>({
      query: () => `/appointment-categories`,
      transformResponse: (response: { data: AppointmentCategories[] }) => response.data,
      providesTags: ['AppointmentCategory']
    }),
    editAppointmentCategories: build.mutation({
      query: (categories) => ({
        url: `appointment-categories/reference-links`,
        method: 'PATCH',
        body: { categories }
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['AppointmentCategory']
    })
  })
});

export const { useGetAppointmentCategoriesQuery, useEditAppointmentCategoriesMutation } =
  appointmentCategoriesApi;
