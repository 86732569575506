import { Role } from 'enums/role';
import { OnboardingStatus } from 'enums/taskDetailsStatus';

import { ObjectValues } from '../utils/common/types';

export const FUTURE_TASKS_FILTERS = [
  { label: 'Show all tasks', value: '0' },
  { label: 'Show tasks up to 1 day in the future', value: '1' },
  { label: 'Show tasks up to 3 days in the future', value: '3' },
  { label: 'Show tasks up to 7 days in the future', value: '7' },
  { label: 'Show tasks up to 14 days in the future', value: '14' },
  { label: 'Show tasks up to 30 days in the future', value: '30' }
];

export const ONBOARDING_STATUSES_EXTENDED = [
  {
    label: 'Only show problems',
    value: OnboardingStatus.TASK_ONLY_PROBLEMS,
    groupLabel: 'Onboarding'
  },
  {
    label: 'Onboarded by staff',
    options: [
      {
        label: 'True',
        value: OnboardingStatus.ON_BOARDED_BY_STAFF,
        groupLabel: 'Onboarded by staff'
      },
      {
        label: 'False',
        value: OnboardingStatus.NOT_ON_BOARDED_BY_STAFF,
        groupLabel: 'Onboarded by staff'
      }
    ]
  },
  {
    label: 'Address',
    options: [
      { label: 'Verified', value: OnboardingStatus.ADDRESS_VERIFIED, groupLabel: 'Address' },
      { label: 'Not verified', value: OnboardingStatus.ADDRESS_NOT_VERIFIED, groupLabel: 'Address' }
    ]
  },
  {
    label: 'Insurance',
    options: [
      {
        label: 'Complete • Covered',
        value: OnboardingStatus.INSURANCE_COVERED,
        groupLabel: 'Insurance'
      },
      {
        label: 'Complete • Not covered',
        value: OnboardingStatus.INSURANCE_NOT_COVERED,
        groupLabel: 'Insurance'
      },
      {
        label: 'Insurance not provided',
        value: OnboardingStatus.INSURANCE_NOT_PROVIDED,
        groupLabel: 'Insurance'
      },
      {
        label: 'Waiting for benefits',
        value: OnboardingStatus.INSURANCE_WAITING_BENEFITS,
        groupLabel: 'Insurance'
      },
      {
        label: 'PA required',
        value: OnboardingStatus.INSURANCE_PA_REQUIRED,
        groupLabel: 'Insurance'
      },
      {
        label: 'PA requested',
        value: OnboardingStatus.INSURANCE_PA_REQUESTED,
        groupLabel: 'Insurance'
      },
      {
        label: 'Incomplete',
        value: OnboardingStatus.INSURANCE_INCOMPLETE_OR_MISSING_INSURANCE,
        groupLabel: 'Insurance'
      },
      {
        label: 'Plan inactive',
        value: OnboardingStatus.INSURANCE_PLAN_INACTIVE,
        groupLabel: 'Insurance'
      }
    ]
  },
  {
    label: 'Medical intake form',
    options: [
      { label: 'Normal', value: OnboardingStatus.MIF_NORMAL, groupLabel: 'MIF' },
      { label: 'Warnings', value: OnboardingStatus.MIF_WARNING, groupLabel: 'MIF' },
      { label: 'Incomplete', value: OnboardingStatus.MIF_INCOMPLETE, groupLabel: 'MIF' }
    ]
  },
  {
    label: 'Identity',
    options: [
      { label: 'Verified', value: OnboardingStatus.IDENTITY_VERIFIED, groupLabel: 'Identity' },
      {
        label: 'Not verified',
        value: OnboardingStatus.IDENTITY_NOT_VERIFIED,
        groupLabel: 'Identity'
      }
    ]
  },
  {
    label: 'Symptoms',
    options: [
      { label: 'Complete', value: OnboardingStatus.SC_COMPLETE, groupLabel: 'Symptoms' },
      { label: 'Skipped', value: OnboardingStatus.SC_SKIPPED, groupLabel: 'Symptoms' },
      { label: 'Incomplete', value: OnboardingStatus.SC_INCOMPLETE, groupLabel: 'Symptoms' },
      { label: 'Urgent', value: OnboardingStatus.SC_URGENT, groupLabel: 'Symptoms' }
    ]
  }
];

const CategoryFilterAppointmentValue = {
  WeightManagement: 'weight-management',
  Nutrition: 'nutrition',
  Sweat: 'sweat-6s',
  Sleep: 'sleep-6s',
  Stress: 'stress-6s',
  Stillness: 'stillness-6s',
  Social: 'social-6s'
} as const;

export type CategoryFilterAppointmentValues = ObjectValues<typeof CategoryFilterAppointmentValue>;

export const CategoryFilterValues = {
  All: 'All',
  Messages: 'Messages',
  Onboarding: 'Onboarding',
  PreConsult: 'Pre-consult',
  Appointment: 'Appointment',
  ApptAsync: 'Appt • Async',
  Queue: 'Queue',
  Renewal: 'Renewal',
  Async: 'Async',
  PostConsult: 'Post-consult',
  Review: 'Review',
  RequestPA: 'Request • PA',
  RequestOrder: 'Request • Order',
  Requests: 'Requests',
  FrontDesk: 'Front desk',
  History: 'History',
  ...CategoryFilterAppointmentValue
} as const;

export const AppointmentCategoriesValue = [
  CategoryFilterValues.WeightManagement,
  CategoryFilterValues.Nutrition,
  CategoryFilterValues.Sweat,
  CategoryFilterValues.Sleep,
  CategoryFilterValues.Stress,
  CategoryFilterValues.Stillness,
  CategoryFilterValues.Social
];

export const TASK_CATEGORIES_FILTER_POPUP = [
  {
    label: 'All',
    value: CategoryFilterValues.All,
    color: 'bg-gray-700',
    bgColor: 'bg-gray-200',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Messages',
    value: CategoryFilterValues.Messages,
    color: 'bg-secondary-500',
    bgColor: 'bg-secondary-100',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Onboarding',
    value: CategoryFilterValues.Onboarding,
    color: 'bg-orange-500',
    bgColor: 'bg-orange-100',
    roles: [Role.MA, Role.AD]
  },
  {
    label: 'Pre-consult',
    value: CategoryFilterValues.PreConsult,
    color: 'bg-orange-500',
    bgColor: 'bg-orange-100',
    roles: [Role.AD, Role.MA]
  },
  {
    label: 'Appointment',
    value: CategoryFilterValues.Appointment,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appt • Async',
    value: CategoryFilterValues.ApptAsync,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.MA, Role.PH, Role.AD, Role.CS]
  },
  {
    label: 'Appointment • WM',
    value: CategoryFilterValues.WeightManagement,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appointment • Nutrition',
    value: CategoryFilterValues.Nutrition,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appointment • Fitness',
    value: CategoryFilterValues.Sweat,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appointment • Sleep',
    value: CategoryFilterValues.Sleep,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appointment • Stress',
    value: CategoryFilterValues.Stress,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appointment • Stillness',
    value: CategoryFilterValues.Stillness,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Appointment • Social',
    value: CategoryFilterValues.Social,
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Queue',
    value: CategoryFilterValues.Queue,
    color: 'bg-orange-500',
    bgColor: 'bg-orange-100',
    roles: [Role.AD, Role.MA]
  },
  {
    label: 'Renewal',
    value: CategoryFilterValues.Renewal,
    color: 'bg-green-500',
    bgColor: 'bg-green-100',
    roles: [Role.NP, Role.PH, Role.AD, Role.MA, Role.CS]
  },
  {
    label: 'Async',
    value: CategoryFilterValues.Async,
    color: 'bg-warm-gray-500',
    bgColor: 'bg-warm-gray-100',
    roles: [Role.NP, Role.PH, Role.AD, Role.MA, Role.CS]
  },
  {
    label: 'Post-consult',
    value: CategoryFilterValues.PostConsult,
    color: 'bg-primary-700',
    bgColor: 'bg-primary-100',
    roles: [Role.NP, Role.PH, Role.AD]
  },
  {
    label: 'Review',
    value: CategoryFilterValues.Review,
    color: 'bg-blue-500',
    bgColor: 'bg-blue-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD]
  },
  {
    label: 'Request • PA',
    value: CategoryFilterValues.RequestPA,
    color: 'bg-violet-500',
    bgColor: 'bg-violet-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS]
  },
  {
    label: 'Request • Order',
    value: CategoryFilterValues.RequestOrder,
    color: 'bg-violet-500',
    bgColor: 'bg-violet-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS]
  },
  {
    label: 'Requests',
    value: CategoryFilterValues.Requests,
    color: 'bg-violet-500',
    bgColor: 'bg-violet-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS]
  },
  {
    label: 'Front desk',
    value: CategoryFilterValues.FrontDesk,
    color: 'bg-red-500',
    bgColor: 'bg-red-100',
    roles: [Role.CS, Role.AD, Role.MA, Role.PH]
  },
  {
    label: 'History',
    value: CategoryFilterValues.History,
    color: 'bg-gray-700',
    bgColor: 'bg-gray-200',
    roles: [Role.PH, Role.NP, Role.MA, Role.AD, Role.CS]
  }
];

export const SENT_REQUEST_FILTER_DATA = [
  {
    label: 'All',
    value: 'All',
    color: 'bg-gray-700',
    bgColor: 'bg-gray-200',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS]
  }
];

export const AUDIENCE_OPTIONS = [
  { label: 'Physician', value: 'PH' },
  { label: 'Medical Assistant', value: 'MA' },
  { label: 'Admin', value: 'AD' },
  { label: 'Customer Service Rep', value: 'CS' }
];
