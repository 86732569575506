import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { closeModal } from 'store/modal/modalSlice';
import { handleReset } from 'utils/filters/filters';

import { defaultValues } from './requestMessageTemplatesFilterForm.settings';
import { RequestMessageTemplatesFilterFormProps } from './RequestMessageTemplatesFilterForm.types';

const RequestMessageTemplatesFilterForm = () => {
  const { control, watch, handleSubmit, reset } = useForm<RequestMessageTemplatesFilterFormProps>({
    reValidateMode: 'onChange'
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = (data: RequestMessageTemplatesFilterFormProps) => {
    const parsedUrl = queryString.parse(location.search);
    const filterValues = {
      ...parsedUrl,
      pageNo: '0',
      ...data,
      message: data.message && encodeURIComponent(data.message),
      shortCode: data.shortCode && encodeURIComponent(data.shortCode),
      uniqueId: data.uniqueId && encodeURIComponent(data.uniqueId),
      status: data.status?.value
    };

    const query = Object.fromEntries(Object.entries(filterValues).filter(([, value]) => !!value));

    if (!isEqual(query, parsedUrl)) {
      navigate({ search: queryString.stringify(query) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex w-[312px] flex-col gap-4 2xl:w-[410px]">
      <InputField
        name="message"
        label="Message"
        type="text"
        placeholder="Enter Message"
        control={control}
      />
      <InputField
        name="shortCode"
        label="Short Code"
        type="text"
        placeholder="Enter Short Code"
        control={control}
      />
      <InputField
        name="uniqueId"
        label="Unique ID"
        type="text"
        placeholder="Enter unique ID"
        control={control}
      />
      <ControlledSelect
        control={control}
        labelDirection="col"
        options={STATUS_OPTIONS}
        placeholder="Select status"
        label="Status"
        name="status"
      />
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default RequestMessageTemplatesFilterForm;
