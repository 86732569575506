import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { stripHTML } from 'utils/common/parseHTML';

// NOTE: we need the extension to be presented during the file upload
// to have the ability to open the preview of the file in the chat
const _getExtension = (imageSrc: string) => {
  let extension = 'png'; // default extension

  // check if the source has an extension at the end
  const matchExt = imageSrc.match(/\.([a-zA-Z0-9]+)(\?|$)/);

  if (matchExt) {
    extension = matchExt[1];
  } else if (imageSrc.startsWith('data:image/')) {
    // check if the source is a base64 image
    extension = imageSrc.match(/data:image\/([a-zA-Z0-9]+);/)?.[1] || 'png';
  }
  return '.' + extension;
};

const _constructImageFileName = (imageSrc: string) => {
  // usually the pasted image has a long original name which make no sense, so we better cut it
  const imageFileNameExtracted = imageSrc?.split('/')?.pop()?.slice(0, 20) || '';
  const currentDate = dayjs().format(DateFormat.YYYY_MM_DD__HH_mm_ss);
  const extension = _getExtension(imageSrc);

  return `screenshot_${imageFileNameExtracted}_${currentDate}${extension}`;
};

// extract image from HTML and convert to a File object
export const extractImageFromMessage = async (message: string) => {
  const imageTag = message.match(/<img[^>]*>/);

  if (!imageTag) return { updatedMessage: message, imageFile: null };

  const imageSrc = imageTag[0].match(/src="([^"]*)/)?.[1] || '';
  const imageFileName = _constructImageFileName(imageSrc);

  // Convert image URL to a File object
  const imageBlob = await fetch(imageSrc).then((response) => response.blob());
  const imageFile = new File([imageBlob], imageFileName, { type: imageBlob.type });

  const updatedMessage = stripHTML(message).trim();

  return { updatedMessage, imageFile };
};
