import { Fragment, useEffect, useMemo } from 'react';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { TaskTags } from 'enums/taskCategories';
import { useAppSelector } from 'hooks/redux';
import { useParams } from 'react-router';
import { selectPatient, useLazyGetPatientQuery } from 'store/patients/patientsSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import {
  HAS_PERMISSION_TO_STOP_RECEIVING_TASKS,
  hasPermission
} from 'utils/popupsContentPermission';

import { getAssignProviderData } from './dropdown.settings';
import { DropdownProps } from './dropdown.types';

const selectPatientState = createSelector([selectPatient], (patient) => ({
  isTripleTherapyPatient: patient.isTripleTherapyPatient
}));

const Dropdown: React.FC<DropdownProps> = ({
  handleLoginAsAPatient,
  handleCreateNewTask,
  handleViewMaxio,
  handleStopReceivingTasks,
  handleAssignProvider,
  handleCheckBenefits,
  handlePrescribeCompound,
  handleRemoveBillingBlockModal
}) => {
  const { isTripleTherapyPatient } = useAppSelector(selectPatientState);
  const { patientId = '' } = useParams<{ patientId: string }>();
  const { _id: userId, userType } = useAppSelector(selectUser);
  const { taskDetails } = useAppSelector(selectTask);
  const [getPatient, { data: patient }] = useLazyGetPatientQuery();
  const isNotMyPatient = patient?.doctorId !== userId;
  const showStopReceivingTasks =
    isNotMyPatient && hasPermission(HAS_PERMISSION_TO_STOP_RECEIVING_TASKS, userType?.name);

  const { showAssignProviderBtn, isReAssignProviderFlow } = useMemo(
    () => getAssignProviderData(taskDetails),
    [taskDetails]
  );

  const isTripleTherapy =
    taskDetails?.tags?.includes(TaskTags.TripleTherapy) || isTripleTherapyPatient;

  const showRemoveBillingBlockAlert = patient?.paymentBlockInfo?.hardBlockedAt;

  const planName = taskDetails?.personalInfo?.planInfo?.planName || patient?.planInfo?.planName;

  const isWeightManagement =
    planName === 'Weight Management' || taskDetails.tags?.includes(TaskTags.WM);
  const showCheckBenefits = handleCheckBenefits && isWeightManagement;

  const isCurrentPlanWeightManagement = planName === 'Weight Management';

  // Is intended to remove branded logic so that the dropdown option can show.
  const showPrescribeCompound = handlePrescribeCompound && isCurrentPlanWeightManagement;
  const showViewInMaxio = patient?.isChargifyCreated && handleViewMaxio;

  const getMenuItemClasses = (active: boolean) =>
    classNames('group flex items-center font-semibold text-sm gap-3 py-4 px-6', {
      'bg-gray-100 text-gray900 cursor-pointer': active,
      'text-gray700': !active
    });

  useEffect(() => {
    if (taskDetails?.patientId || patientId)
      getPatient({ id: taskDetails?.patientId || patientId });
  }, [taskDetails?.patientId, patientId, getPatient]);

  return (
    <Menu data-testid="actions_dropdown" as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-[5px] text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue">
          Actions...
          <Common.Icon name="arrow-down" className="ml-6" />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-md ring-1 ring-black/5 focus:outline-none">
          <div className="py-1">
            <MenuItem>
              {({ active }) => (
                <a className={getMenuItemClasses(active)} onClick={handleCreateNewTask}>
                  <Common.Icon name="plus" />
                  Create new task
                </a>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <a className={getMenuItemClasses(active)} onClick={handleLoginAsAPatient}>
                  <Common.Icon name="log-out" />
                  Sign in as patient
                </a>
              )}
            </MenuItem>
            {showCheckBenefits && (
              <MenuItem>
                {({ active }) => (
                  <a className={getMenuItemClasses(active)} onClick={handleCheckBenefits}>
                    <Common.Icon name="prescription-outline" />
                    Check benefits
                  </a>
                )}
              </MenuItem>
            )}
            {showPrescribeCompound && (
              <MenuItem>
                {({ active }) => (
                  <a className={getMenuItemClasses(active)} onClick={handlePrescribeCompound}>
                    <Common.Icon name="prescription-filled" />
                    {isTripleTherapy ? 'Prescribe TT' : 'Prescribe compound'}
                  </a>
                )}
              </MenuItem>
            )}
            {showViewInMaxio && (
              <MenuItem>
                {({ active }) => (
                  <a className={getMenuItemClasses(active)} onClick={handleViewMaxio}>
                    <Common.Icon name="checkout" />
                    View in Maxio
                  </a>
                )}
              </MenuItem>
            )}
            {showStopReceivingTasks && (
              <MenuItem>
                {({ active }) => (
                  <a className={getMenuItemClasses(active)} onClick={handleStopReceivingTasks}>
                    <Common.Icon name="not-available" />
                    Stop receiving tasks
                  </a>
                )}
              </MenuItem>
            )}
            {showAssignProviderBtn && (
              <MenuItem>
                {({ active }) => (
                  <a className={getMenuItemClasses(active)} onClick={handleAssignProvider}>
                    <Common.Icon name="doctor" />
                    {`${isReAssignProviderFlow ? 'Reassign' : 'Assign'} provider`}
                  </a>
                )}
              </MenuItem>
            )}
            {showRemoveBillingBlockAlert && (
              <MenuItem>
                {({ active }) => (
                  <a className={getMenuItemClasses(active)} onClick={handleRemoveBillingBlockModal}>
                    <Common.Icon name="card" />
                    Remove billing block
                  </a>
                )}
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
