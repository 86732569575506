import { StatusFilterData } from 'constants/filters';

import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import { columns } from 'components/tables/AgingAlertsTable/columns';
import BasicTable from 'components/tables/BasicTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useSearchParams } from 'react-router';
import { AgingAlertsQueryParams } from 'store/agingAlerts/agingAlerts.types';
import { useGetAgingAlertsQuery } from 'store/agingAlerts/agingAlertsSlice';
import { parseQueryParams } from 'utils/router';

const AgingConfig = () => {
  const [searchParams] = useSearchParams();

  const queryParams: AgingAlertsQueryParams = useMemo(() => {
    return parseQueryParams(searchParams);
  }, [searchParams]);
  const {
    data: agingConfigs,
    isFetching,
    isUninitialized
  } = useGetAgingAlertsQuery({ params: queryParams });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: agingConfigs?.agingAlerts
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable
          table={table}
          totalCount={agingConfigs?.totalCount || 0}
          isFetching={isFetching}
        />
      </Card>
    </>
  );
};

export default AgingConfig;
